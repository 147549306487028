import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useForm } from "react-hook-form";
import queryString from "query-string";
import { axios } from "util/request";
import { AxiosResponse } from "axios";
import { C_SITE_API_URL } from "config/config";
import { AlertContext } from "component/alert/alertContext";

import { Grid, Paper, Box, TextField, Typography, Button } from "@mui/material";
import LanguageButton from "layout/languageButton";
import { useTranslation } from "react-i18next";
import { IsValidPasswordFormat } from "util/const";
import { passwordHelperText, confirmPasswordHelperText } from "util/helper";
// import styles from "./resetPassword.module.css";

interface ResetPasswordData {
  email: string;
  code: string;
  new_password: string;
  confirm_password: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlQueryObj = queryString.parse(location.search);
  const [codeValid, setCodeValid] = useState<boolean>(false);
  const { setAlertInfo, setDialogInfo, loadingBackdrop, setLoadingBackdrop } =
    useContext(AlertContext);
  const { t } = useTranslation(["translation", "signin", "registration"]);
  const {
    register,
    handleSubmit,
    getValues: getFormValues,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  const fetchCodeVerification = (iEmail: string, iCode: string) => {
    const actualUrl = "/verify/reset/password/code";
    return axios.post(actualUrl, { email: iEmail, code: iCode }, { baseURL: C_SITE_API_URL });
  };

  const fetchResetPassword = (params: ResetPasswordData) => {
    const actualUrl = "/reset/password";
    return axios.post(actualUrl, params, { baseURL: C_SITE_API_URL });
  };

  const checkCode = () => {
    if (setLoadingBackdrop) {
      setLoadingBackdrop(true);
    }
    fetchCodeVerification(urlQueryObj.email as string, urlQueryObj.code as string)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setCodeValid(true);
        } else {
          // default is false
          // setCodeValid(false);
        }
        setLoadingBackdrop(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingBackdrop(false);
      });
  };

  useEffect(() => {
    checkCode();
  }, []);

  const handleCancel = () => {
    navigate("/");
  };

  const handleReset = (data: { new_password: string; confirm_password: string }) => {
    const params: ResetPasswordData = {
      email: urlQueryObj.email as string,
      code: urlQueryObj.code as string,
      new_password: data.new_password,
      confirm_password: data.confirm_password
    };
    if (setLoadingBackdrop) {
      setLoadingBackdrop(true);
    }
    fetchResetPassword(params)
      .then((response: AxiosResponse) => {
        setLoadingBackdrop(false);
        if (response && response.status === 200 && response.data.message) {
          setDialogInfo &&
            setDialogInfo({
              open: true,
              title: t("dialog.title-result"),
              content: response.data.message,
              onOk: () => {
                navigate("/");
              }
            });
        } else {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: t("signin:forgot-password.fail-to-set-password")
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingBackdrop(false);
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: t("signin:forgot-password.fail-to-set-password")
          });
      });
    clearFormErrors();
  };

  const IsCorrectConfirmPassword = (confirmPassword: string) => {
    const password = getFormValues("new_password");
    if (password === confirmPassword) {
      return true;
    }
    return false;
  };

  const { ref: newPasswordHook, ...newPasswordRest } = register("new_password", {
    required: true,
    minLength: 8,
    validate: IsValidPasswordFormat
  });
  const { ref: confirmPasswordHook, ...confirmPasswordRest } = register("confirm_password", {
    required: true,
    validate: IsCorrectConfirmPassword
  });

  return (
    <Grid container style={{ justifyContent: "center" }}>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        component={Paper}
        style={{ minHeight: "50vh", padding: "20px" }}
      >
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <LanguageButton />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center"
          }}
        >
          <Typography variant="h5">{t("site.name")}</Typography>
          <Typography variant="h6">{t("signin:forgot-password.modal-title")}</Typography>
          {codeValid && (
            <form autoComplete="off" onSubmit={handleSubmit(handleReset)}>
              <Grid container>
                <input
                  type="hidden"
                  value={(urlQueryObj && urlQueryObj.code && urlQueryObj.code) || ""}
                />
                <Grid item xs={12}>
                  <TextField
                    disabled
                    label={t("label.email")}
                    type="text"
                    value={urlQueryObj && urlQueryObj.email && urlQueryObj.email}
                    fullWidth
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...newPasswordRest}
                    inputRef={newPasswordHook}
                    label={t("label.new-password")}
                    type="password"
                    fullWidth
                    variant="standard"
                    autoComplete="new-password"
                    error={!!formErrors.new_password}
                    helperText={passwordHelperText(formErrors.new_password, t)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    {...confirmPasswordRest}
                    inputRef={confirmPasswordHook}
                    label={t("label.confirm-password")}
                    type="password"
                    fullWidth
                    variant="standard"
                    autoComplete="new-password"
                    error={!!formErrors.confirm_password}
                    helperText={confirmPasswordHelperText(formErrors.confirm_password, t)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      m: 4,
                      p: 1,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box sx={{ p: 1, flexGrow: 1 }}>
                      <Button onClick={handleCancel} variant="outlined">
                        {t("button.cancel")}
                      </Button>
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <Button variant="contained" type="submit">
                        {t("button.submit")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
          {!loadingBackdrop && !codeValid && (
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <Grid item xs={12}>
                <Typography color="error" style={{ marginTop: "40px" }} variant="h6">
                  {t("signin:forgot-password.code-is-not-valid")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  fullWidth
                  style={{ marginTop: "60px" }}
                  variant="contained"
                  onClick={handleCancel}
                >
                  {t("button.go-to-sign-in")}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
