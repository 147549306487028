import React from "react";
import { Typography, Button } from "@mui/material";
import { MS_CONFIG } from "config/config";
import { PublicClientApplication } from "@azure/msal-browser";

interface MicrosoftLoginProps {
  msalInstance: PublicClientApplication;
}

const MicrosoftLogin = (props: MicrosoftLoginProps) => {
  const { msalInstance } = props;
  const loginRequest = {
    scopes: MS_CONFIG.scopes
  };

  return (
    <Button
      sx={{ border: "1px solid #dadce0", width: "175px", height: "32px" }}
      onClick={(e) => {
        e.preventDefault();
        msalInstance.loginRedirect(loginRequest);
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
        <title>MS-SymbolLockup</title>
        <rect x="1" y="1" width="9" height="9" fill="#f25022" />
        <rect x="1" y="11" width="9" height="9" fill="#00a4ef" />
        <rect x="11" y="1" width="9" height="9" fill="#7fba00" />
        <rect x="11" y="11" width="9" height="9" fill="#ffb900" />
      </svg>
      <Typography sx={{ marginLeft: "12px", paddingTop: "4px", fontSize: "12px" }}>
        Sign in with MS
      </Typography>
    </Button>
  );
};

export default MicrosoftLogin;
