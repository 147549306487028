import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MyFileUpload, FileStatusDone, FileStatusError } from "./file";
import LinearProgressWithLabel from "./linearProgressWithLabel";

interface DragableUploadListItemProps {
  moveRow: (dragIndex: any, hoverIndex: any) => void;
  removeFile: (file: MyFileUpload) => void;
  downloadFile: (file: MyFileUpload) => void;
  file: MyFileUpload;
  fileList: MyFileUpload[];
}

const type = "DragableUploadList";

const DragableUploadListItem: React.FC<DragableUploadListItemProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);
  // const { originNode, file, fileList, moveRow } = props;
  const { file, fileList, moveRow, removeFile, downloadFile } = props;
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex }: any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward"
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });
  drop(drag(ref));
  // console.log("file", file);
  // const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
  const showProgressByStatus = (iFile: MyFileUpload) => {
    const typoStyle = { flexGrow: 1, width: "100px" };
    if (iFile.status === FileStatusDone) {
      return (
        <>
          <InsertDriveFileIcon sx={{ marginRight: "10px" }} />
          <Typography noWrap sx={{ ...typoStyle }}>
            {iFile.name}
          </Typography>
        </>
      );
    }

    if (iFile.status === FileStatusError) {
      return (
        <>
          <CancelIcon color="error" sx={{ marginRight: "10px" }} />
          <Typography noWrap sx={{ ...typoStyle, color: "red" }}>
            {iFile.name}
          </Typography>
        </>
      );
    }

    return (
      <>
        <CircularProgress sx={{ marginRight: "10px" }} />
        <Box sx={{ paddingTop: "10px", flexGrow: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography noWrap sx={{ width: "100px" }}>
              {file.name}
            </Typography>
            <LinearProgressWithLabel value={file.percent || 0} />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      key={`${file.uid}fileitem`}
      sx={{
        display: "flex",
        flexDirection: { xs: "row" },
        flexWrap: "wrap",
        marginTop: "5px",
        marginBottom: "5px"
      }}
    >
      <Box
        sx={{ display: { xs: "none", md: "inline" } }}
        style={{ width: "30px", fontSize: "16px", margin: "auto", paddingRight: "10px" }}
      >
        <Typography component="div">{index + 1}.</Typography>
      </Box>
      <Box
        sx={{ flexGrow: { xs: 1 } }}
        ref={ref}
        className={`ant-upload-draggable-list-item ant-upload-item-custom 
         ${isOver ? dropClassName : ""}
         ${file.status === FileStatusDone ? " upload-list-item-success" : ""}`}
        style={{ cursor: "move" }}
      >
        <Typography
          component="div"
          className={`${Number(file.uid) ? " file-from-db" : "file-new-upload"}`}
        >
          <Box
            sx={{
              padding: "5px",
              margin: "auto",
              display: "flex",
              flexDirection: "row",
              height: "55px",
              border: "1px solid black",
              alignItems: "center"
            }}
          >
            {showProgressByStatus(file)}
            <IconButton
              aria-label="download"
              key={`download_button${file.uid}`}
              onClick={() => {
                downloadFile(file);
              }}
            >
              <DownloadForOfflineRoundedIcon sx={{ color: "black", marginRight: "5px" }} />
            </IconButton>
            <IconButton
              aria-label="delete"
              key={`remove_button${file.uid}`}
              onClick={() => {
                removeFile(file);
              }}
            >
              <RemoveCircleIcon color="error" />
            </IconButton>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default DragableUploadListItem;
