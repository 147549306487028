import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { RcFile } from "rc-upload/lib/interface";
import { styled } from "@mui/material/styles";
import update from "immutability-helper";
import { AlertContext } from "component/alert/alertContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import { Typography, Box } from "@mui/material";
import DropZoneUploadPictureItem from "./dropZoneUploadPictureItem";
import { FileStatusDone, FileStatusError, FileStatusUploading } from "./file";

// interface UploadFileProps {
//   file_name: string;
//   md5?: string;
//   status: number;
// }

export interface FileUploaded extends RcFile {
  id: number;
  name: string;
  preview: string;
  filename: string;
  percentage: number;
  myTurn: boolean;
  original_file: string;
  thumbnail_file: string;
  status: string;
}

const CustomthumbsContainer = styled("aside")(() => {
  return {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16
  };
});

const CustomizedDroppingAreaDiv = styled("div")(() => {
  return {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderStyle: "dotted",
    borderColor: "#e9eaf1",
    backgroundColor: "#fafafa",
    outline: "none",
    cursor: "pointer",
    transition: "border .24s ease-in-out"
  };
});

interface DropZoneFileUploadProps {
  setCallbackImageList: React.Dispatch<React.SetStateAction<FileUploaded[]>>;
  initialImages?: FileUploaded[];
}

const DropZoneFileUpload: React.FC<DropZoneFileUploadProps> = (props) => {
  const { setCallbackImageList, initialImages } = props;
  const [files, setFiles] = useState<FileUploaded[]>(initialImages || []);
  const [newUpload, setNewUpload] = useState<number>(0);
  const [finishedUpload, setFinishedUpload] = useState<number>(0);
  const [allowDrag, setAllowDrag] = useState<boolean>(true);
  const { t } = useTranslation(["translation", "gallery"]);
  const { setDialogInfo } = useContext(AlertContext);

  const onDrop = (acceptedFiles: string | any[]) => {
    const newFileList: Array<FileUploaded> = [...files];
    setNewUpload(newUpload + acceptedFiles.length);
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      const fuid = uuidv4();
      // const setMyTurn = i === 0;
      setAllowDrag(false);
      const setMyTurn = i === acceptedFiles.length - 1;
      newFileList.unshift(
        Object.assign(acceptedFiles[i], {
          preview: URL.createObjectURL(acceptedFiles[i]),
          percentage: 0,
          uid: fuid,
          status: FileStatusUploading,
          myTurn: setMyTurn
        })
      );
    }
    setFiles(newFileList);
  };

  const shouldDisableDropzone = () => {
    return newUpload > 0 && newUpload !== finishedUpload;
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // noDragEventsBubbling will prevent the event from dropzone shared to drag and drop (react-dnd), as we do sorting at the same time
    noDragEventsBubbling: true,
    disabled: shouldDisableDropzone(),
    accept: ".jpeg,.jpg,.png,.gif"
  });

  const processNext = (index: number, tempFilename: string) => {
    // update the finished file
    const finishedFile = Object.assign(files[index], {
      percentage: tempFilename === "" ? 0 : 100,
      myTurn: false,
      uploaded_filename: tempFilename,
      filename: files[index].name,
      status: tempFilename === "" ? FileStatusError : FileStatusDone
    });
    if (files[index].status === FileStatusDone) {
      setFinishedUpload(finishedUpload + 1);
    }
    const clone = [...files];
    clone[index] = finishedFile as FileUploaded;

    // update the next file "myTurn"
    const nextIndex = index + 1;
    // !files[nextIndex].thumbnail_file, it means the file is coming from db (saved before)
    // files[nextIndex].percentage, means it is not started and could be started
    if (
      nextIndex < files.length &&
      !files[nextIndex].thumbnail_file &&
      files[nextIndex].percentage === 0
    ) {
      const nextFile = Object.assign(files[nextIndex], {
        myTurn: true
      });
      clone[nextIndex] = nextFile as FileUploaded;
    } else {
      setAllowDrag(true);
    }
    setFiles(clone);
  };

  const uploadFinished = (iFile: FileUploaded, tempFilename: string) => {
    if (files) {
      const index = files.findIndex((item) => {
        return item.uid === iFile.uid;
      });
      processNext(index, tempFilename);
    }
  };

  const removeFile = (iFile: FileUploaded) => {
    const index = files.findIndex((item) => {
      if (item.id > 0) {
        return item.id === iFile.id;
      }
      return item.uid === iFile.uid;
    });

    const clone = [...files];
    clone.splice(index, 1);
    setFiles(clone);
    if (iFile && !iFile.thumbnail_file) {
      // it is new upload
      setNewUpload(newUpload - 1);
      if (iFile.status === FileStatusDone) {
        setFinishedUpload(finishedUpload - 1);
      }
    }
  };

  const moveImage = (dragIndex: number, hoverIndex: number) => {
    // console.log("moveRow dragIndex hoverIndex", dragIndex, hoverIndex);
    if (allowDrag) {
      setFiles((prevFileList: FileUploaded[]) => {
        return update(prevFileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevFileList[dragIndex]]
          ]
        });
      });
    } else {
      setDialogInfo({
        open: true,
        title: t("dialog.title-warning"),
        content: t("gallery:dialog.upload-in-progess"),
        onOk: () => {}
      });
    }
  };

  const renderImageItem = useCallback(
    (file: FileUploaded, index: number) => {
      return (
        <DropZoneUploadPictureItem
          file={file}
          key={`${file.id ? file.id : file.uid}pic-item`}
          hasRoomToSend={file.myTurn}
          uploadFinished={uploadFinished}
          removeImage={removeFile}
          moveImage={moveImage}
          index={index}
        />
      );
    },
    [files]
  );

  useEffect(() => {
    return () => {
      files.forEach((file: FileUploaded) => {
        return URL.revokeObjectURL(file.preview);
      });
    };
  }, []);

  useEffect(() => {
    setCallbackImageList(files);
  }, [files]);

  useEffect(() => {
    setFiles(initialImages || []);
    setNewUpload(0);
    setFinishedUpload(0);
  }, [initialImages]);

  return (
    <>
      <CustomizedDroppingAreaDiv
        className={shouldDisableDropzone() ? "disabledColor" : ""}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>
          <CloudUploadIcon />
        </p>
        {isDragActive ? (
          <p>{t("gallery:message.put-here")}</p>
        ) : (
          <p>{t("gallery:message.upload-message")}</p>
        )}
      </CustomizedDroppingAreaDiv>
      {files && files.length > 0 && (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography style={{ marginTop: "10px", flexGrow: 1 }}>
            {t("gallery:label.total-no-of-record", { number: files.length })}
          </Typography>
          <Typography style={{ marginTop: "10px" }}>
            {t("gallery:label.upload", { finished: finishedUpload, new_upload: newUpload })}
          </Typography>
        </Box>
      )}
      <DndProvider backend={HTML5Backend}>
        <CustomthumbsContainer>
          {files.map((file, i) => {
            return renderImageItem(file, i);
          })}
        </CustomthumbsContainer>
      </DndProvider>
    </>
  );
};
export default DropZoneFileUpload;

DropZoneFileUpload.defaultProps = {
  initialImages: []
};
