export const SITE_API_URL = process.env.REACT_APP_SITE_API_URL;
export const V_SITE_API_URL = process.env.REACT_APP_V_SITE_API_URL;
export const C_SITE_API_URL = process.env.REACT_APP_C_SITE_API_URL;

export const MS_CONFIG = {
  auth: {
    clientId: "96ba15e4-30c4-4191-b7f2-1d403ead2f31",
    authority: "https://login.microsoftonline.com/common",
    // redirectUri: "http://localhost/auth/azureadv2/callback", //defaults to application start page
    postLogoutRedirectUri: process.env.REACT_APP_MS_POST_LOGOUT_REDIRECT,
    navigateToLoginRequestUrl: false
  },
  //   cache: {
  //     cacheLocation: "localStorage",
  //     storeAuthStateInCookie: true,
  //   },
  scopes: ["openid", "profile", "User.Read"]
};

export const GOOGLE_CONFIG = {
  clientId: "1034497468084-1c4akr9cl1chcjev7ki2npro704utrn5.apps.googleusercontent.com",
  uxMode: "redirect",
  redirectUri: process.env.REACT_APP_GOOGLE_REDIRECT_URL
};
