import { getI18n } from "react-i18next";
import { LANG_EN } from "./const";

export const GetStandardOptionWithCorrectLangLabel = (option: any) => {
  if (getI18n().language === LANG_EN) {
    return option.name_eng;
  }
  return option.name_chi;
};

export const GetStandardOptionLabel = (option: any) => {
  // console.log(option);
  // if (i18n.language === LANG_EN) {
  //   return option.name_eng;
  // } else {
  return option.name_chi;
  // }
};

export const GetCountryGroupOptionLabel = (option: any) => {
  // console.log(option);
  // if (i18n.language === LANG_EN) {
  //   return option.name_eng;
  // } else {
  return option.country.name_chi;
  // }
};

export const GetTranslatedOptionLabel = (option: any) => {
  // console.log(option);
  if (!option) {
    return "";
  }
  if (getI18n().language === LANG_EN) {
    return option.name_eng;
  }
  return option.name_chi;
};

export const SerializeID = (objs: any): string => {
  let idListString = "";
  objs.forEach((obj: { id: { toString: () => string } }) => {
    if (idListString === "") {
      idListString = obj.id.toString();
    } else {
      idListString += `,${obj.id.toString()}`;
    }
  });
  return idListString;
};

export interface IStatus {
  value: number;
  name: string;
}

export interface IMultiAutoComplete {
  id: number;
  name_chi?: string;
  name_eng?: string;
}
