import React, { useState, useRef, useEffect, useContext } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import {
  IsValidEmail,
  IsValidPhone,
  IsValidChineseName,
  IsValidEnglishName,
  IsValidReferee,
  EDIT_USER_INFORMATION
} from "util/const";
import {
  Typography,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  LinearProgress,
  Autocomplete,
  Button,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetStandardOptionLabel, GetCountryGroupOptionLabel } from "util/display";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import { AlertContext } from "component/alert/alertContext";
import { ErrorType, emailHelperText } from "util/helper";
import { getScopes, userHasPerission } from "util/token";
import CustomizedDialogDivider from "component/customizedDivider";
import CustomizedUserPaper from "component/customizedUserPaper";
import Info from "./info";
import { UserOptionContext } from "./userOptionContext";
import { fetchUpdateUser } from "./service";
import { STATUS_INACTIVE, STATUS_ACTIVE, User, UserStatus, UpdateUser } from "./data.d";
import { Role } from "../role/data.d";

export interface EditUserProps {
  user?: User;
  open: boolean;
  onClose?: () => void;
  submitUpdateCallback?: (user: number) => void;
}

const EditUser: React.FC<EditUserProps> = (props) => {
  const { open, user, submitUpdateCallback } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { data: optionData } = useContext(UserOptionContext);
  const [userState, setUserState] = useState<User | undefined>(user);
  const [dobValue, setDobValue] = useState<Date | null>(null);
  const [selectedCountryID, setSelectedCountryID] = useState<number>(0);
  const [selectedAuthorityID, setSelectedAuthorityID] = useState<number>(0);
  const [selectedChurchID, setSelectedChurchID] = useState<number>(0);
  const [selectedJobNatureID, setSelectedJobNatureID] = useState<number>(0);
  const [selectedStatusID, setSelectedStatusID] = useState<number | undefined>();
  const [selectedRoleID, setSelectedRoleID] = useState<Role[]>([]);
  const scopes = getScopes();
  const hasEditUserPermission = userHasPerission(scopes, [EDIT_USER_INFORMATION]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  const [showEditUserForm, setShowEditUserForm] = useState<boolean>(false);
  const { t } = useTranslation(["translation", "registration", "user-management"]);
  const tooltipsEditString: string = t("button.edit");

  useEffect(() => {
    // console.log("userHasBeenChangedFromIndex", user);
    setUserState(user);
  }, [user]);

  useEffect(() => {
    clearFormErrors();
    // console.log("userStateHasBeenChanged", userState);
    setValue("email", user?.email);
    setValue("name_chi", user?.name_chi);
    setValue("name_eng", user?.name_eng);
    setValue("telephone", user?.telephone || "");
    setValue("referee", user?.referee || "");
    setDobValue(userState?.dob ? new Date(userState?.dob) : null);
    setValue("dob", userState?.dob ? new Date(userState?.dob) : null);

    console.log("userState", userState);
    setSelectedCountryID(userState?.country_id || 0);
    setValue("country_id", userState?.country_id);

    setSelectedChurchID(userState?.church_id || 0);
    setValue("church_id", userState?.church_id);

    setSelectedJobNatureID(userState?.job_nature_id || 0);
    setValue("job_nature_id", userState?.job_nature_id);

    setSelectedAuthorityID(userState?.authority_id || 0);
    setValue("authority_id", userState?.authority_id);

    setSelectedStatusID(userState?.status);
    setValue("status", userState?.status);

    // console.log("userState?.role_list", userState?.role_list);
    setSelectedRoleID(userState?.role_list || []);
    setValue("role", userState?.role_list || []);
  }, [setValue, clearFormErrors, userState]);

  const handleClose = () => {
    setShowEditUserForm(false);
    props.onClose && props.onClose();
    setUserState(undefined);
  };

  const modalTitle = t("user-management:edit-page.title", {
    name: user?.name_chi ? user?.name_chi : ""
  });

  const filteredStatusList = (currentStatus: number): UserStatus[] => {
    if (currentStatus === STATUS_INACTIVE || currentStatus === STATUS_ACTIVE) {
      return (
        optionData.statusList.filter((s) => {
          return s.value === STATUS_INACTIVE || s.value === STATUS_ACTIVE;
        }) || []
      );
    }
    return optionData.statusList;
  };

  const submitForm = (formData: UpdateUser) => {
    // console.log("submitForm", formData);
    const postData = { ...formData };
    if (!formData.church_id) {
      delete postData.church_id;
    }
    if (!formData.job_nature_id) {
      delete postData.job_nature_id;
    }
    if (!formData.authority_id) {
      delete postData.authority_id;
    }
    if (formData.dob === "" || formData.dob === undefined) {
      delete postData.dob;
    } else {
      postData.dob = new Date(formData.dob!).toISOString();
    }
    if (formData.role) {
      postData.role_ids = formData.role?.map((role) => {
        return role.id;
      });
      delete postData.role; // we need the ID list only
    }
    // console.log("modified", postData);

    if (user) {
      setLoadingBackdrop(true);
      fetchUpdateUser(user.id, postData)
        .then((response: AxiosResponse) => {
          if (response && response.status === 200 && response.data) {
            setSuccessInfo({
              open: true,
              message: response.data.message
            });
            if (submitUpdateCallback) {
              handleClose();
              submitUpdateCallback(user.id);
            } else {
              // otherwises, index page will remove the backdrop after refresh
              setLoadingBackdrop(false);
            }
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setLoadingBackdrop(false);
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        });
    }
  };

  // hook form validation requirement
  const { ref: emailHookRef, ...emailHookRest } = register("email", {
    required: true,
    validate: IsValidEmail
  });
  const { ref: nameChiHookRef, ...nameChiHookRest } = register("name_chi", {
    required: true,
    maxLength: 6,
    validate: IsValidChineseName
  });
  const { ref: nameEngHookRef, ...nameEngHookRest } = register("name_eng", {
    required: true,
    maxLength: 31,
    validate: IsValidEnglishName
  });
  const { ref: telephoneHookRef, ...telephoneHookRest } = register("telephone", {
    required: true,
    validate: IsValidPhone
  });
  const { ref: refereeHookRef, ...refereeHookRest } = register("referee", {
    required: true,
    validate: IsValidReferee,
    maxLength: 32
  });
  // const { ref: dobHookRef, ...dobHookRest } = register("dob");
  const { ref: countryIDRef } = register("country_id", {
    required: true
  });
  const { ref: churchIDRef } = register("church_id");
  const { ref: jobNatureIDRef } = register("job_nature_id");
  const { ref: authorityIDRef } = register("authority_id");
  const { ref: statusRef } = register("status", { required: true });
  const { ref: roleRef } = register("role");

  // const emailHelperText = (emailError: ErrorType) => {
  //   if (emailError?.type === "required") {
  //     return t("registration:errors.email-is-required");
  //   } else if (emailError?.type === "validate") {
  //     return t("registration:errors.email-invalid");
  //   }
  //   return "";
  // };
  const chiNameHelperText = (chiName: ErrorType) => {
    if (chiName?.type === "required") {
      return t("registration:errors.name-chi-is-required");
    }
    if (chiName?.type === "validate") {
      return t("registration:errors.name-chi-must-be-chinese");
    }
    if (chiName?.type === "maxLength") {
      return t("registration:errors.name-chi-length");
    }
    return "";
  };
  const engNameHelperText = (engName: ErrorType) => {
    if (engName?.type === "required") {
      return t("registration:errors.name-eng-is-required");
    }
    if (engName?.type === "validate") {
      return t("registration:errors.name-eng-must-be-english");
    }
    if (engName?.type === "maxLength") {
      return t("registration:errors.name-eng-length");
    }
    return "";
  };
  const refereeHelperText = (refereeError: ErrorType) => {
    if (refereeError?.type === "required") {
      return t("registration:errors.referee-is-required");
    }
    if (refereeError?.type === "validate") {
      return t("registration:errors.referee-must-be-chinese-or-english");
    }
    if (refereeError?.type === "maxLength") {
      return t("registration:errors.referee-length");
    }
    return "";
  };

  return (
    <>
      {user && (
        <Dialog
          key="edit-user-modal-dialog"
          open={open}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby={modalTitle}
          aria-describedby={modalTitle}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="edit-user-info-dialog-title">
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography key="edit-user-modal-title" id={modalTitle} variant="h6" component="h2">
                  {user.name_chi}
                  {!showEditUserForm && hasEditUserPermission && (
                    <IconButton
                      aria-label="edit"
                      key={`edit_button${user.id}`}
                      onClick={() => {
                        setShowEditUserForm(true);
                      }}
                    >
                      <Tooltip title={tooltipsEditString} placement="right">
                        <EditIcon color="secondary" />
                      </Tooltip>
                    </IconButton>
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon color="action" />
                </IconButton>
              </Grid>
            </Grid>
            <CustomizedDialogDivider />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <Info user={user} />
            </Grid>

            {showEditUserForm && (
              <>
                <CustomizedUserPaper elevation={3}>
                  <form ref={formEl} onSubmit={handleSubmit(submitForm)}>
                    <Grid container spacing={3}>
                      {/* <Grid item xs={12}>
                        <Typography> {t("button.edit")}</Typography>
                      </Grid> */}
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...emailHookRest}
                          inputRef={emailHookRef}
                          defaultValue={userState?.email}
                          fullWidth
                          variant="standard"
                          autoComplete="off"
                          type="email"
                          label={`${t("label.email")} *`}
                          error={!!formErrors.email}
                          helperText={emailHelperText(formErrors.email, t)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...nameChiHookRest}
                          inputRef={nameChiHookRef}
                          defaultValue={userState?.name_chi}
                          fullWidth
                          variant="standard"
                          type="text"
                          label={`${t("registration:pi.name-chi")}*`}
                          error={!!formErrors.name_chi}
                          helperText={chiNameHelperText(formErrors.name_chi)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...nameEngHookRest}
                          inputRef={nameEngHookRef}
                          defaultValue={userState?.name_eng}
                          fullWidth
                          variant="standard"
                          type="text"
                          label={`${t("registration:pi.name-eng")}*`}
                          error={!!formErrors.name_eng}
                          helperText={engNameHelperText(formErrors.name_eng)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...telephoneHookRest}
                          inputRef={telephoneHookRef}
                          defaultValue={userState?.telephone}
                          fullWidth
                          variant="standard"
                          type="text"
                          label={`${t("registration:pi.telephone")}*`}
                          error={!!formErrors.telephone}
                          helperText={
                            formErrors.telephone && t("registration:errors.telephone-is-required")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          {...refereeHookRest}
                          inputRef={refereeHookRef}
                          defaultValue={userState?.referee}
                          fullWidth
                          variant="standard"
                          type="text"
                          autoComplete="off"
                          error={!!formErrors.referee}
                          helperText={refereeHelperText(formErrors.referee)}
                          label={`${t("registration:pi.referee")}*`}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            disableFuture
                            label={t("registration:pi.dob")}
                            openTo="day"
                            views={["year", "month", "day"]}
                            value={dobValue}
                            onChange={(newValue) => {
                              setDobValue(newValue);
                              setValue("dob", newValue);
                            }}
                            mask="____-__-__"
                            inputFormat="yyyy-MM-dd"
                            renderInput={(params) => {
                              return (
                                <TextField
                                  fullWidth
                                  {...params}
                                  // inputRef={dobHookRef}
                                  {...register("dob")}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {optionData && optionData.countryList ? (
                          <Autocomplete
                            options={optionData.countryList}
                            value={
                              optionData.countryList.find((c) => {
                                return c.id === selectedCountryID;
                              }) || null
                            }
                            onChange={(event, newValue) => {
                              clearFormErrors("country_id");
                              setValue("country_id", newValue?.id);
                              setSelectedCountryID(newValue?.id || 0);
                            }}
                            getOptionLabel={GetStandardOptionLabel}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={countryIDRef}
                                  label={`${t("registration:pi.district")}*`}
                                  variant="standard"
                                  error={!!formErrors.country_id}
                                  helperText={
                                    formErrors.country_id &&
                                    t("registration:errors.district-is-required")
                                  }
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {optionData && optionData.churchList ? (
                          <Autocomplete
                            options={optionData.churchList}
                            groupBy={GetCountryGroupOptionLabel}
                            getOptionLabel={GetStandardOptionLabel}
                            value={
                              optionData.churchList.find((c) => {
                                return c.id === selectedChurchID;
                              }) || null
                            }
                            onChange={(event, newValue) => {
                              clearFormErrors("church_id");
                              setValue("church_id", newValue?.id);
                              setSelectedChurchID(newValue?.id || 0);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={churchIDRef}
                                  label={t("registration:pi.parish")}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {optionData && optionData.jobNatureList ? (
                          <Autocomplete
                            options={optionData.jobNatureList}
                            getOptionLabel={GetStandardOptionLabel}
                            value={
                              optionData.jobNatureList.find((j) => {
                                return j.id === selectedJobNatureID;
                              }) || null
                            }
                            onChange={(event, newValue) => {
                              clearFormErrors("job_nature_id");
                              setValue("job_nature_id", newValue?.id);
                              setSelectedJobNatureID(newValue?.id || 0);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={jobNatureIDRef}
                                  label={t("registration:pi.job-nature")}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {optionData && optionData.authorityList ? (
                          <Autocomplete
                            options={optionData.authorityList}
                            groupBy={GetCountryGroupOptionLabel}
                            getOptionLabel={GetStandardOptionLabel}
                            value={
                              optionData.authorityList.find((a) => {
                                return a.id === selectedAuthorityID;
                              }) || null
                            }
                            onChange={(event, newValue) => {
                              clearFormErrors("authority_id");
                              setValue("authority_id", newValue?.id);
                              setSelectedAuthorityID(newValue?.id || 0);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={authorityIDRef}
                                  label={t("registration:pi.authority")}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        {optionData && optionData.roleList ? (
                          <Autocomplete
                            multiple
                            options={optionData.roleList}
                            // getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={GetStandardOptionLabel}
                            value={selectedRoleID}
                            onChange={(event, newValue) => {
                              // console.log("role newValue", newValue);
                              setValue("role", newValue);
                              setSelectedRoleID(newValue);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={roleRef}
                                  // {...register("role")}
                                  label={t("user-management:label.role")}
                                  variant="standard"
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>

                      <Grid item xs={12} md={6}>
                        {optionData && optionData.statusList ? (
                          <Autocomplete
                            options={filteredStatusList(user.status)}
                            value={optionData.statusList.find((s) => {
                              return s.value === selectedStatusID;
                            })}
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            onChange={(event, newValue) => {
                              clearFormErrors("status");
                              setValue("status", newValue?.value);
                              setSelectedStatusID(newValue?.value);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  inputRef={statusRef}
                                  label={`${t("label.status")}*`}
                                  fullWidth
                                  variant="standard"
                                  error={!!formErrors.status}
                                  helperText={
                                    formErrors.status &&
                                    t("user-management:errors.status-is-required")
                                  }
                                />
                              );
                            }}
                          />
                        ) : (
                          <LinearProgress />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center">
                          <Grid item>
                            <Button variant="outlined" onClick={handleClose}>
                              {t("button.cancel")}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button type="submit" variant="contained">
                              {t("button.save")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </CustomizedUserPaper>
              </>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default EditUser;
