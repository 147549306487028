import React from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import AdminLayout from "layout/adminLayout";
// import Content1 from "admin/content1";
import authProvider, { keepTheLogoutPathToRedirect } from "auth/authProvider";
import { getScopes, userHasPerission } from "util/token";
import UserManagement from "admin/user";
import ResResourceGroup from "res/resource";
import TopicWithCategoryProvider from "res/topic/indexWithCategoryProvider";
import { CategoryContextProvider } from "admin/category/categoryContext";
import Gallery from "gallery";
import PrivacyPolicy from "noauth/PrivacyPolicy";
import UserRegulation from "./noauth/userRegulation";
import NotFound from "./noauth/notFound";
import SignIn from "./noauth/signIn";
import RegisterUser from "./noauth/registerUser";
import ResetPassword from "./noauth/resetPassword";
import { AlertContextProvider } from "./component/alert/alertContext";

// check if user has a valid token only
function RequireAuth({ children }: { children: any }) {
  const location = useLocation();
  if (!authProvider.checkAuth()) {
    // Redirect them to the / page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const redirectPath = keepTheLogoutPathToRedirect(true);
    return <Navigate to={redirectPath} state={{ from: location }} />;
  }
  return children;
}

// check if
function RequirePermission({ requiredP, children }: { requiredP: string[]; children: any }) {
  const scopes = getScopes();
  if (!userHasPerission(scopes, requiredP)) {
    return <NotFound />;
  }
  return children;
}

const App: React.FC = () => {
  // const history = useHistory();
  // const [loginAlert, setLoginAlert] = useState({ open: false, message: "" });
  // const [showBackdrop, setShowBackdrop] = useState(false);
  // console.log("App how many");
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth"
          element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>
          }
          key="auth"
        >
          <Route path="admin" key="Admin">
            {/* <Route
              path="dashboard"
              key="Dashboard"
              element={
                <RequirePermission requiredP={["view_user_information"]}>
                  <Content1 />
                </RequirePermission>
              }
            /> */}
            <Route
              path="user"
              key="UserManagement"
              element={
                <RequirePermission requiredP={["view_user_information", "edit_user_information"]}>
                  <UserManagement />
                </RequirePermission>
              }
            />
          </Route>
          <Route path="gallery" element={<Gallery />} key="Gallery" />
          <Route path="res" element={<CategoryContextProvider />} key="Res">
            <Route path="resource" key="ResResourceGroup" element={<ResResourceGroup />} />
            <Route
              path="topic"
              key="TopicWithCategoryProvider"
              element={<TopicWithCategoryProvider />}
            />
          </Route>
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} key="PrivacyPolicy" />
        <Route
          path="/registration/user-regulation"
          element={<UserRegulation />}
          key="UserRegulation"
        />
        <Route path="/registration/register-user" element={<RegisterUser />} key="RegisterUser" />
        <Route
          path="/reset-password"
          element={
            <AlertContextProvider>
              <ResetPassword />
            </AlertContextProvider>
          }
          // component={ResetPassword}
          key="ResetPassword"
        />
        <Route
          path="/"
          element={
            <AlertContextProvider>
              <SignIn />
            </AlertContextProvider>
          }
          key="SignIn"
        />
        <Route element={<NotFound />} key="PageNotFound" />
        <Route path="*" element={<NotFound />} key="ForAllAsRedirect" />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
