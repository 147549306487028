import { ErrorType } from "util/helper";
import { axios } from "util/request";
import { TopicFormData } from "./data.d";

export const nameHelperText = (nameError: ErrorType, t: (params: string | string[]) => string) => {
  if (nameError?.type === "required") {
    return t("topic:errors.name-is-required");
  }
  if (nameError?.type === "maxLength") {
    return t("topic:errors.name-length");
  }
  return "";
};

export const fetchCreateTopic = (topic: TopicFormData) => {
  const url = "/topic";
  return axios.post(url, topic);
};

export const fetchUpdateTopic = (topic: TopicFormData) => {
  const url = `/topic/${topic.id}`;
  return axios.put(url, topic);
};

export const getTopicDtoByCategory = (categoryId: number) => {
  const url = `/topic/list/${categoryId}/category`;
  return axios.get(url);
};

export const addViewCount = (topicId: number) => {
  const url = `/topic/add/view/count/${topicId}`;
  return axios.post(url);
};
