import React, { useEffect, useState, useContext, useRef } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Grid, LinearProgress, TextField, Autocomplete } from "@mui/material";
import { C_SITE_API_URL } from "config/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAxios, axios } from "util/request";
import CustomizedRegistrationPaperContent from "noauth/component/registrationContentDiv";
import Copyright from "noauth/component/copyright";
import AdapterDateFns from "@mui/lab/AdapterDateFns";

// import useAxios from "axios-hooks";
import {
  IsValidEmail,
  IsValidPhone,
  IsValidChineseName,
  IsValidEnglishName,
  IsValidReferee,
  IsValidPasswordFormat,
  TERMS_ACCEPTED
} from "util/const";
import { styled } from "@mui/material/styles";
import { AlertContext } from "component/alert/alertContext";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DatePicker from "@mui/lab/DatePicker";
import { useForm } from "react-hook-form";
import { GetStandardOptionLabel, GetCountryGroupOptionLabel } from "util/display";
import styles from "./registration.module.css";
import CustomizedRegistrationPaper from "./component/registrationPaper";
import CustomizedRegistrationDivider from "./component/registrationDivider";

const CustomizedTypography = styled(Typography)(({ theme }) => {
  return {
    paddingTop: theme.spacing(2)
  };
});

const CustomizedGrid = styled(Grid)(({ theme }) => {
  return {
    margin: theme.spacing(2)
  };
});

const RegisterUser = () => {
  const navigate = useNavigate();
  const { setAlertInfo, setDialogInfo } = useContext(AlertContext);
  const { t } = useTranslation(["translation", "registration"]);
  const [countries, setCounties] = useState(null);
  const [jobNatures, setJobNatures] = useState(null);
  const [churches, setChurches] = useState(null);
  const [authorities, setAuthorities] = useState(null);
  const [dobValue, setDobValue] = useState(null);
  // const [, setCountryID] = useState(0);
  // const [, setAuthorityID] = useState(0);
  // const [, setChurchID] = useState(0);
  // const [, setJobNatureID] = useState(0);
  const formEl = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    getValues: getFormValues,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  const [{ data: regOptions, error: regOptionError }] = useAxios({
    baseURL: C_SITE_API_URL,
    url: "/register/option"
  });

  const postRegister = (params: any) => {
    return axios.post("/register", params, { baseURL: C_SITE_API_URL });
  };

  useEffect(() => {
    if (regOptions) {
      setCounties(regOptions.data.country);
      setJobNatures(regOptions.data.job_nature);
      setChurches(regOptions.data.church);
      setAuthorities(regOptions.data.authority);
    }
    if (regOptionError) {
      setAlertInfo &&
        setAlertInfo({
          open: true,
          message: `${regOptionError.message}, ${t("error.connection")}`
        });
    }
  }, [regOptions, regOptionError, setAlertInfo, t]);

  const termAccepted = localStorage.getItem(TERMS_ACCEPTED);
  if (termAccepted === null || termAccepted === "false") {
    navigate("/");
  }

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    navigate("/");
  };

  // console.log(watch("email")); // watch input value by passing the name of it

  const submitForm = (formData: any) => {
    // formEl && formEl.current && formEl.current.submit();
    const postData = {
      ...formData,
      country_id: formData.country ? formData.country.id : 0,
      church_id: formData.church ? formData.church.id : 0,
      job_nature_id: formData.job_nature ? formData.job_nature.id : 0,
      authority_id: formData.authority ? formData.authority.id : 0
    };
    // console.log("postData", postData);
    if (postData.country_id <= 0 || postData.country === undefined) {
      delete postData.country_id;
    }
    if (postData.church_id <= 0 || postData.church === undefined) {
      delete postData.church_id;
    }
    if (postData.job_nature_id <= 0 || postData.job_nature === undefined) {
      delete postData.job_nature_id;
    }
    if (postData.authority_id <= 0 || postData.authority === undefined) {
      delete postData.authority_id;
    }
    if (postData.dob === "") {
      delete postData.dob;
    }

    postRegister(postData)
      .then((response) => {
        if (response && response.status === 201) {
          setDialogInfo &&
            setDialogInfo({
              open: true,
              title: t("dialog.title-result"),
              content: response.data.message,
              onOk: () => {
                navigate("/");
              }
            });
        } else {
          let errMessage = "";
          if (response.data && response.data.errors && response.data.errors[0]) {
            errMessage = response.data.errors[0].message;
          }
          setAlertInfo({ open: true, message: errMessage });
        }
        return response.data;
      })
      .catch((error: any) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error}, ${t("error.connection")}`
          });
      });
  };

  const IsCorrectConfirmPassword = (confirmPassword: any) => {
    const password = getFormValues("password");
    if (password === confirmPassword) {
      return true;
    }
    return false;
  };

  const emailHelperText = (emailError: any) => {
    if (emailError?.type === "required") {
      return t("registration:errors.email-is-required");
    }
    if (emailError?.type === "validate") {
      return t("registration:errors.email-invalid");
    }
    return "";
  };

  const passwordHelperText = (pass: any) => {
    if (pass?.type === "required") {
      return t("registration:errors.password-is-required");
    }
    if (pass?.type === "validate") {
      return t("registration:errors.password-format");
    }
    if (pass?.type === "minLength") {
      return t("registration:errors.password-length");
    }
    return "";
  };

  const confirmPasswordHelperText = (pass: any) => {
    if (pass?.type === "required") {
      return t("registration:errors.confirm-password-is-required");
    }
    if (pass?.type === "validate") {
      return t("registration:errors.confirm-password-not-match");
    }
    return "";
  };

  const chiNameHelperText = (chiName: any) => {
    if (chiName?.type === "required") {
      return t("registration:errors.name-chi-is-required");
    }
    if (chiName?.type === "validate") {
      return t("registration:errors.name-chi-must-be-chinese");
    }
    if (chiName?.type === "maxLength") {
      return t("registration:errors.name-chi-length");
    }
    return "";
  };

  const engNameHelperText = (engName: any) => {
    if (engName?.type === "required") {
      return t("registration:errors.name-eng-is-required");
    }
    if (engName?.type === "validate") {
      return t("registration:errors.name-eng-must-be-english");
    }
    if (engName?.type === "maxLength") {
      return t("registration:errors.name-eng-length");
    }
    return "";
  };

  const refereeHelperText = (refereeError: any) => {
    if (refereeError?.type === "required") {
      return t("registration:errors.referee-is-required");
    }
    if (refereeError?.type === "validate") {
      return t("registration:errors.referee-must-be-chinese-or-english");
    }
    if (refereeError?.type === "maxLength") {
      return t("registration:errors.referee-length");
    }
    return "";
  };

  return (
    <>
      <CustomizedRegistrationPaper>
        <Typography className={styles.paperTitle} variant="h5">
          {t("site.name")}
        </Typography>
        <Typography className={styles.paperTitle} variant="h6">
          {t("registration:subtitle")}
        </Typography>
        <CustomizedRegistrationDivider />
        <form ref={formEl}>
          <Grid container component="main" spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" color="primary">
                {t("registration:login-info")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                autoComplete="off"
                type="email"
                label={`${t("label.email")} *`}
                {...register("email", {
                  required: true,
                  validate: IsValidEmail
                })}
                error={!!formErrors.email}
                helperText={emailHelperText(formErrors.email)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register("password", {
                  required: true,
                  minLength: 8,
                  validate: IsValidPasswordFormat
                })}
                variant="standard"
                label={`${t("label.password")}*`}
                type="password"
                autoComplete="new-password"
                error={!!formErrors.password}
                helperText={passwordHelperText(formErrors.password)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register("confirm_password", {
                  required: true,
                  validate: IsCorrectConfirmPassword
                })}
                variant="standard"
                label={`${t("label.confirm-password")}*`}
                type="password"
                autoComplete="new-password"
                error={!!formErrors.confirm_password}
                helperText={confirmPasswordHelperText(formErrors.confirm_password)}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ display: { xs: "none", sm: "block" } }} />
              <CustomizedTypography variant="h5" color="primary">
                {t("registration:personal-info")}
              </CustomizedTypography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register("name_chi", {
                  required: true,
                  maxLength: 6,
                  validate: IsValidChineseName
                })}
                variant="standard"
                type="text"
                label={`${t("registration:pi.name-chi")}*`}
                error={!!formErrors.name_chi}
                helperText={chiNameHelperText(formErrors.name_chi)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register("name_eng", {
                  required: true,
                  maxLength: 31,
                  validate: IsValidEnglishName
                })}
                variant="standard"
                type="text"
                label={`${t("registration:pi.name-eng")}*`}
                error={!!formErrors.name_eng}
                helperText={engNameHelperText(formErrors.name_eng)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  disableFuture
                  label={t("registration:pi.dob")}
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={dobValue}
                  onChange={(newValue) => {
                    setDobValue(newValue);
                    setValue("dob", newValue);
                  }}
                  mask="____-__-__"
                  inputFormat="yyyy-MM-dd"
                  renderInput={(params) => {
                    return (
                      <TextField fullWidth {...params} {...register("dob")} variant="standard" />
                    );
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                {...register("telephone", {
                  required: true,
                  validate: IsValidPhone
                })}
                variant="standard"
                type="text"
                label={`${t("registration:pi.telephone")}*`}
                error={!!formErrors.telephone}
                helperText={formErrors.telephone && t("registration:errors.telephone-is-required")}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                {...register("referee", {
                  required: true,
                  validate: IsValidReferee,
                  maxLength: 32
                })}
                variant="standard"
                type="text"
                autoComplete="off"
                label={`${t("registration:pi.referee")}*`}
                error={!!formErrors.referee}
                helperText={refereeHelperText(formErrors.referee)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {countries ? (
                <Autocomplete
                  {...register("country", { required: true })}
                  options={countries}
                  onChange={(event, newValue) => {
                    clearFormErrors("country");
                    // setCountryID(newValue ? newValue.id : 0);
                    console.log("country", newValue);
                    setValue("country", newValue);
                  }}
                  getOptionLabel={GetStandardOptionLabel}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={`${t("registration:pi.district")}*`}
                        variant="standard"
                        error={!!formErrors.country}
                        helperText={
                          formErrors.country && t("registration:errors.district-is-required")
                        }
                      />
                    );
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {jobNatures ? (
                <Autocomplete
                  {...register("job_nature")}
                  options={jobNatures}
                  getOptionLabel={GetStandardOptionLabel}
                  onChange={(event, newValue) => {
                    clearFormErrors("job_nature");
                    // setJobNatureID(newValue ? newValue.id : 0);
                    setValue("job_nature", newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t("registration:pi.job-nature")}
                        variant="standard"
                      />
                    );
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {churches ? (
                <Autocomplete
                  {...register("church")}
                  options={churches}
                  groupBy={GetCountryGroupOptionLabel}
                  getOptionLabel={GetStandardOptionLabel}
                  onChange={(event, newValue) => {
                    clearFormErrors("church");
                    // setChurchID(newValue ? newValue.id : 0);
                    setValue("church", newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t("registration:pi.parish")}
                        variant="standard"
                      />
                    );
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {authorities ? (
                <Autocomplete
                  {...register("authority")}
                  options={authorities}
                  groupBy={GetCountryGroupOptionLabel}
                  getOptionLabel={GetStandardOptionLabel}
                  onChange={(event, newValue) => {
                    clearFormErrors("authority");
                    // setAuthorityID(newValue ? newValue.id : 0);
                    setValue("authority", newValue);
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label={t("registration:pi.authority")}
                        variant="standard"
                      />
                    );
                  }}
                />
              ) : (
                <LinearProgress />
              )}
            </Grid>
          </Grid>
        </form>

        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <CustomizedGrid item>
                <Button onClick={handleCancel} variant="outlined">
                  {t("button.cancel")}
                </Button>
              </CustomizedGrid>
              <CustomizedGrid item>
                {/* <Button type="submit" variant="contained"> */}
                <Button onClick={handleSubmit(submitForm)} variant="contained">
                  {t("button.submit")}
                </Button>
              </CustomizedGrid>
            </Grid>
          </Grid>
        </Grid>
        <CustomizedRegistrationPaperContent style={{ marginTop: "40px" }}>
          <Copyright />
        </CustomizedRegistrationPaperContent>
      </CustomizedRegistrationPaper>
    </>
  );
};

export default RegisterUser;
