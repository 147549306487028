import { V_SITE_API_URL } from "config/config";
import type {
  RcFile,
  UploadRequestOption as RcCustomRequestOptions
} from "rc-upload/lib/interface";
import SparkMD5 from "spark-md5";
import ResolveError from "util/connUtil";
import { ACCESS_TOKEN, SAVED_LANG } from "util/const";
import { UploadFile } from "antd/lib/upload/interface";

export const FileStatusDone = "done";
export const FileStatusError = "error";
export const FileStatusUploading = "uploading";
const ChunkPath = `${V_SITE_API_URL}/file/chunk`;

export interface MyFileUpload extends UploadFile {
  uploaded_filename?: string;
  ftu?: any; // FileToUpload
}

export interface FileToUploadResult {
  success: boolean;
  error_message: string;
  file_path: string;
  regenreate: boolean;
}

// export type FileToUploadSizeCallback = (uploadSize: number, isFinal: boolean) => void;

export default class FileToUpload {
  static chunkSize = 2097152 * 1; // 2M
  // static chunkSize = 20971 * 1; // testing

  readonly request: XMLHttpRequest;

  readonly file: RcFile;

  fileMd5: string = "";

  readonly name: string;

  fileRemoved: boolean = false;

  currentChunkStartByte: number;

  currentChunkFinalByte: number;

  sessionFile: string = "";

  result: FileToUploadResult = {
    success: false,
    file_path: "",
    error_message: "",
    regenreate: false
  };

  spark = new SparkMD5.ArrayBuffer();

  static blobSlice = File.prototype.slice;
  // || File.prototype.mozSlice || File.prototype.webkitSlice;

  constructor(file: RcFile, name: string) {
    this.request = new XMLHttpRequest();
    this.request.overrideMimeType("application/octet-stream");

    this.file = file;
    // console.log("blobSlice", this.file);
    this.name = name;
    this.currentChunkStartByte = 0;
    this.currentChunkFinalByte = Math.min(FileToUpload.chunkSize, file.size);
  }

  async init() {
    const fileMd5 = await this.calcFileMD5(this.file);
    this.fileMd5 = fileMd5 as string;
  }

  async syncFileChunkUpload(fileMd5: string, options: RcCustomRequestOptions) {
    if (this.fileRemoved) {
      return new Error("File Removed");
    }
    const { onError, onSuccess, onProgress } = options;
    const token = localStorage.getItem(ACCESS_TOKEN);
    this.request.open("PUT", `${ChunkPath}/${this.sessionFile}`, true);
    this.request.setRequestHeader("Content-MD5", fileMd5);
    this.request.setRequestHeader("Authorization", `Bearer ${token}`);
    const lang = localStorage.getItem(SAVED_LANG);
    if (lang) {
      this.request.setRequestHeader("Accept-Language", lang);
    }
    const chunk = FileToUpload.blobSlice.call(
      this.file,
      this.currentChunkStartByte,
      this.currentChunkFinalByte
    );
    const blobEnd = this.currentChunkFinalByte - 1;
    this.request.setRequestHeader(
      "Content-Range",
      `bytes ${this.currentChunkStartByte}-${blobEnd}/${this.file.size}`
    );
    const xhr = this.request;

    return new Promise((resolve, reject) => {
      xhr.onload = () => {
        // console.log("this.file.size", this.file.size, this.currentChunkFinalByte);
        const remainingBytes = this.file.size - this.currentChunkFinalByte;
        if (
          this.currentChunkFinalByte === this.file.size &&
          (xhr.status === 201 || xhr.status === 200)
        ) {
          const jsonResponse = JSON.parse(xhr.response);
          this.result.success = true;
          this.result.file_path = jsonResponse?.name;
          onProgress?.({ percent: 100 });
          // console.log("finished", xhr.response);
          onSuccess?.(xhr.response);
          resolve(this.result);
          return;
        }
        // console.log("remainingBytes", remainingBytes);
        if (xhr.status !== 308) {
          // onError?.(xhr.response);
          // console.log("set onError", xhr.response);
          // console.log("ResolveError(rejectData)", ResolveError(JSON.parse(xhr.response)));
          reject(JSON.parse(xhr.response));
          return;
        }

        if (remainingBytes === 0) {
          onError?.("Unknown Error" as any);
          return;
        }

        // only 308 will do this
        if (remainingBytes < FileToUpload.chunkSize) {
          this.currentChunkStartByte = this.currentChunkFinalByte;
          this.currentChunkFinalByte = this.currentChunkStartByte + remainingBytes;
        } else {
          this.currentChunkStartByte = this.currentChunkFinalByte;
          this.currentChunkFinalByte = this.currentChunkStartByte + FileToUpload.chunkSize;
        }
        // console.log("this.currentChunkFinalByte", remainingBytes, this.currentChunkFinalByte);
        onProgress?.({ percent: Math.floor((this.currentChunkFinalByte / this.file.size) * 100) });
        this.syncFileChunkUpload(fileMd5, options).then(resolve);
      };
      xhr.onerror = () => {
        onError?.(xhr.response);
        console.log("xhr.response", xhr.response);
        // if (xhr.response) {
        //   reject(xhr.response);
        // }
        // reject(new Error("Network response with Error"));
      };
      xhr.send(chunk);
    }).catch((rejectData: any) => {
      onError?.(rejectData);
      // console.log("catch", rejectData);
      return new Error(ResolveError(rejectData));
    });
  }

  uploadFile = async (options: RcCustomRequestOptions) => {
    if (this.fileMd5 === "") {
      await this.init(); // call the MD5 of this file
    }
    const result = await this.syncFileChunkUpload(this.fileMd5, options);
    if (result instanceof Error) {
      throw result.message;
    }
  };

  calcFileMD5 = (file: RcFile) => {
    return new Promise((resolve, reject) => {
      const chunks = Math.ceil(this.file.size / FileToUpload.chunkSize);
      let currentChunk = 0;
      const fileReader = new FileReader();

      function loadNext() {
        const start = currentChunk * FileToUpload.chunkSize;
        const end =
          start + FileToUpload.chunkSize >= file.size ? file.size : start + FileToUpload.chunkSize;
        fileReader.readAsArrayBuffer(FileToUpload.blobSlice.call(file, start, end));
      }
      fileReader.onload = (e) => {
        this.spark.append(e.target?.result);
        currentChunk += 1;
        if (currentChunk < chunks) {
          loadNext();
        } else {
          // console.log('finished loading');
          // const result = spark.end();
          // 如果单纯的使用result 作为hash值的时候, 如果文件内容相同，而名称不同的时候
          // 想保留两个文件无法保留。所以把文件名称加上。
          // const sparkMd5 = new SparkMD5();
          // sparkMd5.append(result);
          // sparkMd5.append(file.name);
          const hexHash = this.spark.end();
          resolve(hexHash);
        }
      };

      fileReader.onerror = () => {
        reject(fileReader.error);
        // reader.abort();
        console.warn("oops, something went wrong.");
      };
      loadNext();
    });
  };
}
