import React, { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Paper,
  InputBase,
  Divider,
  Popover,
  Autocomplete,
  TextField,
  Box,
  Typography,
  useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { TreeSelect } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { userHasPermissionSimple } from "util/token";
import { CREATE_EDIT_RESOURCE } from "util/const";
import { CategoryContext } from "admin/category/categoryContext";
import { TopicSearchFormData } from "../data.d";

export interface TopicFilterProps {
  resetAndSearchCallback: (data: TopicSearchFormData) => void;
  initValue?: TopicSearchFormData;
}

const OnCancel = "onCancelled";

const TopicFilter: React.FC<TopicFilterProps> = (props) => {
  const { initValue, resetAndSearchCallback } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation(["translation", "topic"]);
  const { register, handleSubmit, setValue, getValues, control } = useForm();
  const [showMoreOption, setShowMoreOption] = useState<boolean>();
  const hasResourcesPermission = userHasPermissionSimple(CREATE_EDIT_RESOURCE);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<number | null | undefined | string>(
    null
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { categoryTreeSelectItemUI } = useContext(CategoryContext);

  const handleSearch = (formData: TopicSearchFormData) => {
    // console.log("formData", formData);
    // if (initValue?.category) {
    //   formData.category = initValue?.category;
    // }
    resetAndSearchCallback(formData);
  };

  const handleStatusChange = (/* event: React.SyntheticEvent<Element, Event> */) => {
    const formValues = getValues();
    // console.log("formValues", formValues);
    handleSearch({
      topic: formValues.topic,
      status: formValues.status,
      category: formValues.category
    });
    if (!(hasResourcesPermission && isMobile)) {
      setAnchorEl(null);
    }
  };

  const statusOptions = [
    { label: t("status.inactive"), id: 1, value: 0 },
    { label: t("status.active"), id: 2, value: 1 }
  ];

  useEffect(() => {
    const showMore = hasResourcesPermission || isMobile;
    setShowMoreOption(showMore);
  }, [isMobile]);

  useEffect(() => {
    // console.log("initValue", initValue);
    // console.log("categoryTreeSelectItemUI", categoryTreeSelectItemUI);
    if (initValue && categoryTreeSelectItemUI) {
      // we check form value, because we send the form value out and don't want to init again
      const formValues = getValues();
      setValue("topic", initValue?.topic);
      if (
        formValues.status === undefined ||
        Number(formValues.status) !== Number(initValue?.status)
      ) {
        const initStatus = statusOptions.find((a) => {
          return a.value.toString() === initValue?.status?.toString();
        });
        setValue("status", initStatus?.value);
        setStatusId(initStatus?.id || 0);
      }
      // console.log(
      //   "initValue",
      //   initValue,
      //   selectedCategory,
      //   initValue?.category,
      //   formValues.category
      // );

      if (selectedCategory !== OnCancel && formValues.category !== initValue?.category) {
        setSelectedCategory(initValue?.category ? Number(initValue?.category) : null);
        setValue("category", initValue?.category ? Number(initValue?.category) : null);
        // }
      } else if (selectedCategory === OnCancel) {
        setSelectedCategory(null);
      }
    }
  }, [initValue, categoryTreeSelectItemUI]);
  const { ref: topicFormHookRef, ...topicFormHookRest } = register("topic");
  const { ref: statusFormHookRef } = register("status");

  return (
    <form ref={formEl} autoComplete="off" onSubmit={handleSubmit(handleSearch)}>
      <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", opacity: "0.7" }}>
        <InputBase
          {...topicFormHookRest}
          inputRef={topicFormHookRef}
          sx={{ ml: 1, flex: 1 }}
          placeholder={t("topic:label.search-topic")}
          inputProps={{ "aria-label": t("topic:label.search-topic") }}
        />
        {/* <IconButton onClick={clearSearchValue}>
          <ClearIcon />
        </IconButton> */}
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>

        <IconButton
          hidden={!showMoreOption}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
      <Popover
        id="more-filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {hasResourcesPermission && statusOptions && (
          <Autocomplete
            options={statusOptions}
            value={
              statusOptions.find((a) => {
                return a.id === statusId;
              }) || null
            }
            // getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            onChange={(event, newValue) => {
              setValue("status", newValue?.value); // value for the formValue
              setStatusId(newValue?.id!); // id for the internal state checking
              handleStatusChange();
            }}
            sx={{ minWidth: "150px", m: 1 }}
            renderInput={(params) => {
              // console.log("params", params);
              return (
                <TextField
                  {...params}
                  inputRef={statusFormHookRef}
                  label={t("label.status")}
                  variant="standard"
                />
              );
            }}
          />
        )}
        {isMobile && (
          <Box
            sx={{ width: "30em", paddingTop: "10px", paddingRight: "15px", marginBottom: "20px" }}
          >
            <Typography variant="subtitle2" style={{ marginLeft: "10px", color: "#00000099" }}>
              {t("topic:label.category")}
            </Typography>
            <Controller
              control={control}
              name="category"
              rules={{ required: true }}
              defaultValue={selectedCategory}
              render={(iProps) => {
                // console.log("props", props);
                return (
                  <TreeSelect
                    showSearch
                    allowClear
                    style={{ width: "100%" }}
                    value={iProps.field.value}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                      zIndex: 9999
                    }}
                    bordered={false}
                    placeholder={t("topic:place-holder.category")}
                    treeDefaultExpandAll
                    treeNodeFilterProp="displayName"
                    onChange={(value) => {
                      iProps.field.onChange(value);
                      if (value === undefined) {
                        // Set to another value so that I can identify user has click the "cross" button
                        setSelectedCategory(OnCancel);
                        setValue("category", undefined);
                      }
                      handleStatusChange();
                    }}
                  >
                    {categoryTreeSelectItemUI}
                  </TreeSelect>
                );
              }}
            />
          </Box>
        )}
      </Popover>
    </form>
  );
};

export default TopicFilter;
