import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

interface ComfirmationDialogProps {
  open: boolean;
  dialogTitle?: string;
  messageToDisplay: string;
  setOpen: (value: boolean) => void;
  okAction: () => void;
}

const ComfirmationDialog: React.FC<ComfirmationDialogProps> = (props) => {
  const { open, setOpen, messageToDisplay, dialogTitle, okAction } = props;
  const { t } = useTranslation(["translation"]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {dialogTitle || t("dialog.title-warning")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{messageToDisplay}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t("button.no")}
          </Button>
          <Button onClick={okAction}>{t("button.yes")}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ComfirmationDialog;

ComfirmationDialog.defaultProps = {
  dialogTitle: ""
};
