import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedDialogDivider = styled(Divider)(() => {
  return `
        margin-top: 5px;
        margin-bottom: 10px;
      `;
});

export default CustomizedDialogDivider;
