import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from "@mui/material";
import { format as formatDate } from "date-fns";
import styles from "style/common.module.css";
import CloseIcon from "@mui/icons-material/Close";
import TopicAccordion from "res/resource/topicAccordion";
import InfoResourceList from "res/resource/infoResourceList";
import { AxiosResponse } from "axios";
import { AlertContext } from "component/alert/alertContext";
import { ResolveError } from "util/connUtil";
import { useTranslation } from "react-i18next";
import type { TopicDto } from "res/topic/data.d";
import { ResourceGroup } from "./data.d";
import { getTopicDtoByResGroup } from "./service";

interface InfoProps {
  resourceGroup: ResourceGroup;
  open: boolean;
  onClose: () => void;
}

const Info: React.FC<InfoProps> = (props) => {
  const { resourceGroup, onClose, open: dialogOpen } = props;
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [topicDto, setTopicDto] = useState<TopicDto[] | undefined>();
  const { t } = useTranslation(["translation", "resource"]);

  const handleClose = () => {
    setTopicDto(undefined);
    onClose();
  };

  useEffect(() => {
    if (resourceGroup && resourceGroup.id > 0) {
      setLoadingBackdrop(true);
      getTopicDtoByResGroup(resourceGroup.id)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setTopicDto(response.data.data as TopicDto[]);
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    }
  }, [resourceGroup]);

  const renderTopicAccordion = (topic: TopicDto, totalNumberOfTopics: number) => {
    return (
      <Box key={topic.id} sx={{ width: "100%", marginBottom: "10px" }}>
        <TopicAccordion
          resource_group_id={resourceGroup.id}
          topic={topic}
          defaultOpen={totalNumberOfTopics === 1}
        />
      </Box>
    );
  };

  return (
    <>
      {resourceGroup && (
        <Dialog
          key="topic-info-modal-dialog"
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby={resourceGroup.name}
          aria-describedby={resourceGroup.name}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="topic-info-dialog-title">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography color="primary" variant="h6">
                {resourceGroup.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row"
                  }}
                >
                  <Typography variant="subtitle2">
                    {formatDate(new Date(resourceGroup.republish_at), "yyyy-MM-dd")}
                  </Typography>
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Divider className={styles.modalDivider} />
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Box sx={{ width: "100%", marginBottom: "10px" }}>
                <Typography
                  variant="h6"
                  style={{ whiteSpace: "pre-wrap" }}
                  className={styles.grayStyleLevel2}
                >
                  {resourceGroup.description}
                </Typography>
              </Box>
              <Paper sx={{ marginTop: "10px", marginBottom: "10px", width: "100%" }}>
                <Box>
                  <InfoResourceList resources={resourceGroup.resource} />
                </Box>
              </Paper>
              {topicDto && topicDto.length > 0 && (
                <Box sx={{ width: "100%", marginTop: "20px", marginBottom: "1px" }}>
                  <Typography>{t("resource:label.relevant-topic")}</Typography>
                </Box>
              )}
              {topicDto ? (
                topicDto.map((topic) => {
                  return renderTopicAccordion(topic, topicDto.length);
                })
              ) : (
                <LinearProgress />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Info;
