import { Meta } from "component/table/data.d";
import { IMultiAutoComplete } from "util/display";
import { Role } from "../role/data.d";

export const STATUS_PENDING_FOR_REVIEW = 5;
export const STATUS_ACTIVE = 10;
export const STATUS_INACTIVE = 0;
export const STATUS_REJECTED = -1;

export interface UpdateUser {
  email: string;
  name_chi: string;
  name_eng: string;
  dob?: string;
  telephone: string;
  job_nature_id?: number;
  church_id?: number;
  authority_id?: number;
  country_id: number;
  referee: string;
  status: number;
  role?: Partial<IMultiAutoComplete>[];
  role_ids?: (number | undefined)[];
}

export interface User {
  id: number;
  email: string;
  legacy_user_name: string;
  registration_date: string;
  user_last_login?: string;
  name_chi: string;
  name_eng: string;
  dob?: string;
  telephone: string;
  job_nature_id: number;
  job_nature: string;
  church_id: number;
  church: string;
  authority_id: number;
  authority: string;
  country_id: number;
  country: string;
  referee: string;
  status: number;
  status_label: string;
  login_count: number;
  role_list?: Role[];
}

export interface UserTableData {
  data: User[];
  meta: Meta;
}

export interface UserStatus {
  value: number;
  name: string;
}

export interface UserFormFilterInitProps {
  email?: string;
  name?: string;
  referee?: string;
  registration_from?: Date;
  registration_to?: Date;
  status?: string[] | undefined;
  country_id?: string[] | undefined;
  authority_id?: string[] | undefined;
  job_nature_id?: string[] | undefined;
  church_id?: string[] | undefined;
  role_id?: string[] | undefined;
}

export interface UserFormFilterProps {
  email?: string;
  name?: string;
  referee?: string;
  registration_from?: Date;
  registration_to?: Date;
  status?: UserStatus[];
  country?: Partial<IMultiAutoComplete>[];
  authority?: Partial<IMultiAutoComplete>[];
  job_nature?: Partial<IMultiAutoComplete>[];
  church?: Partial<IMultiAutoComplete>[];
  role?: Partial<IMultiAutoComplete>[];
  // below is for converting a ID array list
  // status_ids?: (number | undefined)[];
  // country_ids?: (number | undefined)[];
  // authority_ids?: (number | undefined)[];
  // job_nature_ids?: (number | undefined)[];
  // church_ids?: (number | undefined)[];
  // role_ids?: (number | undefined)[];
}
