import React, { useEffect, useState, useContext } from "react";
import { AlertContext } from "component/alert/alertContext";
import { useTranslation } from "react-i18next";
import { axios } from "util/request";
import { getRecordID } from "util/helper";
import { ResolveError } from "util/connUtil";
import { getScopes, userHasPerission } from "util/token";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FEATURE_NAME_TOPIC, TOPIC_PATH, CREATE_EDIT_RESOURCE, twoDecimal } from "util/const";
import { V_SITE_API_URL } from "config/config";
import { AxiosResponse } from "axios";
import Qs from "qs";
import { useLocation } from "react-router";
import { format as formatDate } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import {
  CardHeader,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  LinearProgress,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Box,
  useMediaQuery
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useNavigate } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTheme } from "@mui/material/styles";
import { MonitorLocationUpdateAction } from "component/table/filterHelper";
import {
  loadNewPageData,
  getFilterValueStringifyed,
  getFilterValue
} from "component/table/paginatorHelper";
import styles from "style/common.module.css";
import { SaveSetting, GetSavedSetting } from "component/table/paginatorSetting";
import TablePaginator from "component/table/paginator";
import OwnHighlighter from "component/text/highlighter";
import {
  CRITERIA_TYPE_TOPIC,
  CRITERIA_TYPE_CATEGORY,
  CRITERIA_TYPE_STATUS,
  TopicTableData,
  Topic,
  TopicSearchFormData
} from "./data.d";
import CreateEditTopic from "./createEdit";
import TopicFilter from "./filter/filter";
import CategoryExpandFilter from "./categoryExpandFilter";
import SearchCriteriaList from "./filter/searchCriteriaList";
import Info from "./info";
import { addViewCount } from "./service";

const ResTopic = () => {
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const location = useLocation();
  // const tablestyles = useTableStyles();
  // const styles = useCardStyles();
  const [topicTableData, setTopicTableData] = useState<TopicTableData>();
  const [topicList, setTopicList] = useState<any>();
  const [openAddTopic, setOpenAddTopic] = useState<boolean>(false);
  const [openEditTopic, setOpenEditTopic] = useState<boolean>(false);
  const [openTopicInfo, setOpenTopicInfo] = useState<boolean>(false);
  const [dataToCriteria, setDataToCriteria] = useState<any>(undefined);
  const [clearSelectedCategory, setClearSelectedCategory] = useState<boolean>(false);
  const [currentEditingTopic, setCurrentEditingTopic] = useState<Topic | undefined>();
  const { t } = useTranslation(["translation", "topic"]);
  const scopes = getScopes();
  const hasResourcesPermission = userHasPerission(scopes, [CREATE_EDIT_RESOURCE]);
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const executeFetchTopic = (iParams: any) => {
    setLoadingBackdrop(true);
    const actualUrl = "/search/topic";
    setLoadingData(true);
    axios
      .get(actualUrl, {
        params: iParams,
        baseURL: V_SITE_API_URL,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        }
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const newData: TopicTableData = response.data;
          if (isMobile && topicList?.data && response.data.meta.current_page > 1) {
            newData.data = [...topicList.data, ...response.data.data];
          }
          setTopicList(newData as TopicTableData);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error.message}, ${t("error.connection")}`
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
        setLoadingData(false);
      });
  };

  const localLoadNewPageData = (optionIsNextPage?: boolean) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_TOPIC);
    let pageNo = pageSetting.current_page!;
    if (optionIsNextPage) {
      pageNo += 1;
    }
    loadNewPageData(
      pageNo,
      pageSetting.record_per_page!,
      FEATURE_NAME_TOPIC,
      pageSetting.filter_value,
      executeFetchTopic
    );
  };

  const loadMore = () => {
    if (isMobile) {
      localLoadNewPageData(true);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loadingData,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !isMobile,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 50px 0px"
  });

  const resetAndSearchCallback = (data: TopicSearchFormData) => {
    SaveSetting({
      current_page: 1,
      feature_name: FEATURE_NAME_TOPIC,
      filter_value: data
    });
    const urlPara = Qs.stringify(data, { arrayFormat: "repeat" });
    let historyPath = TOPIC_PATH;
    if (urlPara !== "") {
      historyPath += `?${urlPara}`;
    }
    navigate(historyPath);
  };

  useEffect(() => {
    if (isMobile) {
      const pageSetting = GetSavedSetting(FEATURE_NAME_TOPIC);
      resetAndSearchCallback(pageSetting.filter_value);
    }
  }, [isMobile]);

  const runExecuteFetchTopic = () => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_TOPIC);
    // console.log("pageSetting.filter_value", pageSetting.filter_value);
    setDataToCriteria(pageSetting.filter_value);

    executeFetchTopic &&
      executeFetchTopic({
        ...pageSetting.filter_value,
        page_size: pageSetting.record_per_page,
        current_page: pageSetting.current_page
      });
  };
  useEffect(() => {
    MonitorLocationUpdateAction({
      location,
      featureName: FEATURE_NAME_TOPIC,
      featurePath: TOPIC_PATH,
      fnrExecuteFetchList: runExecuteFetchTopic
    });
  }, [location]);

  // rely on useEffect [location]
  // useEffect(() => {
  //   LoadDataByCheckingUrl({
  //     iLocation: location,
  //     featureName: FEATURE_NAME_TOPIC,
  //     fnrExecuteFetchList: runExecuteFetchTopic
  //   });
  // }, []);

  const handleEditTopic = (topic: Topic) => {
    setCurrentEditingTopic(topic);
    setOpenEditTopic(true);
  };

  const actionButton = (topic: Topic): React.ReactNode => {
    const tooltipsEditString: string = t("button.edit");

    const editButton = (
      <IconButton
        aria-label="edit"
        key={`edit_button${topic.id}`}
        onClick={() => {
          handleEditTopic(topic);
          // console.log("openTopic", openEditTopic);
        }}
      >
        <Tooltip title={tooltipsEditString} placement="right">
          <EditIcon color="secondary" />
        </Tooltip>
      </IconButton>
    );
    return editButton;
  };

  useEffect(() => {
    if (topicList && topicList.data) {
      if (topicList.meta.current_page < topicList.meta.total_page) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
      setTopicTableData({
        data: topicList.data.map((row: Topic, index: number) => {
          return (
            <Box key={`whole${row.id}`} sx={{ display: "flex", flexDirection: "row" }}>
              {row.show_edit_button && (
                <div
                  key={`edit_button${row.id}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column"
                  }}
                >
                  {actionButton(row)}
                </div>
              )}
              <Card key={row.id} className={styles.cardRoot}>
                <CardActionArea
                  className={row.not_ready_to_publish ? styles.grayStyleBackground : ""}
                  sx={
                    row.status === 0
                      ? { backgroundColor: "pink" }
                      : { backgroundColor: "white", opacity: "0.7" }
                  }
                  onClick={() => {
                    setCurrentEditingTopic(row);
                    setOpenTopicInfo(true);
                    addViewCount(row.id);
                  }}
                >
                  <CardContent style={{ paddingBottom: "10px" }} key={`content${row.id}`}>
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              flexGrow: 1
                            }}
                          >
                            <Typography
                              style={{
                                minWidth: "2em",
                                paddingRight: "1em"
                              }}
                            >
                              {getRecordID(
                                index,
                                isMobile ? 1 : topicList.meta.current_page,
                                topicList.meta.record_per_page
                              )}
                              .
                            </Typography>
                            <div style={{ width: "100%", flexGrow: 1 }}>
                              <Typography
                                key={`name${row.id}`}
                                className={styles.cardTitle}
                                color="primary"
                                gutterBottom
                              >
                                <OwnHighlighter
                                  searchContent={row.name}
                                  searchTerm={dataToCriteria.topic}
                                />
                              </Typography>
                              <Typography
                                style={{ whiteSpace: "pre-wrap" }}
                                key={`description${row.id}`}
                              >
                                <OwnHighlighter
                                  searchContent={row.description}
                                  searchTerm={dataToCriteria.topic}
                                />
                              </Typography>
                            </div>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              flexDirection: "row-reverse",
                              display: { sm: "none", md: "flex" }
                            }}
                          >
                            <Typography variant="subtitle2">
                              {row.score > 0 && (
                                <>
                                  <span className={styles.grayStyleLevel1}>
                                    {t("topic:label.score")}:{" "}
                                  </span>
                                  <span className={styles.grayStyleLevel2}>
                                    {twoDecimal(row.score)}
                                    {", "}
                                  </span>
                                </>
                              )}
                              <span className={styles.grayStyleLevel1}>
                                {t("topic:label.last-updated-at")}:{" "}
                              </span>
                              <span className={styles.grayStyleLevel2}>
                                {formatDate(new Date(row.republish_at), "yyyy-MM-dd")}
                              </span>
                              {hasResourcesPermission && (
                                <>
                                  <span className={styles.grayStyleLevel1}>
                                    {", "} {t("topic:label.view-count")}:{" "}
                                  </span>
                                  <span className={styles.grayStyleLevel2}>{row.view_count}</span>
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                </CardActionArea>
                {/* <CardActions></CardActions> */}
              </Card>
            </Box>
          );
        }),
        meta: topicList.meta
      });
    }
  }, [topicList]);

  const removeCriteriaByType = (type: string) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_TOPIC);
    switch (type) {
      case CRITERIA_TYPE_CATEGORY:
        pageSetting.filter_value.category = undefined;
        break;
      case CRITERIA_TYPE_TOPIC:
        pageSetting.filter_value.topic = "";
        break;
      case CRITERIA_TYPE_STATUS:
        delete pageSetting.filter_value.status;
        break;
      default:
        pageSetting.filter_value = undefined;
    }
    if (!pageSetting.filter_value || !pageSetting.filter_value?.category) {
      setClearSelectedCategory(true);
    }
    resetAndSearchCallback(pageSetting.filter_value);
  };

  const handleCategoryIsSelected = (selectedID: string, removeOtherCriteria?: boolean) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_TOPIC);
    let topic = pageSetting.filter_value?.topic ?? "";
    if (removeOtherCriteria) {
      topic = "";
    }
    resetAndSearchCallback({ topic, category: Number(selectedID) });
  };

  const addTopicButton = (): React.ReactNode => {
    const tooltipStr: string = t("topic:label.add-topic");
    if (hasResourcesPermission) {
      if (!isMobile) {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setOpenAddTopic(true);
            }}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {tooltipStr}
          </Button>
        );
      }
      return (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenAddTopic(true);
          }}
        >
          <Tooltip title={tooltipStr} placement="top">
            <AddBoxIcon />
          </Tooltip>
        </IconButton>
      );
    }

    return <></>;
  };

  const initCategorySelectedValue = () => {
    const category = GetSavedSetting(FEATURE_NAME_TOPIC).filter_value?.category;
    return category ? [category] : [];
  };
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ p: 1, display: { xs: "none", md: "block" } }}>
          <CategoryExpandFilter
            clearAllSelected={clearSelectedCategory}
            initSelected={initCategorySelectedValue()}
            searchByCategory={handleCategoryIsSelected}
          />
        </Box>
        <Box sx={{ p: 1, flexGrow: 4 }}>
          <Card sx={{ marginRight: "10px", backgroundColor: "transparent" }}>
            <CardHeader title={t("nav.topic")} action={addTopicButton()} />
            <CardContent>
              <Grid container>
                <Grid item xs={12} className={styles.filterAreaSpacing}>
                  <TopicFilter
                    initValue={getFilterValue(FEATURE_NAME_TOPIC)}
                    resetAndSearchCallback={resetAndSearchCallback}
                  />
                  <SearchCriteriaList
                    removeCriteriaByType={removeCriteriaByType}
                    filterData={dataToCriteria}
                  />
                </Grid>
                {isMobile && (
                  <Grid item xs={12} style={{ paddingBottom: "5px" }}>
                    {topicTableData &&
                      topicTableData.meta &&
                      t("table.total-no-of-record", {
                        number: topicTableData!.meta!.total_record.toString()
                      })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {topicTableData && topicTableData.data ? topicTableData.data : <LinearProgress />}
                  {isMobile && (loadingData || hasNextPage) && (
                    <div key="x" ref={sentryRef}>
                      <LinearProgress />
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              {!isMobile && topicTableData && topicTableData.meta && (
                <TablePaginator
                  key="table-paginator"
                  recordPerPageOptions={{
                    defaultOption: topicTableData.meta.record_per_page
                  }}
                  recordFrom={topicTableData.meta.from}
                  recordTo={topicTableData.meta.to}
                  currentPage={topicTableData.meta.current_page}
                  recordTotal={topicTableData.meta.total_record}
                  noOfPages={topicTableData.meta.total_page}
                  featureName={FEATURE_NAME_TOPIC}
                  urlPath={TOPIC_PATH}
                  urlQuery={getFilterValueStringifyed(FEATURE_NAME_TOPIC)}
                />
              )}
            </CardActions>
          </Card>
        </Box>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CreateEditTopic
          open={openAddTopic}
          onClose={(success: boolean) => {
            if (success) {
              localLoadNewPageData();
            }
            setOpenAddTopic(false);
          }}
        />
        {currentEditingTopic && (
          <CreateEditTopic
            topic={currentEditingTopic}
            open={openEditTopic}
            onClose={(success: boolean) => {
              if (success) {
                localLoadNewPageData();
              }
              setCurrentEditingTopic(undefined);
              setOpenEditTopic(false);
            }}
            // submitUpdateCallback={() => {
            //   setOpenEditTopic(false);
            //   localLoadNewPageData();
            // }}
          />
        )}
      </LocalizationProvider>
      <Info
        topic={currentEditingTopic as Topic}
        open={openTopicInfo}
        onClose={() => {
          setOpenTopicInfo(false);
        }}
        searchByCategory={handleCategoryIsSelected}
      />
    </>
  );
};

export default ResTopic;
