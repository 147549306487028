import React from "react";
import { AlertContextProvider } from "component/alert/alertContext";
import RegisterUserForm from "./registerUserForm";

const RegisterUesr = () => {
  return (
    <AlertContextProvider>
      <RegisterUserForm />
    </AlertContextProvider>
  );
};

export default RegisterUesr;
