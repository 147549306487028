import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "util/request";
import { IMultiAutoComplete } from "util/display";
import { AlertContext } from "component/alert/alertContext";
import { Authority } from "../authority/data.d";
import { Church } from "../church/data.d";
import { JobNature } from "../job-nature/data.d";
import { Country } from "../country/data.d";
import { Role } from "../role/data.d";
import { UserStatus } from "./data.d";

export interface UserOptionContextState {
  data: {
    statusList: UserStatus[];
    countryList: Country[];
    churchList: Church[];
    jobNatureList: JobNature[];
    authorityList: Authority[];
    roleList: Role[];
  };
}

const defaultValue: UserOptionContextState = {
  data: {
    statusList: [],
    countryList: [],
    churchList: [],
    jobNatureList: [],
    authorityList: [],
    roleList: []
  }
};

export const chooseSelectedFilterUser = (
  selectedValue?: string[],
  userStatusOption?: UserStatus[]
): UserStatus[] => {
  return (
    userStatusOption?.filter((s) => {
      if (Array.isArray(selectedValue)) {
        return selectedValue?.includes(s.value.toString());
      }
      return selectedValue && selectedValue === s.value.toString();
    }) || []
  );
};

export const chooseSelectedFilterIMultiAutoComplete = (
  selectedValue?: string[],
  userStatusOption?: IMultiAutoComplete[]
): IMultiAutoComplete[] => {
  return (
    userStatusOption?.filter((s) => {
      if (Array.isArray(selectedValue)) {
        return selectedValue?.includes(s.id.toString());
      }
      return selectedValue && selectedValue === s.id.toString();
    }) || []
  );
};

// Create Context Object
export const UserOptionContext = React.createContext<UserOptionContextState>(defaultValue);

// Create a provider for components to consume and subscribe to changes
export const UserOptionContextProvider: React.FC = ({ children }) => {
  const [statusList, setStatusList] = useState<UserStatus[]>([]);
  const [countryList, setCountryList] = useState<Country[]>([]);
  const [churchList, setChurchList] = useState<Church[]>([]);
  const [jobNatureList, setJobNatureList] = useState<JobNature[]>([]);
  const [authorityList, setAuthorityList] = useState<Authority[]>([]);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const { setAlertInfo } = useContext(AlertContext);
  const { t } = useTranslation();

  const [{ data: apiStatusList, error: apisStatusListError }] = useAxios({
    url: "/user/status/list"
  });
  const [{ data: apiFilterSelection, error: apiFilterSelectionError }] = useAxios({
    url: "/user/option"
  });
  useEffect(() => {
    if (apiStatusList && apiStatusList.data) {
      setStatusList(apiStatusList.data);
    }
    if (apisStatusListError) {
      setAlertInfo &&
        setAlertInfo({
          open: true,
          message: `${apisStatusListError.message}, ${t("error.connection")}`
        });
    }
  }, [apiStatusList, apisStatusListError]);

  useEffect(() => {
    if (apiFilterSelection && apiFilterSelection.data) {
      setCountryList(apiFilterSelection.data.country);
      setJobNatureList(apiFilterSelection.data.job_nature);
      setChurchList(apiFilterSelection.data.church);
      setAuthorityList(apiFilterSelection.data.authority);
      setRoleList(apiFilterSelection.data.role);
    }
    if (apiFilterSelectionError) {
      setAlertInfo &&
        setAlertInfo({
          open: true,
          message: `${apiFilterSelectionError.message}, ${t("error.connection")}`
        });
    }
  }, [apiFilterSelection, apiFilterSelectionError, setAlertInfo, t]);

  return (
    <UserOptionContext.Provider
      value={{
        data: {
          statusList,
          countryList,
          churchList,
          jobNatureList,
          authorityList,
          roleList
        }
      }}
    >
      {children}
    </UserOptionContext.Provider>
  );
};
