import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { SAVED_LANG, LANG_EN, LANG_ZH_HK } from "util/const";

const defaultLang = localStorage.getItem(SAVED_LANG) ? localStorage.getItem(SAVED_LANG) : LANG_EN;
i18n
  .use(Backend)

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: `${defaultLang}`,
    fallbackLng: LANG_ZH_HK,
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    load: "currentOnly"
  });

export default i18n;
