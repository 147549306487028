import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { AlertContextProvider } from "component/alert/alertContext";
import AppBar from "./appbar/myAppBar";
import MyDrawer from "./myDrawer";
import { LayoutContextProvider } from "./layoutContext";
import Content from "./content";
// import { CategoryContextProvider } from "../admin/category/CategoryContext";

export default function AdminLayout() {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <CssBaseline />
      <LayoutContextProvider>
        <AlertContextProvider>
          <AppBar />
          <MyDrawer />
          <Content />
        </AlertContextProvider>
      </LayoutContextProvider>
    </div>
  );
}
