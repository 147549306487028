// import atob from 'atob';
import {
  REFRESH_TOKEN,
  ACCESS_TOKEN,
  AUTH_PAYLOAD,
  SSO_TOKEN,
  SAVED_EMAIL,
  REMEMBER_ME_SETTING
} from "./const";

export function saveAuth(token: string, refreshToken: string, payload: string) {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN, token);
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
  if (payload) {
    localStorage.setItem(AUTH_PAYLOAD, payload);
  }

  const rememberMe = localStorage.getItem(REMEMBER_ME_SETTING);
  const payloadJson = JSON.parse(payload);
  if (rememberMe === "1") {
    if (payloadJson.email) {
      localStorage.setItem(SAVED_EMAIL, payloadJson.email);
    }
  } else {
    localStorage.setItem(SAVED_EMAIL, "");
  }
}

export function removeToken() {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(AUTH_PAYLOAD);
  localStorage.removeItem(SSO_TOKEN);
}

export function getCurrentUser() {
  const payload = localStorage.getItem(AUTH_PAYLOAD) || "";
  const data = JSON.parse(payload);
  if (data.name_eng) {
    return data.name_eng;
  }
  return "";
}

export function getToken() {
  return localStorage.getItem(ACCESS_TOKEN) || "";
}

export function parseToken(token: string) {
  const arr = token !== "" ? token.split(".") : [];
  return arr.length === 3 ? JSON.parse(atob(arr[1])) : {};
}

export function getScopes() {
  const tokenObj = parseToken(getToken());
  const scopes = tokenObj && tokenObj.scp ? tokenObj.scp : [];
  return scopes.filter((s: string, i: number) => {
    return s !== "" && scopes.indexOf(s) === i;
  });
}

export function isNotGeneralUser() {
  const scopes = getScopes();
  return scopes && scopes.length > 0;
}

export function userHasPermissionSimple(requiredScope: string) {
  let hasPermission = false;
  if (requiredScope && requiredScope.length === 0) {
    hasPermission = true;
  } else {
    hasPermission = getScopes().find((element: string) => {
      return requiredScope.includes(element);
    });
  }
  return hasPermission;
}

// userHasPerission, it return true if one of the permissions in requiredScope matched
export function userHasPerission(userTokenScope: string[], requiredScope: string[]) {
  let hasPermission = false;
  if (requiredScope && requiredScope.length === 0) {
    hasPermission = true;
  } else {
    const gotIt = userTokenScope.find((element) => {
      return requiredScope.includes(element);
    });
    hasPermission = !!gotIt;
  }
  return hasPermission;
}

// export function checkToken(token: string): boolean {
//   try {
//     return !_.isEmpty(parseToken(token));
//   } catch (error) {
//     removeToken();
//     return false;
//   }
// }

interface TokenType {
  expire_at: Date;
}

export function checkTokenIsValid() {
  if (getToken()) {
    const payload = localStorage.getItem(AUTH_PAYLOAD);
    if (payload) {
      let data: TokenType;
      try {
        data = JSON.parse(payload);
      } catch (error) {
        return false;
      }
      if (data && data.expire_at && new Date(data.expire_at).getTime() > new Date().getTime()) {
        return true;
      }
    }
  }
  removeToken();
  return false;
}

// export function getRefreshToken(): string {
//   return localStorage.getItem(REFRESH_TOKEN_NAME) || '';
// }

export function buildAuthorization() {
  const token = getToken();
  return token !== "" ? `Bearer ${token}` : "";
}
