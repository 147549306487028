import React, { useState } from "react";
import { Menu, MenuItem, Typography, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useTranslation } from "react-i18next";
import UserProfile from "admin/user-profile/index";
import LanguageMenu from "../languageMenu";
import { AccountMenuProps } from "./accountMenu";

export const mobileMenuID = "account-menu-mobile";

export const AccountMenu: React.FC<AccountMenuProps> = (props) => {
  const { t } = useTranslation();
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const { anchorEl, open: showMenu } = props;
  const [openLangMenu, setOpenLangMenu] = useState({
    anchorEl: null,
    menuOpen: false
  });
  const handleClose = () => {
    props.handleClose();
  };

  const handleLogout = () => {
    props.signOut();
  };

  const handleLanguageMenuClose = () => {
    setOpenLangMenu({ anchorEl: null, menuOpen: false });
  };
  const handlePopupLanguageMenu = () => {
    // console.log("setOpenLangMenu");
    handleClose();
    setOpenLangMenu({ anchorEl: props.anchorEl, menuOpen: true });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        id={mobileMenuID}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={showMenu}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenUserProfile(true);
            handleClose();
          }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Typography>{t("button.profile")}</Typography>
        </MenuItem>
        <MenuItem style={{ paddingRight: "30px" }} onClick={handlePopupLanguageMenu}>
          <IconButton aria-label="Change language" color="inherit">
            <GTranslateIcon />
          </IconButton>
          <Typography>{t("button.change-language")}</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <ExitToAppIcon />
          </IconButton>
          <Typography>{t("button.logout")}</Typography>
        </MenuItem>
      </Menu>
      <UserProfile
        open={openUserProfile}
        onClose={() => {
          setOpenUserProfile(false);
        }}
      />
      <LanguageMenu
        anchorEl={openLangMenu.anchorEl}
        open={openLangMenu.menuOpen}
        handleClose={handleLanguageMenuClose}
      />
    </>
  );
};
