import { useTranslation } from "react-i18next";
import React, { useEffect, useContext, useRef } from "react";
import { useForm } from "react-hook-form";
import { axios } from "util/request";
import { AxiosResponse } from "axios";
import { C_SITE_API_URL } from "config/config";
import { AlertContext } from "component/alert/alertContext";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material";
import { IsValidEmail } from "util/const";
import { ErrorType, emailHelperText } from "util/helper";

interface ForgotPasswordProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { open } = props;
  const { t } = useTranslation(["translation", "signin"]);
  const { setAlertInfo, setDialogInfo, setLoadingBackdrop } = useContext(AlertContext);
  const formEl = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors },
    reset
  } = useForm();

  useEffect(() => {
    clearFormErrors();
  }, [clearFormErrors]);

  const handleClose = () => {
    props.onClose();
    if (clearFormErrors) {
      clearFormErrors();
    }
  };

  const fetchApproveUser = (resetEmail: string) => {
    const actualUrl = "/reset/password/request";
    return axios.post(actualUrl, { email: resetEmail }, { baseURL: C_SITE_API_URL });
  };

  const handleResetPassword = (data: { email: string }) => {
    // console.log(data.email);
    // setLoadingBackdrop(true);
    setLoadingBackdrop && setLoadingBackdrop(true);
    fetchApproveUser(data.email)
      .then((response: AxiosResponse) => {
        setLoadingBackdrop(false);
        if (response && response.status === 200 && response.data && response.data.data) {
          //   setLoadingBackdrop(false);
          // console.log(response);
          handleClose();
          //   formEl?.current?.reset();
          setDialogInfo &&
            setDialogInfo({
              open: true,
              title: t("dialog.title-result"),
              content: response.data.data.message,
              onOk: () => {
                reset();
              }
            });
        } else {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: t("signin:forgot-password.fail-to-request-password-reset")
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingBackdrop(false);
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: t("signin:forgot-password.fail-to-request-password-reset")
          });
        // handleClose();
      });
  };

  const combinedEmailHelperText = (emailError: ErrorType) => {
    const text = emailHelperText(emailError, t);
    if (text !== "") {
      return text;
    }
    return t("signin:forgot-password.email-helper-text");
  };

  const { ref: emailHookRef, ...emailHookRest } = register("email", {
    required: true,
    validate: IsValidEmail
  });
  return (
    <>
      {props && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form ref={formEl} autoComplete="off" onSubmit={handleSubmit(handleResetPassword)}>
            <DialogTitle id="alert-dialog-title">
              {t("signin:forgot-password.modal-title")}
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                {...emailHookRest}
                inputRef={emailHookRef}
                fullWidth
                variant="standard"
                autoComplete="off"
                type="email"
                label={`${t("label.email")} *`}
                error={!!formErrors.email}
                helperText={combinedEmailHelperText(formErrors.email)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                {t("button.cancel")}
              </Button>
              <Button
                // onClick={}
                type="submit"
                variant="contained"
              >
                {t("button.submit")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default ForgotPassword;
