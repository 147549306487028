import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  IconButton,
  Box,
  Button,
  Link
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { findCatNameWithParentById } from "admin/category/service";
import { CategoryContext } from "admin/category/categoryContext";
import styles from "style/common.module.css";
import { blackColor } from "style/theme";
import { Topic } from "./data.d";
import InfoResourceGroupList from "./infoResourceGroupList";

interface TopicInfoProps {
  topic: Topic;
  open: boolean;
  onClose: () => void;
  searchByCategory: (selectedID: string, removeOtherCriteria?: boolean) => void;
}

const Info: React.FC<TopicInfoProps> = (props) => {
  const { topic, onClose, searchByCategory, open: dialogOpen } = props;
  const { fullCatTreeData } = useContext(CategoryContext);
  const { t } = useTranslation(["translation", "topic"]);
  const handleClose = () => {
    onClose();
  };
  const displayCategory = (iTopic: Topic) => {
    return (
      <Typography variant="subtitle2">
        {iTopic.topic_category && (
          <>
            {iTopic.topic_category.map((category) => {
              const name = findCatNameWithParentById(fullCatTreeData.data, category.category_id);
              return (
                <span key={`category_id${category.category_id}`}>
                  <Button
                    component={Link}
                    onClick={() => {
                      handleClose();
                      searchByCategory(category.category_id.toString(), true);
                    }}
                  >
                    {name}
                  </Button>
                  <br />
                </span>
              );
            })}
          </>
        )}
      </Typography>
    );
  };

  return (
    <>
      {topic && (
        <Dialog
          key="topic-info-modal-dialog"
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby={topic.name}
          aria-describedby={topic.name}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="topic-info-dialog-title">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography color="primary" variant="h6">
                {topic.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row"
                  }}
                >
                  <Typography variant="subtitle2">
                    {formatDate(new Date(topic.republish_at), "yyyy-MM-dd")}
                  </Typography>
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Divider className={styles.modalDivider} />
          </DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingRight: "5px"
                  }}
                >
                  <Box sx={{ width: "100%", marginBottom: "10px" }}>
                    <Typography
                      variant="h6"
                      style={{ whiteSpace: "pre-wrap" }}
                      className={styles.grayStyleLevel2}
                    >
                      {topic.description}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <InfoResourceGroupList topic_id={topic.id} />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  borderLeft: 1,
                  borderColor: "general.border",
                  paddingLeft: "5px",
                  minWidth: "12em",
                  display: { xs: "none", md: "block" }
                }}
              >
                <Typography color={blackColor} variant="subtitle2">
                  {t("topic:label.category")}
                </Typography>
                {displayCategory(topic)}
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Info;
