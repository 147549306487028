import { Meta } from "component/table/data.d";
import { MyFileUpload } from "component/upload/file";
import type { TopicDto } from "res/topic/data.d";

export const CRITERIA_TYPE_RESOURCE = "resource";
export const CRITERIA_TYPE_RESOURCE_GROUP_TYPE = "resource_group_type";
export const CRITERIA_TYPE_STATUS = "status";

export interface ResourceDto {
  id: number;
  resource_group_id: number;
  resource_type_id: number;
  filepath: string;
  filename: string;
  display_file_size: string;
  link: string;
}

export interface ResourceGroupType {
  id: number;
  name_eng: string;
  name_chi: string;
  name: string;
}

export interface ResourceGroup {
  id: number;
  name: string;
  description: string;
  first_publish_at: string;
  republish_at: string;
  score: number;
  download_count: number;
  status: number;
  updated_at: string;
  number_of_files: number;
  resource: ResourceDto[];
  resource_group_type: ResourceGroupType[];
  topic: TopicDto[];
}

export interface ResourceGroupDto extends ResourceGroup {
  show_edit_button: boolean;
  not_ready_to_publish: boolean;
}

export interface ResourceGroupTableData {
  data: ResourceGroupDto[];
  meta: Meta;
}

export interface ResourceGroupFormData {
  id?: number;
  name: string;
  description: string;
  publish_at: string;
  status: number;
  files: MyFileUpload[];
  category?: number[];
  topic_id?: number[];
  file_id?: number[];
}

export interface ResourceGroupTypeDto {
  id: number;
  name: string;
}

export interface ResourceGroupListDto {
  id: number;
  name: string;
  description: string;
  publish_at: string;
  not_ready_to_publish: boolean;
  status: number;
  resource: ResourceDto[];
}

export interface ResourceSearchFormData {
  resource: string;
  status?: number;
  resource_group_type?: number[];
}
