import React, { useState, useEffect } from "react";
import { Paper, Typography, Box, LinearProgress } from "@mui/material";
// import { CategoryContext } from "admin/category/categoryContext";
import { SAVED_GALLERY_NODE_STATE } from "util/const";
import { useTranslation } from "react-i18next";
import { findCatById, renderAntWholeTree, AntTreeNode } from "admin/category/service";
import { Tree } from "antd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { GalleryCategory } from "../data.d";

interface GalleryExpandFilterProps {
  galleryTreeData: GalleryCategory[];
  searchBy: (selectedId: string) => void;
  initSelected?: string[];
}

function getGalleryNoteState(): string[] | undefined {
  let savedCategoryState = localStorage.getItem(SAVED_GALLERY_NODE_STATE);
  if (savedCategoryState === "" || savedCategoryState === null) {
    savedCategoryState = "[]";
  }
  if (savedCategoryState) {
    return JSON.parse(savedCategoryState);
  }
  return undefined;
}

const GalleryExpandFilter: React.FC<GalleryExpandFilterProps> = (props) => {
  const { galleryTreeData, searchBy, initSelected } = props;
  const [galleryAntCategoryTreeData, setGalleryAntCategoryTreeData] = useState<
    AntTreeNode[] | undefined
  >(undefined);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [nodeSelected, setNodeSelected] = useState<string[]>(initSelected || []);
  const { t } = useTranslation(["translation", "gallery"]);

  useEffect(() => {
    const savedCategoryState = getGalleryNoteState();
    if (savedCategoryState) {
      setExpanded(savedCategoryState);
    }
  }, []);

  useEffect(() => {
    setGalleryAntCategoryTreeData(renderAntWholeTree(galleryTreeData));
  }, [galleryTreeData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExpand = (expandedKeys: React.Key[], info: any) => {
    // console.log("handleExpand", info);
    setExpanded(expandedKeys as string[]);
    localStorage.setItem(SAVED_GALLERY_NODE_STATE, JSON.stringify(expandedKeys));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelect = (selectedKeys: React.Key[], info: any) => {
    // console.log("handleExpand", info);
    if (selectedKeys.length > 0) {
      const nodeId = selectedKeys[0] as string;
      const catNode = findCatById(galleryTreeData, Number(nodeId));
      if (!catNode?.children) {
        setNodeSelected(selectedKeys as string[]);
        searchBy(nodeId);
      } else {
        const savedCategoryState = getGalleryNoteState();
        if (savedCategoryState) {
          const gotIt = savedCategoryState.find((value: string) => {
            return nodeId === value;
          });
          if (!gotIt) {
            // expand node
            savedCategoryState.push(nodeId);
            handleExpand(savedCategoryState, undefined);
          } else {
            // collapse node
            savedCategoryState.splice(
              savedCategoryState.findIndex((value) => {
                return value === nodeId;
              }),
              1
            );
            handleExpand(savedCategoryState, undefined);
          }
        }
      }
    }
  };

  const showTreeList = () => {
    return (
      galleryTreeData &&
      galleryAntCategoryTreeData &&
      galleryTreeData.length > 0 &&
      galleryAntCategoryTreeData.length > 0
    );
  };
  return (
    <Paper style={{ padding: "10px", width: "320px", backgroundColor: "transparent" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box>
          <Typography variant="h6" style={{ marginBottom: "3px" }}>
            {t("gallery:label.gallery-classification")}
          </Typography>
        </Box>
        <Box>
          {showTreeList() ? (
            <Tree
              aria-label="Gallery List"
              expandedKeys={expanded}
              titleRender={(node) => {
                if (!node.children) {
                  return <Typography color="primary">{node.title}</Typography>;
                }
                return <Typography color="secondary">{node.title}</Typography>;
              }}
              selectedKeys={nodeSelected}
              onExpand={handleExpand}
              onSelect={handleSelect}
              switcherIcon={<ExpandMoreIcon />}
              treeData={galleryAntCategoryTreeData}
            />
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default GalleryExpandFilter;

GalleryExpandFilter.defaultProps = {
  initSelected: []
};
