import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Divider,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  SelectChangeEvent,
  Tooltip,
  Badge
} from "@mui/material";
import { DesktopDatePicker } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { AlertContext } from "component/alert/alertContext";
import styles from "style/common.module.css";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ResolveError } from "util/connUtil";
import { TreeSelect } from "antd";
import { CategoryContext } from "admin/category/categoryContext";
import { AxiosResponse } from "axios";
import { ResourceGroupListDto } from "res/resource/data.d";
import { getResourceGroupByTopicWithoutResource } from "res/resource/service";
import { nameHelperText, fetchUpdateTopic, fetchCreateTopic } from "./service";
import { Topic, TopicFormData } from "./data.d";
import SelectResourceGroupList from "./selectResourceGroupList";

export interface CreateEditTopicProps {
  open: boolean;
  onClose: (success: boolean) => void;
  submitUpdateCallback?: (topicId: number) => void;
  topic?: Topic;
}

const CreateEditTopic: React.FC<CreateEditTopicProps> = (props) => {
  const { topic, open: dialogOpen } = props;
  const [publishAt, setPublishAt] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<number>(1);
  const [resourceGroupList, setResourceGroupList] = useState<ResourceGroupListDto[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number[]>([]);
  const [openSelectResourceGroupDialog, setOpenSelectResourceGroupDialog] =
    useState<boolean>(false);
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { categoryTreeSelectItemUI } = useContext(CategoryContext);
  const { t } = useTranslation(["translation", "topic"]);
  const formEl = useRef<HTMLFormElement | null>(null);
  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  useEffect(() => {
    if (topic && topic.id > 0) {
      setLoadingBackdrop(true);
      getResourceGroupByTopicWithoutResource(topic.id)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setResourceGroupList(response.data.data as ResourceGroupListDto[]);
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    }
  }, [topic]);

  const resetPublishAtToNow = () => {
    setPublishAt(new Date());
    setValue("publish_at", new Date());
  };

  const resetForm = () => {
    reset();
    resetPublishAtToNow();
    setSelectedStatus(1);
  };

  const submitForm = (formData: TopicFormData) => {
    let submitFunc = fetchCreateTopic;
    const submitData = { ...formData };
    if (formData.publish_at.length === 10) {
      submitData.publish_at = `${formData.publish_at}T00:00:00Z`;
    }
    if (topic) {
      submitData.id = topic.id;
      submitData.resource_group_list = resourceGroupList;
      submitFunc = fetchUpdateTopic;
    }
    setLoadingBackdrop(true);

    submitFunc(submitData)
      .then((response: AxiosResponse) => {
        if (response.status === 200 || response.status === 201) {
          setSuccessInfo({
            open: true,
            message: response.data.message
          });
          props.onClose(true);
          resetForm();
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setLoadingBackdrop(false);
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const resetFormByDefaultTopic = (iTopic: Topic) => {
    setValue("name", iTopic.name);
    setValue("description", iTopic.description);
    setPublishAt(new Date(iTopic.republish_at));
    setValue("publish_at", new Date(iTopic.republish_at));
    setSelectedStatus(iTopic.status);
    setValue("status", iTopic.status);
    const categoryIDs = iTopic.topic_category.map((value) => {
      return value.category_id;
    });
    setSelectedCategory(categoryIDs);
    setValue("category", categoryIDs);
  };

  useEffect(() => {
    if (topic) {
      clearFormErrors();
      resetFormByDefaultTopic(topic);
    } else {
      resetPublishAtToNow();
    }
  }, [topic]);

  const handleClose = () => {
    topic && resetFormByDefaultTopic(topic);
    props.onClose(false);
  };

  const { ref: nameHookRef, ...nameHookRest } = register("name", {
    required: true,
    maxLength: 128
  });

  const { ref: descriptionHookRef, ...descriptionHookRest } = register("description", {
    required: true
  });
  const { ref: statusHookRef, ...statusHookRest } = register("status", {
    required: true
  });

  const tooltipAssignToResourceGroup: string = t("topic:button.assign-to-resource");
  return (
    <>
      <Dialog
        key="edit-topic-modal-dialog"
        open={dialogOpen}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby={t("topic:label.edit-topic")}
        aria-describedby={t("topic:label.edit-topic")}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="edit-topic-dialog-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                key="edit-topic-modal-title"
                id="edit-topic-modal-title"
                variant="h6"
                component="h2"
              >
                {topic ? t("topic:label.edit-topic") : t("topic:label.add-topic")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon color="action" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={styles.modalDivider} />
        </DialogTitle>
        <form ref={formEl} onSubmit={handleSubmit(submitForm)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label={`${t("topic:label.topic")} *`}
                  {...nameHookRest}
                  inputRef={nameHookRef}
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  type="text"
                  error={!!formErrors.name}
                  helperText={nameHelperText(formErrors.name, t)}
                  placeholder={t("topic:label.topic")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...descriptionHookRest}
                  inputRef={descriptionHookRef}
                  fullWidth
                  autoComplete="off"
                  multiline
                  aria-label="topic-description"
                  label={`${t("topic:label.description")} *`}
                  placeholder={t("topic:label.description")}
                  variant="standard"
                  type="text"
                  error={!!formErrors.description}
                  helperText={formErrors.description && t("topic:errors.description-is-required")}
                />
              </Grid>
              <Grid item xs={12}>
                {categoryTreeSelectItemUI && (
                  <>
                    <Typography
                      variant="body1"
                      color={formErrors.category ? "error" : "general.fieldDefault"}
                    >
                      {t("topic:label.category")} *
                    </Typography>
                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: true }}
                      defaultValue={selectedCategory}
                      render={(fProps) => {
                        // console.log("props", props);
                        return (
                          <TreeSelect
                            showSearch
                            allowClear
                            style={{ width: "100%" }}
                            value={fProps.field.value}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                              zIndex: 9999
                            }}
                            bordered={false}
                            placeholder={t("topic:place-holder.category")}
                            multiple
                            treeDefaultExpandAll
                            treeNodeFilterProp="displayName"
                            onChange={(value) => {
                              // console.log("value", value);
                              fProps.field.onChange(value);
                            }}
                          >
                            {categoryTreeSelectItemUI}
                          </TreeSelect>
                        );
                      }}
                    />
                    {formErrors.category && (
                      <Typography color="error">
                        {t("topic:errors.category-is-required")}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              {topic && resourceGroupList && (
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <Badge badgeContent={resourceGroupList.length} color="success">
                    <Typography>{t("topic:label.resource-assignment")}</Typography>
                  </Badge>
                  <IconButton
                    style={{ padding: "0 0 0 8px" }}
                    color="secondary"
                    onClick={() => {
                      setOpenSelectResourceGroupDialog(true);
                    }}
                  >
                    <Tooltip title={tooltipAssignToResourceGroup} placement="top">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <DesktopDatePicker
                  label={`${t("topic:label.publish-at")} *`}
                  openTo="year"
                  views={["day"]}
                  value={publishAt}
                  onChange={(newValue) => {
                    setPublishAt(newValue);
                    setValue("publish_at", newValue);
                  }}
                  mask="____-__-__"
                  inputFormat="yyyy-MM-dd"
                  renderInput={(params) => {
                    return (
                      <TextField
                        fullWidth
                        {...params}
                        {...register("publish_at", { required: true })}
                        variant="standard"
                        error={!!formErrors.publish_at}
                        helperText={
                          formErrors.publish_at && t("topic:errors.publish-at-is-required")
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="topic-status-label">{`${t("label.status")} *`}</InputLabel>
                  <Select
                    {...statusHookRest}
                    inputRef={statusHookRef}
                    labelId="topic-status-label"
                    id="topic-status"
                    value={selectedStatus}
                    onChange={(event: SelectChangeEvent<number>) => {
                      setSelectedStatus(Number(event.target.value));
                    }}
                    label={t("label.status")}
                  >
                    <MenuItem value={1}>{t("status.active")}</MenuItem>
                    <MenuItem value={0}>{t("status.inactive")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              margin: "15px",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              {t("button.cancel")}
            </Button>
            <Button type="submit" variant="contained" style={{ marginLeft: "auto", order: 2 }}>
              {t("button.save")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {topic && resourceGroupList && (
        <SelectResourceGroupList
          topic={topic}
          resourceGroupList={resourceGroupList}
          open={openSelectResourceGroupDialog}
          onClose={(sortedList: ResourceGroupListDto[]) => {
            setResourceGroupList(sortedList);
            setOpenSelectResourceGroupDialog(false);
          }}
        />
      )}
    </>
  );
};

export default CreateEditTopic;
