import React, { useState, useEffect, createContext, FC } from "react";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { isNotGeneralUser } from "util/token";

interface LayoutContextProps {
  open: boolean;
  setOpen: (i: boolean) => void;
}

// Create Context Object
export const LayoutContext = createContext<LayoutContextProps>({} as LayoutContextProps);

// Create a provider for components to consume and subscribe to changes
export const LayoutContextProvider: FC = ({ children }) => {
  const theme = useTheme();
  const smallDensity = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isNotGeneralUser()) {
      if (smallDensity) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [smallDensity]);

  return <LayoutContext.Provider value={{ open, setOpen }}>{children}</LayoutContext.Provider>;
};
