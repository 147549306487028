interface ErrorValue {
  message: string;
  field: string;
  result_code: string;
}

export interface ErrorJson {
  errors: ErrorValue[];
}

export const ResolveError = (errorsJson: ErrorJson) => {
  let message = "";
  console.log("errorsJson", errorsJson);
  if (errorsJson) {
    errorsJson?.errors?.forEach((error) => {
      if (error) {
        message += error.message;
      }
    });
  }
  // console.log("Y", message)
  return message;
};

export default ResolveError;
