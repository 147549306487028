import React from "react";
import { AccordionSummary, Accordion, Typography, AccordionDetails, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { TopicDto } from "res/topic/data.d";
import { addViewCount } from "res/topic/service";
import styles from "style/common.module.css";
import InfoResourceGroupList from "res/topic/infoResourceGroupList";

interface TopicAccordionProps {
  topic: TopicDto;
  resource_group_id: number;
  defaultOpen: boolean;
}

const TopicAccordion: React.FC<TopicAccordionProps> = (props) => {
  const { topic, defaultOpen, resource_group_id } = props;

  return (
    <Accordion
      onChange={(event: React.SyntheticEvent, expanded) => {
        if (expanded) {
          addViewCount(topic.id);
        }
      }}
      defaultExpanded={defaultOpen}
      className={topic.not_ready_to_publish ? styles.grayStyleBackground : ""}
      sx={topic.status === 0 ? { backgroundColor: "pink" } : { backgroundColor: "white" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            noWrap
            sx={{ width: { xs: "200px", sm: "200px", md: "700px" } }}
            variant="subtitle1"
            color="secondary"
          >
            {topic.name}
          </Typography>
          <Typography variant="subtitle2">{topic.description}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <InfoResourceGroupList topic_id={topic.id} highlight_res_group_id={resource_group_id} />
      </AccordionDetails>
    </Accordion>
  );
};

export default TopicAccordion;
