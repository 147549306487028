import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import { MonitorLocationUpdateAction } from "component/table/filterHelper";
import { SaveSetting, GetSavedSetting } from "component/table/paginatorSetting";
import { FEATURE_NAME_RESOURCE, RESOURCE_PATH, CREATE_EDIT_RESOURCE, twoDecimal } from "util/const";
import {
  loadNewPageData,
  getFilterValueStringifyed,
  getFilterValue
} from "component/table/paginatorHelper";
import { AlertContext } from "component/alert/alertContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { axios } from "util/request";
import { AxiosResponse } from "axios";
import { V_SITE_API_URL } from "config/config";
import Qs from "qs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { LocalizationProvider } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { ResolveError } from "util/connUtil";
import EditIcon from "@mui/icons-material/Edit";
import { getRecordID } from "util/helper";
import { format as formatDate } from "date-fns";
import { getScopes, userHasPerission } from "util/token";
import styles from "style/common.module.css";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddBoxIcon from "@mui/icons-material/AddBox";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TablePaginator from "component/table/paginator";
import useResourceGroupType from "hook/resourceGroupTypeList";
import OwnHighlighter from "component/text/highlighter";
import CreateEditResourceGroup from "./createEdit";
import SearchCriteriaList from "./filter/searchCriteriaList";
import {
  CRITERIA_TYPE_RESOURCE,
  CRITERIA_TYPE_STATUS,
  ResourceSearchFormData,
  ResourceGroupTableData,
  ResourceGroup,
  ResourceGroupDto
} from "./data.d";

import Info from "./info";
import ResourceFilter from "./filter/filter";

const ResResourceGroup = () => {
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { t } = useTranslation(["translation", "resource"]);
  const scopes = getScopes();
  const hasResourcesPermission = userHasPerission(scopes, [CREATE_EDIT_RESOURCE]);

  const [openAddResourceGroup, setOpenAddResourceGroup] = useState<boolean>(false);
  const [openEditResourceGroup, setOpenEditResourceGroup] = useState<boolean>(false);
  const [resourceGroupTableData, setResourceGroupTableData] = useState<ResourceGroupTableData>();
  const [resourceGroupList, setResourceGroupList] = useState<any>();
  const [openResourceGroupInfo, setOpenResourceGroupInfo] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [dataToCriteria, setDataToCriteria] = useState<any>(undefined);
  const resourceGroupTypeList = useResourceGroupType();

  // console.log("loadingData", loadingData);
  const [currentEditResourceGroup, setCurrentEditResourceGroup] = useState<
    ResourceGroup | undefined
  >();
  const [currentInfoResourceGroup, setCurrentInfoResourceGroup] = useState<
    ResourceGroup | undefined
  >();

  const handleEditResource = (res: ResourceGroup) => {
    setCurrentEditResourceGroup(res);
    setOpenEditResourceGroup(true);
  };
  const actionButton = (res: ResourceGroupDto): React.ReactNode => {
    const tooltipsEditString: string = t("button.edit");

    const editButton = (
      <IconButton
        aria-label="edit"
        key={`edit_button${res.id}`}
        onClick={() => {
          handleEditResource(res);
        }}
      >
        <Tooltip title={tooltipsEditString} placement="right">
          <EditIcon color="secondary" />
        </Tooltip>
      </IconButton>
    );
    return editButton;
  };

  const executeFetchResourceGroup = (iParams: any) => {
    setLoadingBackdrop(true);
    const actualUrl = "/search/resource/group";
    setLoadingData(true);
    axios
      .get(actualUrl, {
        params: iParams,
        baseURL: V_SITE_API_URL,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        }
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const newData: ResourceGroupTableData = response.data;
          if (isMobile && resourceGroupList?.data && response.data.meta.current_page > 1) {
            newData.data = [...resourceGroupList.data, ...response.data.data];
          }
          setResourceGroupList(newData as ResourceGroupTableData);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error.message}, ${t("error.connection")}`
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
        setLoadingData(false);
      });
  };

  const runExecuteFetchResourceGroup = () => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_RESOURCE);
    setDataToCriteria(pageSetting.filter_value);

    executeFetchResourceGroup &&
      executeFetchResourceGroup({
        ...pageSetting.filter_value,
        page_size: pageSetting.record_per_page,
        current_page: pageSetting.current_page
      });
  };

  useEffect(() => {
    if (resourceGroupList && resourceGroupList.data) {
      if (resourceGroupList.meta.current_page < resourceGroupList.meta.total_page) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
      setResourceGroupTableData({
        data: resourceGroupList.data.map((row: ResourceGroupDto, index: number) => {
          return (
            <Box key={`whole${row.id}`} sx={{ display: "flex", flexDirection: "row" }}>
              {row.show_edit_button && (
                <div
                  key={`edit_button${row.id}`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column"
                  }}
                >
                  {actionButton(row)}
                </div>
              )}
              <Card key={row.id} className={styles.cardRoot}>
                <CardActionArea
                  className={row.not_ready_to_publish ? styles.grayStyleBackground : ""}
                  sx={
                    row.status === 0
                      ? { backgroundColor: "pink" }
                      : { backgroundColor: "white", opacity: "0.7" }
                  }
                  onClick={() => {
                    setCurrentInfoResourceGroup(row);
                    setOpenResourceGroupInfo(true);
                  }}
                >
                  <CardContent style={{ paddingBottom: "10px" }} key={`content${row.id}`}>
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              flexGrow: 1
                            }}
                          >
                            <Typography
                              style={{
                                minWidth: "2em",
                                paddingRight: "0.5em"
                              }}
                            >
                              {getRecordID(
                                index,
                                isMobile ? 1 : resourceGroupList.meta.current_page,
                                resourceGroupList.meta.record_per_page
                              )}
                              .
                            </Typography>
                            <div style={{ width: "100%", flexGrow: 1 }}>
                              <Typography
                                key={`name${row.id}`}
                                className={styles.cardTitle}
                                color="primary"
                                gutterBottom
                              >
                                <OwnHighlighter
                                  searchContent={row.name}
                                  searchTerm={dataToCriteria.resource}
                                />
                              </Typography>
                              <Typography
                                style={{ whiteSpace: "pre-wrap" }}
                                key={`description${row.id}`}
                              >
                                <OwnHighlighter
                                  searchContent={row.description}
                                  searchTerm={dataToCriteria.resource}
                                />
                              </Typography>
                            </div>
                          </Box>
                          <Box sx={{ display: { sm: "none", md: "block" } }}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row-reverse"
                              }}
                            >
                              <Typography variant="subtitle2">
                                {row.score > 0 && (
                                  <>
                                    <span className={styles.grayStyleLevel1}>
                                      {t("resource:label.score")}:{" "}
                                    </span>
                                    <span className={styles.grayStyleLevel2}>
                                      {twoDecimal(row.score)}
                                      {", "}
                                    </span>
                                  </>
                                )}
                                <span className={styles.grayStyleLevel1}>
                                  {t("resource:label.last-updated-at")}:{" "}
                                </span>
                                <span className={styles.grayStyleLevel2}>
                                  {formatDate(new Date(row.republish_at), "yyyy-MM-dd")}
                                </span>
                                {hasResourcesPermission && (
                                  <>
                                    <span className={styles.grayStyleLevel1}>
                                      {", "} {t("resource:label.download-count")}:{" "}
                                    </span>
                                    <span className={styles.grayStyleLevel2}>
                                      {row.download_count}
                                    </span>
                                  </>
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </CardContent>
                </CardActionArea>
                {/* <CardActions></CardActions> */}
              </Card>
            </Box>
          );
        }),
        meta: resourceGroupList.meta
      });
    }
  }, [resourceGroupList]);

  useEffect(() => {
    MonitorLocationUpdateAction({
      location,
      featureName: FEATURE_NAME_RESOURCE,
      featurePath: RESOURCE_PATH,
      fnrExecuteFetchList: runExecuteFetchResourceGroup
    });
  }, [location]);

  // rely on useEffect [location]
  // useEffect(() => {
  //   LoadDataByCheckingUrl({
  //     iLocation: location,
  //     featureName: FEATURE_NAME_RESOURCE,
  //     fnrExecuteFetchList: runExecuteFetchResourceGroup
  //   });
  // }, []);

  const localLoadNewPageData = (optionIsNextPage?: boolean) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_RESOURCE);
    let pageNo = pageSetting.current_page!;
    if (optionIsNextPage) {
      pageNo += 1;
    }
    loadNewPageData(
      pageNo,
      pageSetting.record_per_page!,
      FEATURE_NAME_RESOURCE,
      pageSetting.filter_value,
      runExecuteFetchResourceGroup
    );
  };

  const loadMore = () => {
    if (isMobile) {
      localLoadNewPageData(true);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loadingData,
    hasNextPage,
    onLoadMore: loadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !isMobile,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 50px 0px"
  });

  const addResourceButton = (): React.ReactNode => {
    const tooltipStr: string = t("resource:label.add-resource-group");
    if (hasResourcesPermission) {
      if (!isMobile) {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setOpenAddResourceGroup(true);
            }}
            startIcon={<FontAwesomeIcon icon={faPlus} />}
          >
            {t("resource:label.add-resource-group")}
          </Button>
        );
      }
      return (
        <IconButton
          color="primary"
          onClick={() => {
            setOpenAddResourceGroup(true);
          }}
        >
          <Tooltip title={tooltipStr} placement="top">
            <AddBoxIcon />
          </Tooltip>
        </IconButton>
      );
    }

    return <></>;
  };

  const resetAndSearchCallback = (data: ResourceSearchFormData) => {
    SaveSetting({
      current_page: 1,
      feature_name: FEATURE_NAME_RESOURCE,
      filter_value: data
    });
    const urlPara = Qs.stringify(data, { arrayFormat: "repeat" });
    let historyPath = RESOURCE_PATH;
    if (urlPara !== "") {
      historyPath += `?${urlPara}`;
    }
    navigate(historyPath);
  };

  const removeCriteriaByType = (type: string) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_RESOURCE);
    switch (type) {
      case CRITERIA_TYPE_RESOURCE:
        pageSetting.filter_value.resource = undefined;
        break;
      case CRITERIA_TYPE_STATUS:
        delete pageSetting.filter_value.status;
        break;
      default:
        pageSetting.filter_value = undefined;
    }
    resetAndSearchCallback(pageSetting.filter_value);
  };

  const removeCriteriaByResourceGroupType = (idNeedToRemove: number) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_RESOURCE);
    const resGroupTypeList = pageSetting.filter_value.resource_group_type;
    for (let i = 0; i < resGroupTypeList.length; i += 1) {
      if (Number(resGroupTypeList[i]) === Number(idNeedToRemove)) {
        const newRGTL = [...resGroupTypeList];
        newRGTL.splice(i, 1);
        pageSetting.filter_value.resource_group_type = newRGTL;
        break;
      }
    }

    resetAndSearchCallback(pageSetting.filter_value);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Card sx={{ marginRight: "10px", backgroundColor: "transparent" }}>
          <CardHeader title={t("nav.resource")} action={addResourceButton()} />
          <CardContent>
            <Grid container>
              <Grid item xs={12} className={styles.filterAreaSpacing}>
                {resourceGroupTypeList && (
                  <ResourceFilter
                    initValue={getFilterValue(FEATURE_NAME_RESOURCE)}
                    resourceGroupTypeList={resourceGroupTypeList}
                    resetAndSearchCallback={resetAndSearchCallback}
                  />
                )}
                {resourceGroupTypeList && (
                  <SearchCriteriaList
                    removeCriteriaByType={removeCriteriaByType}
                    removeCriteriaByResourceGroupType={removeCriteriaByResourceGroupType}
                    resourceGroupTypeList={resourceGroupTypeList}
                    filterData={dataToCriteria}
                  />
                )}
              </Grid>
              {isMobile && (
                <Grid item xs={12} style={{ paddingBottom: "5px" }}>
                  {resourceGroupTableData &&
                    resourceGroupTableData.meta &&
                    t("table.total-no-of-record", {
                      number: resourceGroupTableData!.meta!.total_record.toString()
                    })}
                </Grid>
              )}
              <Grid item xs={12}>
                {resourceGroupTableData && resourceGroupTableData.data ? (
                  resourceGroupTableData.data
                ) : (
                  <LinearProgress />
                )}
                {isMobile && (loadingData || hasNextPage) && (
                  <div key="x" ref={sentryRef}>
                    <LinearProgress />
                  </div>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            {!isMobile && resourceGroupTableData && resourceGroupTableData.meta && (
              <TablePaginator
                key="table-paginator"
                recordPerPageOptions={{
                  defaultOption: resourceGroupTableData.meta.record_per_page
                }}
                recordFrom={resourceGroupTableData.meta.from}
                recordTo={resourceGroupTableData.meta.to}
                currentPage={resourceGroupTableData.meta.current_page}
                recordTotal={resourceGroupTableData.meta.total_record}
                noOfPages={resourceGroupTableData.meta.total_page}
                featureName={FEATURE_NAME_RESOURCE}
                urlPath={RESOURCE_PATH}
                urlQuery={getFilterValueStringifyed(FEATURE_NAME_RESOURCE)}
              />
            )}
          </CardActions>
        </Card>
      </Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <>
          <CreateEditResourceGroup
            open={openAddResourceGroup}
            onClose={(success: boolean) => {
              if (success) {
                localLoadNewPageData();
              }
              setOpenAddResourceGroup(false);
            }}
          />
          {currentEditResourceGroup && (
            <CreateEditResourceGroup
              open={openEditResourceGroup}
              onClose={(success: boolean) => {
                if (success) {
                  localLoadNewPageData();
                }
                setCurrentEditResourceGroup(undefined);
                setOpenEditResourceGroup(false);
              }}
              resourceGroup={currentEditResourceGroup}
            />
          )}
          {currentInfoResourceGroup && (
            <Info
              resourceGroup={currentInfoResourceGroup as ResourceGroup}
              open={openResourceGroupInfo}
              onClose={() => {
                setCurrentInfoResourceGroup(undefined);
                setOpenResourceGroupInfo(false);
              }}
            />
          )}
        </>
      </LocalizationProvider>
    </>
  );
};

export default ResResourceGroup;
