import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useTranslation } from "react-i18next";
import { LANG_ZH_HK, LANG_EN, SAVED_LANG } from "util/const";
import { Typography } from "@mui/material";

interface LanguageMenuProps {
  handleClose: () => void;
  open: boolean;
  anchorEl: Element | null;
}

const LanguageMenu = (props: LanguageMenuProps) => {
  const { i18n } = useTranslation();

  const { anchorEl, open: menuOpen, handleClose } = props;
  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem(SAVED_LANG, lang);
    handleClose();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      id="language-menu"
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={menuOpen}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          handleChangeLanguage(LANG_ZH_HK);
        }}
      >
        <Typography>繁體中文</Typography>
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleChangeLanguage(LANG_EN);
        }}
      >
        <Typography>English</Typography>
      </MenuItem>
    </Menu>
  );
};

export default LanguageMenu;
