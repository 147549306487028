export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 0;
export const STATUS_ALL = 10;
export interface ErrorType {
  type: string;
}

export function hexToRgb(inputColor: string) {
  let input = `${inputColor}`;
  input = inputColor.replace("#", "");
  const hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    const first = input[0];
    const second = input[1];
    const last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  const first = input[0] + input[1];
  const second = input[2] + input[3];
  const last = input[4] + input[5];
  return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(last, 16)}`;
}

export const emailHelperText = (
  emailError: ErrorType,
  t: (params: string | string[]) => string
) => {
  if (emailError?.type === "required") {
    return t("registration:errors.email-is-required");
  }
  if (emailError?.type === "validate") {
    return t("registration:errors.email-invalid");
  }
  return "";
};

export const passwordHelperText = (pass: ErrorType, t: (params: string | string[]) => string) => {
  if (pass?.type === "required") {
    return t("registration:errors.password-is-required");
  }
  if (pass?.type === "validate") {
    return t("registration:errors.password-format");
  }
  if (pass?.type === "minLength") {
    return t("registration:errors.password-length");
  }
  return "";
};

export const confirmPasswordHelperText = (
  pass: ErrorType,
  t: (params: string | string[]) => string
) => {
  if (pass?.type === "required") {
    return t("registration:errors.confirm-password-is-required");
  }
  if (pass?.type === "validate") {
    return t("registration:errors.confirm-password-not-match");
  }
  return "";
};

export const getRecordID = (index: number, currentPage: number, recordPerPage: number) => {
  return (currentPage - 1) * recordPerPage + (index + 1);
};

export const getStatusLabel = (status: number, t: (params: string | string[]) => string) => {
  if (status === STATUS_INACTIVE) {
    return t("status.inactive");
  }
  if (status === STATUS_ACTIVE) {
    return t("status.active");
  }
  return t("status.all");
};
