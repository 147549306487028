import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedUserPaper = styled(Paper)(() => {
  return `
        margin-top: 15px;
        padding: 20px;
      `;
});

export default CustomizedUserPaper;
