import React, { useState, useContext, useRef, useEffect } from "react";
import CustomizedFilterPaper from "component/customizedFilterPaper";
import { Box, TextField, Grid, LinearProgress, Autocomplete, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { styled } from "@mui/material/styles";
import { DateRange, MobileDateRangePicker } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useForm } from "react-hook-form";
import { GetStandardOptionLabel, GetCountryGroupOptionLabel } from "util/display";
import styles from "style/common.module.css";
import {
  UserOptionContext,
  chooseSelectedFilterUser,
  chooseSelectedFilterIMultiAutoComplete
} from "./userOptionContext";
import { Authority } from "../authority/data.d";
import { UserStatus, UserFormFilterInitProps, UserFormFilterProps } from "./data.d";
import { Church } from "../church/data.d";
import { JobNature } from "../job-nature/data.d";
import { Country } from "../country/data.d";
import { Role } from "../role/data.d";

export interface UserTableFilterProps {
  resetAndSearchCallback: (data: UserFormFilterProps) => void;
  initValue?: UserFormFilterInitProps;
}

const PREFIX = "TableFilter";

const classes = {
  root: `${PREFIX}-root`
};

export const CustomizedForm = styled("form")(({ theme }) => {
  return {
    [`&.${classes.root}`]: {},
    [`& .MuiTextField-root`]: {
      margin: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  };
});

const TableFilter: React.FC<UserTableFilterProps> = (props) => {
  const { initValue } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation(["translation", "registration"]);
  const [dateRangeValue, setDateRangeValue] = useState<DateRange<Date>>([null, null]);
  // const [statusList, setStatusList] = useState<UserStatus[]>([]);
  // const [countries, setCounties] = useState<Country[]>([]);
  // const [jobNatures, setJobNatures] = useState<JobNature[]>([]);
  // const [churches, setChurches] = useState<Church[]>([]);
  // const [authorities, setAuthorities] = useState<Authority[]>([]);
  const [selectedStatusID, setSelectedStatusID] = useState<UserStatus[]>([]);
  const [selectedCountryID, setSelectedCountryID] = useState<Country[]>([]);
  const [selectedAuthorityID, setSelectedAuthorityID] = useState<Authority[]>([]);
  const [selectedRoleID, setSelectedRoleID] = useState<Role[]>([]);
  const [selectedChurchID, setSelectedChurchID] = useState<Church[]>([]);
  const [selectedJobNatureID, setSelectedJobNatureID] = useState<JobNature[]>([]);

  // const { setAlertInfo } = useContext(AlertContext);
  const { data: optionData } = useContext(UserOptionContext);
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (initValue) {
      // console.log("set init", initValue, initValue?.status);
      setValue("email", initValue?.email);
      setValue("name", initValue?.name);
      setValue("referee", initValue?.referee);
      if (optionData) {
        const statusSelected = chooseSelectedFilterUser(initValue?.status, optionData.statusList);
        setSelectedStatusID(statusSelected);
        setValue("status", statusSelected);

        const countrySelected = chooseSelectedFilterIMultiAutoComplete(
          initValue?.country_id,
          optionData.countryList
        );
        setSelectedCountryID(countrySelected as Country[]);
        setValue("country", countrySelected);

        const churchSelected = chooseSelectedFilterIMultiAutoComplete(
          initValue?.church_id,
          optionData.churchList
        );
        setSelectedChurchID(churchSelected as Church[]);
        setValue("church", churchSelected);

        const jobNatureSelected = chooseSelectedFilterIMultiAutoComplete(
          initValue?.job_nature_id,
          optionData.jobNatureList
        );
        setSelectedJobNatureID(jobNatureSelected as JobNature[]);
        setValue("job_nature", jobNatureSelected);

        const authoritySelected = chooseSelectedFilterIMultiAutoComplete(
          initValue?.authority_id,
          optionData.authorityList
        );
        setSelectedAuthorityID(authoritySelected as Authority[]);
        setValue("authority", authoritySelected);

        const roleSelected = chooseSelectedFilterIMultiAutoComplete(
          initValue?.role_id,
          optionData.roleList
        );
        setSelectedRoleID(roleSelected as Role[]);
        setValue("role", roleSelected);

        initValue?.registration_from && setValue("registration_from", initValue?.registration_from);
        initValue?.registration_to && setValue("registration_to", initValue?.registration_to);
        setDateRangeValue([
          initValue?.registration_from || null,
          initValue?.registration_to || null
        ]);
      }
    }
  }, [initValue, optionData]);

  const handleSearch = (data: UserFormFilterProps) => {
    // console.log("status_values", data);
    // if (data && data.status) {
    //   data.status_ids = data.status?.map((status) => status.value);
    // }
    // if (data && data.country) {
    //   data.country_ids = data.country?.map((country) => country.id);
    // }
    // if (data && data.authority) {
    //   data.authority_ids = data.authority?.map((authority) => authority.id);
    // }
    // if (data && data.job_nature) {
    //   data.job_nature_ids = data.job_nature?.map((job_nature) => job_nature.id);
    // }
    // if (data && data.church) {
    //   data.church_ids = data.church?.map((church) => church.id);
    // }
    // if (data && data.role) {
    //   data.role_ids = data.role?.map((role) => role.id);
    // }
    props.resetAndSearchCallback(data);
  };

  const resetFilter = () => {
    setValue("church", []);
    setValue("status", []);
    setValue("job_nature", []);
    setValue("authority", []);
    setValue("country", []);
    setValue("role", []);
    setSelectedAuthorityID([]);
    setSelectedRoleID([]);
    setSelectedChurchID([]);
    setSelectedJobNatureID([]);
    setSelectedCountryID([]);
    setSelectedStatusID([]);
    formEl?.current?.reset();
    setDateRangeValue([null, null]);
    const empty: UserFormFilterProps = {};
    props.resetAndSearchCallback(empty);
  };

  // we set a random value here is just
  // beause if we don't do this, the reset button to the autocomplete value won't work
  // const autoCompleteKey = (): React.Key => {
  //   return Math.random();
  // };
  const { ref: emailFormHookRef, ...emailFormHookRest } = register("email");
  const { ref: nameFormHookRef, ...nameFormHookRest } = register("name");
  const { ref: refereeFromHookRef, ...refereeFromHookRest } = register("referee");
  const { ref: statusRef } = register("status");
  const { ref: churchRef } = register("church");
  const { ref: jobNatureRef } = register("job_nature");
  const { ref: countryRef } = register("country");
  const { ref: authorityRef } = register("authority");
  const { ref: roleRef } = register("role");

  return (
    t && (
      <CustomizedFilterPaper>
        <CustomizedForm
          ref={formEl}
          className={classes.root}
          autoComplete="off"
          onSubmit={handleSubmit(handleSearch)}
        >
          <Grid container component="main" spacing={4}>
            <Grid item xs={12} md={9}>
              <Grid container>
                <Grid item xs={10} md={4} lg={3}>
                  <TextField
                    label={t("registration:label.email")}
                    {...emailFormHookRest}
                    type="text"
                    inputProps={{ inputMode: "email" }}
                    fullWidth
                    autoComplete="off"
                    variant="standard"
                    inputRef={emailFormHookRef}
                  />
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  <TextField
                    {...nameFormHookRest}
                    label={t("registration:label.name")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputRef={nameFormHookRef}
                  />
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  <TextField
                    {...refereeFromHookRest}
                    label={t("registration:pi.referee")}
                    type="text"
                    fullWidth
                    variant="standard"
                    inputRef={refereeFromHookRef}
                  />
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.statusList ? (
                    <Autocomplete
                      multiple
                      // getOptionSelected={(option, value) => option.value === value.value}
                      options={optionData.statusList}
                      value={selectedStatusID}
                      getOptionLabel={(option) => {
                        return option.name;
                      }}
                      onChange={(event, newValue) => {
                        setValue("status", newValue);
                        setSelectedStatusID(newValue);
                      }}
                      limitTags={2}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={statusRef}
                            label={t("label.status")}
                            fullWidth
                            // style={{ top: "-2px" }}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.countryList ? (
                    <Autocomplete
                      multiple
                      options={optionData.countryList}
                      value={selectedCountryID}
                      onChange={(event, newValue) => {
                        setValue("country", newValue);
                        setSelectedCountryID(newValue);
                      }}
                      limitTags={2}
                      getOptionLabel={GetStandardOptionLabel}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={countryRef}
                            label={t("registration:pi.district")}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.jobNatureList ? (
                    <Autocomplete
                      multiple
                      options={optionData.jobNatureList}
                      value={selectedJobNatureID}
                      getOptionLabel={GetStandardOptionLabel}
                      onChange={(event, newValue) => {
                        // console.log("selected", newValue);
                        setValue("job_nature", newValue);
                        setSelectedJobNatureID(newValue);
                      }}
                      limitTags={2}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={jobNatureRef}
                            label={t("registration:pi.job-nature")}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.churchList ? (
                    <Autocomplete
                      multiple
                      options={optionData.churchList}
                      value={selectedChurchID}
                      groupBy={GetCountryGroupOptionLabel}
                      getOptionLabel={GetStandardOptionLabel}
                      onChange={(event, newValue) => {
                        setValue("church", newValue);
                        setSelectedChurchID(newValue);
                      }}
                      limitTags={2}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={churchRef}
                            label={t("registration:pi.parish")}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.authorityList ? (
                    <Autocomplete
                      multiple
                      options={optionData.authorityList}
                      value={selectedAuthorityID}
                      groupBy={GetCountryGroupOptionLabel}
                      getOptionLabel={GetStandardOptionLabel}
                      onChange={(event, newValue) => {
                        setValue("authority", newValue);
                        setSelectedAuthorityID(newValue);
                      }}
                      limitTags={2}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={authorityRef}
                            label={t("registration:pi.authority")}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={4} lg={3}>
                  {optionData && optionData.roleList ? (
                    <Autocomplete
                      multiple
                      options={optionData.roleList}
                      value={selectedRoleID}
                      getOptionLabel={GetStandardOptionLabel}
                      onChange={(event, newValue) => {
                        setValue("role", newValue);
                        setSelectedRoleID(newValue);
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            inputRef={roleRef}
                            label={t("user-management:label.role")}
                            variant="standard"
                          />
                        );
                      }}
                    />
                  ) : (
                    <LinearProgress />
                  )}
                </Grid>
                <Grid item xs={10} md={8} lg={8}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateRangePicker
                      startText={t("user-management:label.registration-from")}
                      endText={t("user-management:label.registration-to")}
                      value={dateRangeValue}
                      allowSameDateSelection
                      onChange={(newValue) => {
                        // console.log("date newValue", newValue);
                        setValue("registration_from", newValue[0]);
                        setValue("registration_to", newValue[1]);
                        setDateRangeValue(newValue);
                      }}
                      mask="____-__-__"
                      inputFormat="yyyy-MM-dd"
                      renderInput={(startProps, endProps) => {
                        return (
                          <>
                            <TextField
                              {...startProps}
                              fullWidth
                              variant="standard"
                              {...register("registration_from")}
                            />
                            <Box sx={{ mx: 2 }}> {t("label.to")} </Box>
                            <TextField
                              {...endProps}
                              fullWidth
                              variant="standard"
                              {...register("registration_to")}
                            />
                          </>
                        );
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <IconButton
                className={styles.searchButton}
                aria-label="search"
                color="primary"
                type="submit"
              >
                <SearchIcon />
              </IconButton>
              <IconButton
                className={styles.searchButton}
                aria-label="reset"
                color="primary"
                type="reset"
                onClick={resetFilter}
              >
                <CancelOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </CustomizedForm>
      </CustomizedFilterPaper>
    )
  );
};

export default TableFilter;
