import React, { useState, useContext } from "react";
import { Box, Button, Divider, Typography, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { AlertContext } from "component/alert/alertContext";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { AxiosResponse } from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import { ResolveError } from "util/connUtil";
import { getEmailList } from "../service";

const EmailListTypeAll = "all";
const EmailListTypeGeneralMember = "general-member";

interface EmailListDialogProps {
  open: boolean;
  setOpen: (input: boolean) => void;
}

const EmailListDialog: React.FC<EmailListDialogProps> = (props) => {
  const { open, setOpen } = props;
  const { t } = useTranslation(["translation", "user-management"]);
  const [emailList, setEmailList] = useState<string>("");
  const [emailResultTitle, setEmailResultTitle] = useState<string>("");
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);

  const handleClose = () => {
    setOpen(false);
  };

  const loadEmailList = (memberType: string) => {
    setLoadingBackdrop(true);
    getEmailList(memberType)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data) {
          setEmailList(response.data.data);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={t("user-management:dialog.email-list-title")}
      aria-describedby={t("user-management:dialog.email-list-title")}
      scroll="paper"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("user-management:dialog.email-list-title")}
      </DialogTitle>
      <DialogContent>
        <Divider sx={{ marginBottom: "10px" }} />
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => {
              loadEmailList(EmailListTypeGeneralMember);
              setEmailResultTitle(t("user-management:label.result-general-member"));
            }}
            variant="contained"
            sx={{ flexGrow: 0.5 }}
            startIcon={<FontAwesomeIcon icon={faEnvelope} />}
          >
            {t("user-management:label.general-member-only")}
          </Button>
          <Button
            onClick={() => {
              loadEmailList(EmailListTypeAll);
              setEmailResultTitle(t("user-management:label.result-all-member"));
            }}
            variant="contained"
            sx={{ marginLeft: "40px", flexGrow: 0.5 }}
            startIcon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
          >
            {t("user-management:label.all-member")}
          </Button>
        </Box>
        {emailList !== "" && (
          <>
            <Typography variant="h6" sx={{ paddingTop: "40px" }}>
              {emailResultTitle}
              <CopyToClipboard
                onCopy={() => {
                  setSuccessInfo({
                    open: true,
                    message: t("user-management:message.email-copied")
                  });
                }}
                text={emailList}
              >
                <IconButton color="primary">
                  <ContentCopyIcon />
                </IconButton>
              </CopyToClipboard>
            </Typography>
            <Divider />
          </>
        )}
        <Typography variant="body1" style={{ wordWrap: "break-word" }} sx={{ paddingTop: "5px" }}>
          {emailList}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} type="submit" variant="contained">
          {t("button.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmailListDialog;
