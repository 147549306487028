import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useTranslation } from "react-i18next";
import LanguageMenu from "./languageMenu";

interface LanguageStateProps {
  anchorEl: (EventTarget & HTMLElement) | null;
  menuOpen: boolean;
}

interface LanguageButtonProps {
  primaryColor?: boolean;
}

const LanguageButton: React.FC<LanguageButtonProps> = (props) => {
  const { primaryColor } = props;
  const [languageMenuState, setLanguageMenuState] = useState<LanguageStateProps>({
    anchorEl: null,
    menuOpen: false
  });
  const { t } = useTranslation(["translation", "signin"]);

  const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageMenuState({ anchorEl: event.currentTarget, menuOpen: true });
  };
  const handleLanguageMenuClose = () => {
    setLanguageMenuState({ anchorEl: null, menuOpen: false });
  };

  return (
    <>
      <IconButton
        aria-label={t("button.change-language")}
        color={primaryColor ? "primary" : "inherit"}
        onClick={handleOpenLanguageMenu}
      >
        <GTranslateIcon />
      </IconButton>
      <LanguageMenu
        anchorEl={languageMenuState.anchorEl}
        open={languageMenuState.menuOpen}
        handleClose={handleLanguageMenuClose}
      />
    </>
  );
};

const defaultProps: LanguageButtonProps = {
  primaryColor: true
};

LanguageButton.defaultProps = defaultProps;

export default LanguageButton;
