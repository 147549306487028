import React, { useEffect, useContext, useState } from "react";
import "react-image-lightbox/style.css";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  useMediaQuery,
  Button,
  IconButton,
  Tooltip,
  LinearProgress,
  Typography,
  CardActions
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { axios } from "util/request";
import { AxiosResponse } from "axios";
import { V_SITE_API_URL } from "config/config";
import Qs from "qs";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ResolveError } from "util/connUtil";
import { AlertContext } from "component/alert/alertContext";
import { loadNewPageData, getFilterValueStringifyed } from "component/table/paginatorHelper";
import { getScopes, userHasPerission } from "util/token";
import { MonitorLocationUpdateAction } from "component/table/filterHelper";
import { SaveSetting, GetSavedSetting } from "component/table/paginatorSetting";
import { CREATE_EDIT_RESOURCE, FEATURE_NAME_GALLERY, GALLERY_PATH } from "util/const";
import styles from "style/common.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { findCatById } from "admin/category/service";
import useInfiniteScroll from "react-infinite-scroll-hook";
import TablePaginator from "component/table/paginator";
import Lightbox from "react-image-lightbox";
import { getImageSrc } from "component/upload/service";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import GalleryExpandFilter from "./filter/galleryExpandFilter";
import GallerySelectFilter from "./filter/gallerySelectFilter";
import { getStructuredGalleryList } from "./service";
import { GalleryCategory, GalleryData, GalleryTableData } from "./data.d";
import UploadPictureDialog from "./upload/uploadPictureDialog";

const CustomizedImgInnerBox = styled(Box)(() => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    textAlign: "center",
    overflow: "hidden"
  };
});

const CustomizedImg = styled("img")(() => {
  return {
    display: "block",
    width: "auto",
    height: "100%",
    cursor: "pointer"
  };
});

const Gallery = () => {
  const [galleryTreeData, setGalleryTreeData] = useState<GalleryCategory[]>([]);
  const { setAlertInfo, setDialogInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [selectedGalleryCat, setSelectedGalleryCat] = useState<string>();
  const [selectedGalleryCatId, setSelectedGalleryCatId] = useState<number>(0);
  const [galleryImageList, setGalleryImageList] = useState<any>();
  const [imageGridData, setImageGridData] = useState<GalleryTableData>();
  const [currentImageIndex, setCurrentImageIndex] = useState<number | undefined>(undefined);
  const [showUploadPictureDialog, setShowUploadPictureDialog] = useState<boolean>(false);
  const [currentImageBase, setCurrentImageBase] = useState<string>("");
  const [previousImageBase, setPreviousImageBase] = useState<string>("");
  const [nextImageBase, setNextImageBase] = useState<string>("");
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [openListBox, setOpenListBox] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "gallery"]);
  const scopes = getScopes();
  const hasResourcesPermission = userHasPerission(scopes, [CREATE_EDIT_RESOURCE]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const setGalleryName = (galleryCatId: number) => {
    const selectedG = findCatById(galleryTreeData, galleryCatId);
    setSelectedGalleryCat(selectedG?.name || "");
  };

  useEffect(() => {
    getStructuredGalleryList()
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data && response.data.data) {
          setGalleryTreeData(response.data.data);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      });
  }, []);

  useEffect(() => {
    if (selectedGalleryCatId > 0) {
      setGalleryName(selectedGalleryCatId);
    }
  }, [galleryTreeData, selectedGalleryCatId]);

  const handleGalleryIsSelected = (selectedId: string) => {
    const data = { category_id: selectedId };
    SaveSetting({
      current_page: 1,
      feature_name: FEATURE_NAME_GALLERY,
      filter_value: data
    });
    const urlPara = Qs.stringify(data, { arrayFormat: "repeat" });
    let historyPath = GALLERY_PATH;
    if (urlPara !== "") {
      historyPath += `?${urlPara}`;
    }
    navigate(historyPath);
    // const selectedG = findCatById(galleryTreeData, Number(selectedId));
    // setSelectedGalleryCatId(Number(selectedId));
    // setSelectedGalleryCat(selectedG?.name || "");
  };

  const addPictureButton = (): React.ReactNode => {
    const tooltipStr: string = t("gallery:label.manage-picture");
    if (hasResourcesPermission) {
      if (!isMobile) {
        return (
          <Button
            variant="contained"
            onClick={() => {
              if (selectedGalleryCatId > 0) {
                setShowUploadPictureDialog(true);
              } else {
                setDialogInfo({
                  open: true,
                  title: t("dialog.title-warning"),
                  content: t("gallery:dialog.please-select-a-gallery"),
                  onOk: () => {}
                });
              }
            }}
            startIcon={<FontAwesomeIcon icon={faUpload} />}
          >
            {tooltipStr}
          </Button>
        );
      }
      return (
        <IconButton
          color="primary"
          onClick={() => {
            setShowUploadPictureDialog(true);
          }}
        >
          <Tooltip title={tooltipStr} placement="top">
            <CloudUploadIcon />
          </Tooltip>
        </IconButton>
      );
    }
    return <></>;
  };

  const executeFetchImages = (iParams: any) => {
    setLoadingBackdrop(true);
    const actualUrl = "/gallery/search";
    setLoadingData(true);
    axios
      .get(actualUrl, {
        params: iParams,
        baseURL: V_SITE_API_URL,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        }
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const newData: GalleryTableData = response.data;
          if (isMobile && galleryImageList?.data && response.data.meta.current_page > 1) {
            newData.data = [...galleryImageList.data, ...response.data.data];
          }
          setGalleryImageList(newData as GalleryTableData);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error.message}, ${t("error.connection")}`
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
        setLoadingData(false);
      });
  };

  const runExecuteFetchImages = () => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_GALLERY);
    // console.log("pageSetting", pageSetting);
    const galleryCatId = Number(pageSetting.filter_value.category_id);
    setSelectedGalleryCatId(galleryCatId);
    // setGalleryName(galleryCatId);
    galleryCatId > 0 &&
      executeFetchImages &&
      executeFetchImages({
        ...pageSetting.filter_value,
        page_size: pageSetting.record_per_page,
        current_page: pageSetting.current_page,
        category_id: galleryCatId
      });
  };

  const localLoadNewPageData = (optionIsNextPage?: boolean) => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_GALLERY);
    let pageNo = pageSetting.current_page!;
    if (optionIsNextPage) {
      pageNo += 1;
    }
    loadNewPageData(
      pageNo,
      pageSetting.record_per_page!,
      FEATURE_NAME_GALLERY,
      pageSetting.filter_value,
      runExecuteFetchImages
    );
  };

  const loadMore = () => {
    if (isMobile) {
      localLoadNewPageData(true);
    }
  };

  // useEffect(() => {
  //   if (selectedGalleryCatId > 0) {
  //     console.log("prepare localLoadNewPageData");
  //     localLoadNewPageData();
  //   }
  // }, [selectedGalleryCatId]);

  useEffect(() => {
    MonitorLocationUpdateAction({
      location,
      featureName: FEATURE_NAME_GALLERY,
      featurePath: GALLERY_PATH,
      fnrExecuteFetchList: runExecuteFetchImages
    });
  }, [location]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingData,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !isMobile,
    rootMargin: "0px 0px 50px 0px"
  });

  useEffect(() => {
    if (galleryImageList && galleryImageList.data && currentImageIndex !== undefined) {
      // Current Image
      getImageSrc(galleryImageList.data[currentImageIndex].original_file).then((response) => {
        const content = `data:${response.headers[
          "content-type"
        ].toLowerCase()};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
        setCurrentImageBase(content);
      });

      // Previous Image
      if (currentImageIndex - 1 >= 0) {
        getImageSrc(galleryImageList.data[currentImageIndex - 1].original_file).then((response) => {
          const content = `data:${response.headers[
            "content-type"
          ].toLowerCase()};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
          setPreviousImageBase(content);
        });
      } else {
        setPreviousImageBase("");
      }
      // Previous Image
      if (currentImageIndex + 1 < galleryImageList.data.length) {
        getImageSrc(galleryImageList.data[currentImageIndex + 1].original_file).then((response) => {
          const content = `data:${response.headers[
            "content-type"
          ].toLowerCase()};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
          setNextImageBase(content);
        });
      } else {
        setNextImageBase("");
      }
    }
  }, [currentImageIndex]);

  useEffect(() => {
    if (galleryImageList && galleryImageList.data) {
      if (galleryImageList.meta.current_page < galleryImageList.meta.total_page) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
      setImageGridData({
        data: galleryImageList.data.map((row: GalleryData, index: number) => {
          return (
            <Box
              key={row.id}
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                marginBottom: 2,
                marginRight: 2,
                width: { xs: "300px", md: "350px", lg: "150px" },
                height: { xs: "400px", md: "300px", lg: "150px" }
                // boxSizing: "border-box"
                // border: "1px solid #eaeaea",
                // borderRadius: 10,
              }}
            >
              <CustomizedImgInnerBox>
                <CustomizedImg
                  onClick={() => {
                    setCurrentImageIndex(index);
                    setOpenListBox(true);
                  }}
                  key={row.id}
                  src={row.thumbnail_file}
                  alt={row.filename}
                />
              </CustomizedImgInnerBox>
            </Box>
          );
        }),
        meta: galleryImageList.meta
      });
    }
  }, [galleryImageList]);

  const showTablePaginator = () => {
    return (
      !!selectedGalleryCatId &&
      !isMobile &&
      imageGridData &&
      imageGridData.meta &&
      imageGridData.meta.total_record > 0
    );
  };

  const showWhichMessage = () => {
    if (!selectedGalleryCatId) {
      return <Typography>{t("gallery:message.select-gallery")}</Typography>;
    }
    if (
      !(
        !!selectedGalleryCatId &&
        imageGridData &&
        imageGridData.meta &&
        imageGridData.meta.total_record > 0
      )
    ) {
      return <Typography>{t("gallery:message.no-image")}</Typography>;
    }
    return <></>;
  };

  const initCategorySelectedValue = () => {
    const category = GetSavedSetting(FEATURE_NAME_GALLERY).filter_value?.category_id;
    return category ? [category] : [];
  };

  const showMobileTotal = () => {
    return (
      isMobile && selectedGalleryCatId > 0 && imageGridData && imageGridData.meta.total_record > 0
    );
  };

  const downloadButton = (data: string): React.ReactNode => {
    return (
      <IconButton
        onClick={() => {
          if (currentImageIndex !== undefined) {
            saveAs(data, galleryImageList.data[currentImageIndex].filename);
          }
        }}
        style={{ marginRight: "2px", color: "white" }}
        aria-label="Download"
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: { xs: "none", md: "block" }, p: 1 }}>
          <GalleryExpandFilter
            galleryTreeData={galleryTreeData}
            initSelected={initCategorySelectedValue()}
            searchBy={handleGalleryIsSelected}
          />
        </Box>
        <Box sx={{ p: 1, flexGrow: 4 }}>
          <Card sx={{ marginRight: "10px", backgroundColor: "transparent" }}>
            <CardHeader
              title={selectedGalleryCat}
              subheader={t("nav.gallery")}
              action={addPictureButton()}
            />
            <CardContent>
              <Grid container>
                {isMobile && (
                  <Grid item xs={12} className={styles.filterAreaSpacing}>
                    <GallerySelectFilter
                      galleryTreeData={galleryTreeData}
                      initSelected={initCategorySelectedValue()}
                      searchBy={handleGalleryIsSelected}
                    />
                  </Grid>
                )}
                {showWhichMessage()}
                {showMobileTotal() && imageGridData && (
                  <Grid item xs={12} style={{ paddingBottom: "5px" }}>
                    {imageGridData &&
                      imageGridData.meta &&
                      t("gallery:label.total-no-of-record", {
                        number: imageGridData!.meta!.total_record.toString()
                      })}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {selectedGalleryCatId > 0 && (
                    <Box sx={{ textAlign: { xs: "center", md: "left" } }}>
                      {imageGridData && imageGridData.data ? (
                        imageGridData.data
                      ) : (
                        <LinearProgress />
                      )}
                      {isMobile && (loadingData || hasNextPage) && (
                        <div key="x" ref={sentryRef}>
                          <LinearProgress />
                        </div>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions disableSpacing>
              {showTablePaginator() && imageGridData && (
                <TablePaginator
                  key="table-paginator"
                  recordPerPageOptions={{
                    defaultOption: imageGridData.meta.record_per_page
                  }}
                  recordFrom={imageGridData.meta.from}
                  recordTo={imageGridData.meta.to}
                  currentPage={imageGridData.meta.current_page}
                  recordTotal={imageGridData.meta.total_record}
                  noOfPages={imageGridData.meta.total_page}
                  featureName={FEATURE_NAME_GALLERY}
                  urlPath={GALLERY_PATH}
                  urlQuery={getFilterValueStringifyed(FEATURE_NAME_GALLERY)}
                />
              )}
            </CardActions>
          </Card>
        </Box>
      </Box>
      <UploadPictureDialog
        galleryCatId={selectedGalleryCatId}
        galleryCatName={selectedGalleryCat || ""}
        open={showUploadPictureDialog}
        onClose={(refresh: boolean) => {
          if (refresh) {
            localLoadNewPageData();
          }
          setShowUploadPictureDialog(false);
        }}
      />
      {openListBox && (
        <Lightbox
          mainSrc={currentImageBase}
          nextSrc={nextImageBase}
          prevSrc={previousImageBase}
          toolbarButtons={[downloadButton(currentImageBase)]}
          reactModalStyle={{ overlay: { zIndex: "9999" } }}
          onCloseRequest={() => {
            setOpenListBox(false);
          }}
          onMovePrevRequest={() => {
            currentImageIndex !== undefined && setCurrentImageIndex(currentImageIndex - 1);
          }}
          onMoveNextRequest={() => {
            currentImageIndex !== undefined && setCurrentImageIndex(currentImageIndex + 1);
          }}
        />
      )}
    </>
  );
};

export default Gallery;
