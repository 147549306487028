import { axios } from "util/request";
import { GalleryFormData } from "./data.d";

const SAVE_UPLOAD_TIMEOUT = 120000; // 2 minutes

export const getStructuredGalleryList = () => {
  const url = "/category/gallery/tree/list";
  return axios.get(url);
};

export const fetchCreateGallery = (gallery: GalleryFormData) => {
  const url = `/gallery`;
  return axios.post(url, gallery, { timeout: SAVE_UPLOAD_TIMEOUT });
};

export const getSimpleGalleryByCategory = (categoryId: number) => {
  const url = `/gallery/all/${categoryId}/category`;
  return axios.get(url);
};

export const getImageOriginalBase64 = (id: number) => {
  const url = `/gallery/image/original/type/${id}`;
  return axios.get(url);
};
