import React, { useCallback, useEffect, useState } from "react";
import update from "immutability-helper";
import { ResourceGroupListDto } from "res/resource/data.d";
import DragableResourceGroupItem from "./dragableResourceGroupItem";

interface DragableResourceGroupContainerProps {
  resGroupList: ResourceGroupListDto[];
  // eslint-disable-next-line react/no-unused-prop-types
  onChangeResGroupList: (resGroups: ResourceGroupListDto[]) => void;
}

const DragableResourceGroupContainer: React.FC<DragableResourceGroupContainerProps> = (props) => {
  const { resGroupList, onChangeResGroupList } = props;
  const [resourceGroupList, setResourceGroupList] = useState<ResourceGroupListDto[]>(resGroupList);

  const moveRow = useCallback((dragIndex, hoverIndex) => {
    // console.log("moveRow dragIndex hoverIndex", dragIndex, hoverIndex);

    // copy and set is not working, don't know why
    // const newList = [...resourceGroupList];
    // newList.splice(dragIndex, 1);
    // newList.splice(hoverIndex, 0, resourceGroupList[dragIndex]);
    // console.log("newList", newList);
    // setResourceGroupList(newList);

    setResourceGroupList((prevResGroupList: ResourceGroupListDto[]) => {
      return update(prevResGroupList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevResGroupList[dragIndex]]
        ]
      });
    });
  }, []);

  useEffect(() => {
    onChangeResGroupList(resourceGroupList);
    // console.log("resourceGroupList", resourceGroupList);
  }, [resourceGroupList]);

  const removeResource = (resourceGroupId: number) => {
    for (let i = 0; i < resourceGroupList.length; i += 1) {
      if (resourceGroupList[i].id === resourceGroupId) {
        const newResGroupList = [...resourceGroupList];
        newResGroupList.splice(i, 1);
        setResourceGroupList(newResGroupList);
      }
    }
  };

  const renderResGroup = useCallback(
    (resGroupItem: ResourceGroupListDto, index: number) => {
      //   console.log("resGroupItem", resGroupItem, index);
      return (
        <DragableResourceGroupItem
          key={resGroupItem.id}
          id={resGroupItem.id.toString()}
          index={index}
          resource_group={resGroupItem}
          moveRow={moveRow}
          removeResource={removeResource}
        />
      );
    },
    [resourceGroupList]
  );
  return (
    <>
      <div>
        {resourceGroupList.map((resGroup, i) => {
          return renderResGroup(resGroup, i);
        })}
      </div>
    </>
  );
};

export default DragableResourceGroupContainer;
