import React, { useState, useContext, useEffect } from "react";
import { Paper, Typography, Collapse, Box } from "@mui/material";
import { CategoryContext } from "admin/category/categoryContext";
import { SAVED_CATEGORY_NODE_STATE } from "util/const";
import { useTranslation } from "react-i18next";
import { findCatById } from "admin/category/service";
import { Tree } from "antd";
import ExpandMore from "component/button/expandMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface CategoryExpandFilterProps {
  searchByCategory: (selectedID: string, removeOtherCriteria?: boolean) => void;
  clearAllSelected: boolean;
  initSelected: string[];
}

function getSavedCategory(): string[] | undefined {
  let savedCategoryState = localStorage.getItem(SAVED_CATEGORY_NODE_STATE);
  if (savedCategoryState === "" || savedCategoryState === null) {
    savedCategoryState = "[]";
  }
  if (savedCategoryState) {
    return JSON.parse(savedCategoryState);
  }
  return undefined;
}

const CategoryExpandFilter: React.FC<CategoryExpandFilterProps> = (props) => {
  const { fullCatTreeData, mediaAntCategoryTreeData, topicAntCategoryTreeData } =
    useContext(CategoryContext);
  const { initSelected, clearAllSelected } = props;
  const [topicTypeCategoryExpaned, setTopicTypeCategoryExpaned] = useState<boolean>(true);
  const [mediaTypeCategoryExpaned, setMediaTypeCategoryExpaned] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [nodeSelected, setNodeSelected] = useState<string[]>(initSelected || []);
  const { t } = useTranslation(["translation", "topic"]);

  useEffect(() => {
    const savedCategoryState = getSavedCategory();
    if (savedCategoryState) {
      setExpanded(savedCategoryState);
    }
  }, []);

  useEffect(() => {
    if (clearAllSelected) {
      setNodeSelected([]);
    }
  }, [clearAllSelected]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleExpand = (expandedKeys: React.Key[], info: any) => {
    // console.log("handleExpand", info);
    setExpanded(expandedKeys as string[]);
    localStorage.setItem(SAVED_CATEGORY_NODE_STATE, JSON.stringify(expandedKeys));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSelect = (selectedKeys: React.Key[], info: any) => {
    // console.log("handleExpand", info);
    if (selectedKeys.length > 0) {
      const nodeId = selectedKeys[0] as string;
      const catNode = findCatById(fullCatTreeData.data, Number(nodeId));
      if (!catNode?.children) {
        setNodeSelected(selectedKeys as string[]);
        props.searchByCategory(nodeId);
      } else {
        const savedCategoryState = getSavedCategory();
        if (savedCategoryState) {
          const gotIt = savedCategoryState.find((value) => {
            return nodeId === value;
          });
          if (!gotIt) {
            // expand node
            savedCategoryState.push(nodeId);
            handleExpand(savedCategoryState, undefined);
          } else {
            // collapse node
            savedCategoryState.splice(
              savedCategoryState.findIndex((value) => {
                return value === nodeId;
              }),
              1
            );
            handleExpand(savedCategoryState, undefined);
          }
        }
      }
    }
  };

  return (
    <Paper style={{ padding: "10px", width: "320px", backgroundColor: "transparent" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h6" style={{ marginBottom: "3px" }}>
            {t("topic:label.topic-categroy-list")}
          </Typography>
          <ExpandMore
            expand={topicTypeCategoryExpaned}
            onClick={() => {
              setTopicTypeCategoryExpaned(!topicTypeCategoryExpaned);
            }}
            aria-expanded={topicTypeCategoryExpaned}
            aria-label="Show more"
          />
        </Box>
        <Collapse in={topicTypeCategoryExpaned} timeout="auto" unmountOnExit>
          <Tree
            aria-label="Category List"
            expandedKeys={expanded}
            titleRender={(node) => {
              if (!node.children) {
                return <Typography color="primary">{node.title}</Typography>;
              }
              return <Typography color="secondary">{node.title}</Typography>;
            }}
            selectedKeys={nodeSelected}
            onExpand={handleExpand}
            onSelect={handleSelect}
            switcherIcon={<ExpandMoreIcon />}
            treeData={topicAntCategoryTreeData}
          />
        </Collapse>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Typography variant="h6" style={{ marginBottom: "3px" }}>
            {t("topic:label.media-categroy-list")}
          </Typography>
          <ExpandMore
            expand={mediaTypeCategoryExpaned}
            onClick={() => {
              setMediaTypeCategoryExpaned(!mediaTypeCategoryExpaned);
            }}
            aria-expanded={mediaTypeCategoryExpaned}
            aria-label="Show more"
          />
        </Box>
        <Collapse in={mediaTypeCategoryExpaned} timeout="auto" unmountOnExit>
          <Tree
            aria-label="Category List"
            expandedKeys={expanded}
            titleRender={(node) => {
              if (!node.children) {
                return <Typography color="primary">{node.title}</Typography>;
              }
              return <Typography color="secondary">{node.title}</Typography>;
            }}
            selectedKeys={nodeSelected}
            onExpand={handleExpand}
            onSelect={handleSelect}
            switcherIcon={<ExpandMoreIcon />}
            treeData={mediaAntCategoryTreeData}
          />
        </Collapse>
      </Box>
    </Paper>
  );
};

export default CategoryExpandFilter;
