import { axios } from "util/request";
import { C_SITE_API_URL } from "config/config";
import { UpdateUserProfile, UpdateUserPassword } from "./data.d";

export const fetchCurrentUserInfo = () => {
  const url = "/user-profile/my/info";
  return axios.get(url);
};

export const fetchUserAttrOption = () => {
  const url = "/register/option";
  return axios.get(url, { baseURL: C_SITE_API_URL });
};

export const fetchUpdateUserProfile = (user: UpdateUserProfile) => {
  const url = "/user-profile";
  const updatePart = user;
  return axios.put(url, { ...updatePart });
};

export const fetchUpdatePasswordOnProfile = (password: UpdateUserPassword) => {
  const url = "/user-profile/update/password";
  return axios.post(url, { ...password });
};
