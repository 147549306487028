import * as React from "react";
import { useRef, useState } from "react";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  onClick: () => void;
}

const PREFIX = "ExpandMore";
const classes = {
  button: `${PREFIX}-button`
};

const CustomizedIconButton = styled(IconButton)(({ theme }) => {
  return {
    [`&.${classes.button}`]: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    }
  };
});

const ExpandMore: React.FC<ExpandMoreProps> = (props) => {
  const { expand: catExpand } = props;
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [expand, setExpanded] = useState<boolean>(catExpand);

  const handleButtonClicked = () => {
    if (buttonRef && buttonRef.current) {
      if (!expand) {
        buttonRef.current.style.transform = "rotate(0deg)";
      } else {
        buttonRef.current.style.transform = "rotate(180deg)";
      }
    }
    setExpanded(!expand);
    props.onClick();
  };

  return (
    <CustomizedIconButton
      ref={buttonRef}
      className={classes.button}
      onClick={handleButtonClicked}
      size="small"
    >
      <ExpandMoreIcon />
    </CustomizedIconButton>
  );
};

export default ExpandMore;
