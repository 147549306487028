import * as React from "react";
// import { useGoogleLogout } from "react-google-login";
// GoogleLoginResponse, GoogleLoginResponseOffline
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState, useEffect, useContext } from "react";
import AuthProvider, {
  whereToGoAfterLogin,
  fetchTokenExchange,
  fetchCodeExchange
} from "auth/authProvider";
// import { History } from "history";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { PublicClientApplication } from "@azure/msal-browser";
import { MS_CONFIG } from "config/config";
import { AlertContext } from "component/alert/alertContext";
import {
  AZUREAD_TOKEN_EXCHANGE,
  // GOOGLE_TOKEN_EXCHANGE,
  SSO_TOKEN_EXCHANGE_URL,
  REMEMBER_ME_SETTING,
  SAVED_EMAIL
} from "util/const";
// import { ResolveError } from "../util/ConnUtil";
// import { getToken } from "util/token";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import Backdrop from "@mui/material/Backdrop";
// import CircularProgress from "@mui/material/CircularProgress";
// import { useCommonStyles } from "../resource/CommonStyle";
import { useTranslation } from "react-i18next";
import LanguageButton from "layout/languageButton";
// import { useTheme } from "@emotion/react";
import { styled } from "@mui/material/styles";
import GoogleLoginButton from "./sso/googleLoginButton";
import MicrosoftLogin from "./sso/microsoftLoginButton";
import ForgotPassword from "./forgotPassword";
import styles from "./signIn.module.css";
import Copyright from "./component/copyright";

const CustomizedDiv = styled("div")(({ theme }) => {
  return `
      margin: ${theme.spacing(2, 4)};
      display: flex;
      flex-direction: column;
      align-items: center;`;
});

const CustomizedSignupDiv = styled("div")(({ theme }) => {
  return `
      margin-left: ${theme.spacing(4)};
      padding-top: ${theme.spacing(1.5)};
      flex: 1;
      flex-grow: 100;`;
});

const CustomizedLanguageDiv = styled("div")(({ theme }) => {
  return `
      margin-right: ${theme.spacing(2.5)};
      flex: 1;
      flex-shrink: 0.5;`;
});

const CustomizedAvatar = styled(Avatar)(({ theme }) => {
  return `margin: ${theme.spacing(1)};
      background-color: white;
      border: 0.5px solid lightgray;`;
});

const CustomizedForm = styled("form")(({ theme }) => {
  return `
      margin-top: ${theme.spacing(1)};
      width: "100%";`;
});

const CustomizedSubmitButton = styled(Button)(({ theme }) => {
  return `
      margin: ${theme.spacing(3, 0, 2)};`;
});

const SignInSide: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mounted] = useState(true);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();
  const urlQueryObj = queryString.parse(location.search);
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  let ssoTokenExchangeUrl = localStorage.getItem(SSO_TOKEN_EXCHANGE_URL);
  // const classes = useStyles();
  const navigate = useNavigate();
  // const commonClasses = useCommonStyles();
  // console.log("test sign in");
  // const handleGoogleLogout = () => {
  //   console.log("handleGoogleLogout");
  // };
  const { t } = useTranslation(["translation", "signin"]);
  // const { signOut, loaded: signoutLoaded } = useGoogleLogout({
  //   clientId: GOOGLE_CONFIG.clientId,
  //   uxMode: GOOGLE_CONFIG.uxMode,
  //   redirectUri: GOOGLE_CONFIG.redirectUri,
  //   onLogoutSuccess: handleGoogleLogout,
  //   onFailure: handleGoogleLogout
  // });

  const removeSsoToken = () => {
    // localStorage.removeItem(SSO_TOKEN);
    // setSsoToken = "";
  };

  const removeSsoTokenExchangeUrl = () => {
    localStorage.removeItem(SSO_TOKEN_EXCHANGE_URL);
    ssoTokenExchangeUrl = "";
  };

  const loginErrorHandling = (e: Error) => {
    // console.log("sign out 1");
    // if (signoutLoaded) {
    // signOut();
    // }
    removeSsoToken();
    removeSsoTokenExchangeUrl();
    if (mounted) {
      setAlertInfo({
        open: true,
        message: e.message
      });
      // setGlobalAlert(alertObj);
      // console.log("backDropOpen 3", e.message);
      setLoadingBackdrop(false);
    }
  };

  const exchangeToken = (token: string) => {
    if (token) {
      // console.log("mounted", mounted);
      if (mounted) {
        // console.log("backDropOpen 1", backDropOpen);
        setLoadingBackdrop(true);
      }
      if (ssoTokenExchangeUrl) {
        fetchTokenExchange(ssoTokenExchangeUrl, token)
          .then((isSuccess) => {
            setLoadingBackdrop(false);
            // }
            if (isSuccess) {
              removeSsoToken();
              removeSsoTokenExchangeUrl();
              // history.push("/res/topic");
              // console.log("fetchTokenExchange");
              whereToGoAfterLogin(navigate);
            }
          })
          .catch((e: Error) => {
            // console.log("backDropOpen x", backDropOpen, e.message);
            loginErrorHandling(e);
          });
      }
    }
  };

  const saveSsoToken = (token: string) => {
    // console.log("saveSsoToken", token);
    // localStorage.setItem(SSO_TOKEN, token);
    exchangeToken(token);
    // setSsoToken(token);
  };

  const saveSsoTokenExchangeUrl = (url: string) => {
    // console.log("saveSsoTokenExchangeUrl", url);
    localStorage.setItem(SSO_TOKEN_EXCHANGE_URL, url);
    ssoTokenExchangeUrl = url;
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem(SAVED_EMAIL);
    const savedRememberMeValue = localStorage.getItem(REMEMBER_ME_SETTING) === "1";
    setRememberMe(savedRememberMeValue);
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  // useEffect(() => {
  //   const token = getToken();
  //   if (token) {
  //     // history.push("/res/topic");
  //     console.log("SignIn signoutLoaded");
  //     whereToGoAfterLogin(navigate);
  //   }
  //   return function cleanup() {
  //     setMounted(false);
  //   };
  // }, [signoutLoaded]);

  // const handleGoogleLoginResponse = (response: any) => {
  //   // if (typeof response === "GoogleLoginResponse") {
  //   console.log("handleGoogleLoginResponse", response);
  //   if (response && response.tokenObj && response.tokenObj.id_token) {
  //     saveSsoTokenExchangeUrl(GOOGLE_TOKEN_EXCHANGE);
  //     saveSsoToken(response.tokenObj.id_token);
  //   } else {
  //     // console.log("sign out 2");
  //     // signOut();
  //     // props.setShowBackdrop(false);
  //     // if resposne is empty, usually means the page is loaded, it doesn't really mean 365 sign in fail
  //   }
  //   // }
  // };
  // const handleGoogleLoginFailResponse = (response: any) => {
  //   if (response.error === "idpiframe_initialization_failed") {
  //     // do nothing
  //     // console.log("response", response);
  //   } else {
  //     setLoadingBackdrop(false);
  //     setAlertInfo({ open: true, message: t("error.fail-to-login") });
  //   }
  //   console.log("handleGoogleLoginFailResponse", response);
  // };

  const msalInstance = new PublicClientApplication(MS_CONFIG);

  function handleMsResponse(response: any) {
    // handle redirect response
    console.log("handleMsResponse", response);

    if (response && response.accessToken) {
      // console.log("handleMsResponse", response);
      saveSsoTokenExchangeUrl(AZUREAD_TOKEN_EXCHANGE);
      saveSsoToken(response.accessToken);
    } else {
      saveSsoToken("");
      // props.setShowBackdrop(false);
      // if resposne is empty, usually means the page is loaded, it doesn't really mean 365 sign in fail
    }
  }

  const doCodeExchange = (code: string) => {
    if (code) {
      // console.log("mounted", mounted);
      setLoadingBackdrop(true);
      fetchCodeExchange(code)
        .then((isSuccess) => {
          if (isSuccess) {
            whereToGoAfterLogin(navigate);
          }
        })
        .catch((e: Error) => {
          // console.log("e", e);
          loginErrorHandling(e);
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    }
  };

  const handleGoogleSignInResult = () => {
    const error = urlQueryObj.error as string;
    const codeForExchange = urlQueryObj.code as string;
    if (error && error !== "") {
      setAlertInfo({ open: true, message: t("error.fail-to-login") });
    } else if (codeForExchange !== "") {
      doCodeExchange(codeForExchange);
    }
  };

  useEffect(() => {
    // microsoft sign in, init
    msalInstance
      .handleRedirectPromise()
      .then(handleMsResponse)
      .catch((error) => {
        console.log("msalInstance error:", error);
        setAlertInfo({ open: true, message: t("error.fail-to-login") });
      });
    // google sign in result
    handleGoogleSignInResult();
  }, []);

  const Login = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingBackdrop(true);
    AuthProvider.login({
      email,
      password,
      navigate,
      setGlobalBackdropOpen: setLoadingBackdrop
    }).catch((error) => {
      console.log("msalInstance error:", error);
      setAlertInfo({
        open: true,
        message: error.message
      });
      // set the email to prevent user to enter again when login is failed
      localStorage.setItem(SAVED_EMAIL, email);
      setLoadingBackdrop(false);
    });
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem(REMEMBER_ME_SETTING, event.target.checked ? "1" : "0");
    setRememberMe(event.target.checked);
  };

  const rememberMeString = t("signin:button.remember-me") || "";
  return (
    <>
      <Grid container className={styles.root}>
        <CssBaseline />
        <Grid item xs={12} component={Paper}>
          <div className={styles.headerRow}>
            <CustomizedSignupDiv>
              <Link href="/registration/user-regulation" variant="body2">
                {t("signin:button.sign-up")}
              </Link>
            </CustomizedSignupDiv>
            <CustomizedLanguageDiv>
              <LanguageButton />
            </CustomizedLanguageDiv>
          </div>
          <CustomizedDiv>
            <Typography variant="h5">{t("site.sort_name")}</Typography>
            <CustomizedAvatar
              sx={{ width: 80, height: 80 }}
              alt={t("site.sort_name")}
              src="/sdbei-logo.png"
            />
            <CustomizedForm onSubmit={Login}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="email"
                label={t("label.email")}
                name="email"
                autoComplete="off"
                autoFocus
                value={email}
                onChange={(e) => {
                  return setEmail(e.target.value);
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("label.password")}
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => {
                  return setPassword(e.target.value);
                }}
              />
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      // value="remember"
                      checked={rememberMe}
                      onChange={handleRememberMe}
                      color="primary"
                    />
                  }
                  label={rememberMeString}
                  sx={{ flexGrow: 1 }}
                />
                <Button
                  href="#"
                  onClick={() => {
                    setOpenForgotPassword(true);
                  }}
                  sx={{
                    alignSelf: "flex-end",
                    padding: 0,
                    "&:hover,&:focus": {
                      backgroundColor: "white"
                    },
                    height: "50px"
                    // fontSize: { xs: "8px", md: "12px" }
                  }}
                >
                  {t("signin:button.forgot-password")}
                </Button>
              </Box>
              <CustomizedSubmitButton fullWidth variant="contained" type="submit">
                {t("signin:button.signin")}
              </CustomizedSubmitButton>
              <Box
                sx={{
                  paddingBottom: "15px",
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  color: "gray",
                  opacity: 0.7,
                  width: "100%"
                }}
              >
                <div
                  style={{
                    border: "1px solid gray",
                    marginTop: "10px",
                    height: "1px",
                    flexGrow: 0.5
                  }}
                />
                <div>
                  <Typography sx={{ marginLeft: "10px", marginRight: "10px", top: "-10px" }}>
                    {t("signin:label.or-sign-in-with")}
                  </Typography>
                </div>
                <div
                  style={{
                    border: "1px solid gray",
                    marginTop: "10px",
                    height: "1px",
                    flexGrow: 0.5
                  }}
                />
              </Box>
              <Box
                sx={{
                  p: 0,
                  display: "flex",
                  flexDirection: "row",
                  width: "100%"
                }}
              >
                <Box sx={{ flexGrow: 1, mr: "3px" }}>
                  <MicrosoftLogin msalInstance={msalInstance} />
                </Box>
                <Box sx={{ alignSelf: "flex-end" }}>
                  <GoogleLoginButton />
                </Box>
              </Box>
              {/* <Grid container>
                <Grid item xs>
                  <Button
                    href="#"
                    onClick={() => {
                      setOpenForgotPassword(true);
                    }}
                  >
                    {t("signin:button.forgot-password")}
                  </Button>
                </Grid>
              </Grid> */}
              <Box sx={{ mt: 5 }}>
                <Copyright />
              </Box>
            </CustomizedForm>
          </CustomizedDiv>
        </Grid>
      </Grid>
      <ForgotPassword
        open={openForgotPassword}
        onClose={() => {
          return setOpenForgotPassword(false);
        }}
      />
    </>
  );
};

export default SignInSide;
