import React, { useContext, useState } from "react";
import { AppBar, Toolbar, Typography, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faCommentAlt, faImage } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { AccountCircle } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { DRAWER_WIDTH as drawerWidth } from "util/const";
import authProvider from "auth/authProvider";
// import { useGoogleLogout } from "react-google-login";
// import { GOOGLE_CONFIG } from "config/config";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isNotGeneralUser } from "util/token";
import { LayoutContext } from "../layoutContext";
import { AccountMenu, menuID } from "./accountMenu";
import { AccountMenu as AccountMenuMobile, mobileMenuID } from "./accountMenuMobile";
import LanguageButton from "../languageButton";

const PREFIX = "MyAppBar";

const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  content: `${PREFIX}-content`,
  toolbar: `${PREFIX}-toolbar`,
  hide: `${PREFIX}-hide`,
  sectionMobile: `${PREFIX}-sectionMobile`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  grow: `${PREFIX}-grow`
};

const CustomizedLink = styled(NavLink)(() => {
  return `
        margin-right: 15px;
        color: #f0f0f0;
        font-weight: bold;
        &:hover {
          color: #c1f0e0f5;
        }
      `;
});

const CustomizedAppBar = styled(AppBar)(({ theme }) => {
  return {
    [`&.${classes.appBar}`]: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    [`&.${classes.appBarShift}`]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    [`& .${classes.toolbar}`]: {
      paddingRight: "0px"
    },
    [`& .${classes.sectionMobile}`]: {
      display: "flex",
      [theme.breakpoints.up("lg")]: {
        display: "none"
      }
    },
    [`& .${classes.sectionDesktop}`]: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "flex"
      }
    },
    [`& .${classes.hide}`]: {
      display: "none"
    },
    [`& .${classes.grow}`]: {
      flexGrow: 1
    }
  };
});

interface MenuStateProps {
  anchorEl: any;
  menuOpen: boolean;
}

const menuStateObj: MenuStateProps = {
  anchorEl: null,
  menuOpen: false
};

const MyAppBar: React.FC = () => {
  const { open, setOpen } = useContext(LayoutContext);
  const [menuState, setMenuState] = useState<MenuStateProps>(menuStateObj);
  const [mobileMenuState, setMobileMenuState] = useState<MenuStateProps>(menuStateObj);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const handleGoogleLogoutSuccess = () => {
  //   console.log("handleGoogleLogout success");
  //   navigate("/");
  // };

  //   const handleGoogleLogoutFail = (response: any) => {
  //     console.log("handleGoogleLogout fail", response);
  //     // redirect to login page only if cookie is enable, because google sign in would require cookie
  //     if (!(response && response.error && response.error === "idpiframe_initialization_failed")) {
  //       navigate("/");
  //     }
  //   };

  // const { signOut } = useGoogleLogout({
  //   clientId: GOOGLE_CONFIG.clientId,
  //   uxMode: GOOGLE_CONFIG.uxMode,
  //   redirectUri: GOOGLE_CONFIG.redirectUri,
  //   onLogoutSuccess: handleGoogleLogoutSuccess
  //   // onFailure: () => {} // as we cannot get the response, just use the success callback
  // });

  // console.log("MyAppBar 1");
  const handleLogout = () => {
    // console.log("who did it");
    authProvider.logout();
    // if (signoutLoaded) {
    // signOut();
    navigate("/");
    // if we want to keep the path that user last login, we keep here
    // keepTheLogoutPathToRedirect(false);
    // }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuState({ anchorEl: event.currentTarget, menuOpen: true });
  };
  const handleAccountMenuClose = () => {
    setMenuState({ anchorEl: null, menuOpen: false });
  };
  const handleMobileOpenAccountMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuState({ anchorEl: event.currentTarget, menuOpen: true });
  };
  const handleMobileAccountMenuClose = () => {
    setMobileMenuState({ anchorEl: null, menuOpen: false });
  };

  return (
    <>
      <CustomizedAppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolbar}>
          {isNotGeneralUser() && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography color="white" variant="h6" noWrap>
            {t("site.sort_name")}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <CustomizedLink to="/auth/res/resource">
              <Button variant="contained" startIcon={<FontAwesomeIcon icon={faFile} />}>
                {t("nav.latest-resource")}
              </Button>
            </CustomizedLink>
            <CustomizedLink to="/auth/res/topic">
              <Button variant="contained" startIcon={<FontAwesomeIcon icon={faCommentAlt} />}>
                {t("nav.resource-classification")}
              </Button>
            </CustomizedLink>
            <CustomizedLink to="/auth/gallery">
              <Button variant="contained" startIcon={<FontAwesomeIcon icon={faImage} />}>
                {t("nav.gallery-classification")}
              </Button>
            </CustomizedLink>
          </div>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuID}
              aria-haspopup="true"
              onClick={handleOpenAccountMenu}
              color="inherit"
              style={{ marginRight: "1px" }}
            >
              <AccountCircle />
            </IconButton>
            <LanguageButton primaryColor={false} />
          </div>
          <div className={classes.sectionMobile}>
            <CustomizedLink to="/auth/res/resource">
              <FontAwesomeIcon icon={faFile} />
            </CustomizedLink>
            <CustomizedLink to="/auth/res/topic">
              <FontAwesomeIcon icon={faCommentAlt} />
            </CustomizedLink>
            <CustomizedLink to="/auth/gallery">
              <FontAwesomeIcon icon={faImage} />
            </CustomizedLink>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="Show More"
              aria-controls={mobileMenuID}
              aria-haspopup="true"
              onClick={handleMobileOpenAccountMenu}
              color="inherit"
            >
              <MoreVertIcon />
            </IconButton>
          </div>
        </Toolbar>
      </CustomizedAppBar>
      <AccountMenu
        // menuId={menuID}
        open={menuState.menuOpen}
        anchorEl={menuState.anchorEl}
        handleClose={handleAccountMenuClose}
        signOut={handleLogout}
      />
      <AccountMenuMobile
        // menuId={mobileMenuID}
        open={mobileMenuState.menuOpen}
        anchorEl={mobileMenuState.anchorEl}
        handleClose={handleMobileAccountMenuClose}
        signOut={handleLogout}
      />
    </>
  );
};

export default MyAppBar;
