import React, { useContext } from "react";
import { Box, Avatar, Typography, IconButton, Divider } from "@mui/material";
import { AlertContext } from "component/alert/alertContext";
import { ResourceDto } from "res/resource/data.d";
import { saveAndDownloadFile } from "component/upload/service";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTranslation } from "react-i18next";
import { blackColor } from "style/theme";

interface InfoResourceListProps {
  resources: ResourceDto[];
}

const InfoResourceList: React.FC<InfoResourceListProps> = (props) => {
  const { resources } = props;
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { t } = useTranslation();

  const displayDownloadButton = (resource: ResourceDto): React.ReactNode => {
    return (
      <IconButton
        onClick={() => {
          saveAndDownloadFile(resource.id.toString(), resource.filename, setLoadingBackdrop).catch(
            (error) => {
              console.log("download error", error);
              setAlertInfo({
                open: true,
                message: t("error.fail-to-download")
              });
            }
          );
        }}
        color="secondary"
        edge="end"
        aria-label="Download"
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  };

  const listResGroupUi = (): React.ReactNode => {
    if (resources.length > 0) {
      let i = 0;
      return resources.map((res) => {
        i += 1;
        return (
          <div key={`${res.id}res_div`}>
            <Box
              sx={{
                margin: "5px",
                display: "flex",
                padding: "8px",
                // borderTop: "1px solid",
                // borderBottom: "1px solid",
                flexDirection: "row"
              }}
              key={`${res.id}res_outer_box`}
            >
              {resources.length > 1 && (
                <Box sx={{ paddingRight: "15px" }}>
                  <Avatar sx={{ bgcolor: "secondary.main" }}>{i}</Avatar>
                </Box>
              )}
              <Box sx={{ margin: "auto", flexGrow: "1" }}>
                <Typography variant="h6" color={blackColor}>
                  {res.filename}
                </Typography>
              </Box>
              <Box sx={{ paddingRight: "5px" }}>{displayDownloadButton(res)}</Box>
            </Box>
            {resources.length > 1 && <Divider variant="middle" />}
          </div>
        );
      });
    }
    return <></>;
  };

  return (
    <>
      {resources && resources.length >= 1 && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>{listResGroupUi()}</Box>
      )}
    </>
  );
};

export default InfoResourceList;
