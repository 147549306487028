import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Pagination,
  Box,
  useMediaQuery,
  SelectChangeEvent
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DEFAULT_RECORD_PER_PAGE_OPTION } from "util/const";
import { useNavigate } from "react-router-dom";
import { useTheme, styled } from "@mui/material/styles";
import { GetSavedSetting } from "./paginatorSetting";

const CustomizedFormControl = styled(FormControl)(({ theme }) => {
  return `
      margin: ${theme.spacing(1)};
      background-color: ${theme.palette.background.paper};
      top: -12px;
    `;
});

// handleRecordPerPageChange
// defaultRecordPerPage
// recordPerPageOptions
export interface PaginatorOptions {
  options?: number[];
  defaultOption: number;
}

export interface PaginatorProps {
  // handlePageChange?: (newPage: number, recordPerPage: number) => void;
  // handleRecordPerPageChange?: (currentPage: number, recordPerPage: number) => void;
  recordPerPageOptions?: PaginatorOptions;
  recordFrom: number;
  recordTo: number;
  recordTotal: number;
  currentPage: number;
  noOfPages: number;
  featureName: string;
  urlPath: string;
  urlQuery: string;
}

interface PaginatorState {
  pageOptions: number[];
  recordPerPage: number;
  recordFrom: number;
  recordTo: number;
  recordTotal: number;
  currentPage: number;
  noOfPages: number;
}

const TablePaginator: React.FC<PaginatorProps> = (props) => {
  const { recordPerPageOptions, recordFrom, recordTo, currentPage, recordTotal, noOfPages } = props;
  const navigate = useNavigate();
  const [paginatorState, setPaginatorState] = useState<PaginatorState>({
    pageOptions: recordPerPageOptions?.options,
    recordPerPage: recordPerPageOptions?.defaultOption,
    recordFrom,
    recordTo,
    currentPage,
    recordTotal,
    noOfPages
  } as PaginatorState);
  const { t } = useTranslation();
  const theme = useTheme();
  const atSmScreenSize = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const newState = {
      ...paginatorState,
      recordFrom: props.recordFrom,
      recordTo: props.recordTo,
      recordTotal: props.recordTotal,
      noOfPages: props.noOfPages,
      currentPage: props.currentPage
    };
    if (recordPerPageOptions) {
      newState.recordPerPage = recordPerPageOptions.defaultOption;
    }
    setPaginatorState(newState);
  }, [recordFrom, recordTo, noOfPages, recordTotal, currentPage, recordPerPageOptions]);

  useEffect(() => {
    if (paginatorState.pageOptions === undefined) {
      // it means it will be controlled by ourselve
      const newState = {
        ...paginatorState,
        pageOptions: DEFAULT_RECORD_PER_PAGE_OPTION.options,
        recordPerPage: DEFAULT_RECORD_PER_PAGE_OPTION.defaultOption
      };
      const pageSetting = GetSavedSetting(props.featureName);
      newState.recordPerPage = pageSetting.record_per_page ? pageSetting.record_per_page : 0;
      setPaginatorState(newState);
    }
  }, [paginatorState]);

  const changeUrlPathUsingHistory = (pageNumber: number, pageSize?: number) => {
    let searchPath = `?current_page=${pageNumber}`;
    if (pageSize) {
      searchPath += `&record_per_page=${pageSize}`;
    }
    if (props.urlQuery !== "") {
      let queryString = props.urlQuery.replace(/current_page=[0-9]+(&)?/gm, "");
      queryString = queryString.replace(/record_per_page=[0-9]+(&)?/gm, "");
      queryString = `&${queryString}`;
      searchPath += queryString;
    }

    // console.log("props.urlPath", props.urlPath);
    // console.log("searchPath", searchPath);
    navigate({
      pathname: props.urlPath,
      search: searchPath
    });
  };

  const recordPerPageHasBeenUpdated = (event: SelectChangeEvent<number>) => {
    const newNumber = Number(event.target.value);
    const newState = {
      ...paginatorState,
      recordPerPage: newNumber,
      currentPage: 1
    };
    setPaginatorState(newState);
    // handleRecordPerPageChange && handleRecordPerPageChange(1, newNumber);
    changeUrlPathUsingHistory(1, newNumber);
  };

  const recordPerPageOptionItem =
    paginatorState &&
    paginatorState.pageOptions &&
    paginatorState.pageOptions.map((option) => {
      return (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      );
    });

  const handleOnChange = (event: React.ChangeEvent<unknown>, pageNumber: number) => {
    const newState = { ...paginatorState, currentPage: pageNumber };
    setPaginatorState(newState);
    changeUrlPathUsingHistory(pageNumber, paginatorState.recordPerPage);
    // we don't need to call page change, because history.push has a listener on index page
    // props.handlePageChange(pageNumber, paginatorState.recordPerPage);
  };

  return (
    <Box
      sx={{
        p: 1,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        flexWrap: "wrap"
      }}
    >
      <Box sx={{ flexGrow: { md: 2 } }}>
        <Box sx={{ display: "flex", paddingTop: "5px" }}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box>
              {t("table.records-per-page")} {": "}
            </Box>
            <Box>
              <CustomizedFormControl variant="standard">
                {paginatorState.recordPerPage && (
                  <Select
                    labelId="record-per-page-label"
                    id="record-per-page"
                    onChange={recordPerPageHasBeenUpdated}
                    autoWidth
                    value={paginatorState && paginatorState.recordPerPage}
                  >
                    {recordPerPageOptionItem}
                  </Select>
                )}
              </CustomizedFormControl>
            </Box>
          </Box>
          <Box>
            {" "}
            {t("table.from")} {paginatorState.recordFrom} {t("table.to")} {paginatorState.recordTo}{" "}
            {t("table.of")} {paginatorState.recordTotal} {t("table.record")}
          </Box>
        </Box>
      </Box>
      <Box>
        <Pagination
          variant="outlined"
          count={paginatorState.noOfPages}
          color="primary"
          onChange={handleOnChange}
          page={paginatorState.currentPage}
          showFirstButton={!atSmScreenSize}
          showLastButton={!atSmScreenSize}
          siblingCount={atSmScreenSize ? 0 : 2}
        />
      </Box>
    </Box>
  );
};

export default TablePaginator;
