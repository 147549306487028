import React, { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";

interface HighlighterProps {
  searchTerm: string;
  searchContent: string;
}

const OwnHighlighter: React.FC<HighlighterProps> = (props) => {
  const { searchTerm, searchContent } = props;
  const [displayText, setDisplayText] = useState<React.ReactNode>(<>searchTerm</>);

  const formatSearchText = (inputSearchText: string): React.ReactNode => {
    const searchTermArr: string[] = [];
    for (let i = 0; i < inputSearchText.length; i += 1) {
      searchTermArr.push(inputSearchText.substring(i, i + 1));
    }
    return (
      <Highlighter
        highlightClassName="searchHighlight"
        searchWords={searchTermArr}
        autoEscape
        textToHighlight={searchContent}
      />
    );
  };

  useEffect(() => {
    if (searchTerm) {
      const formattedText = formatSearchText(searchTerm);
      setDisplayText(formattedText);
    } else {
      setDisplayText(searchContent);
    }
  }, [searchTerm, searchContent]);

  return <>{displayText}</>;
};

export default OwnHighlighter;
