import React, { useEffect, useState } from "react";

import { Stack, Chip, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { getStatusLabel } from "util/helper";
import { GetTranslatedOptionLabel } from "util/display";
import {
  CRITERIA_TYPE_RESOURCE,
  CRITERIA_TYPE_STATUS,
  ResourceGroupTypeDto,
  ResourceSearchFormData
} from "../data.d";

interface SearchCriteriaListProps {
  removeCriteriaByType: (type: string) => void;
  removeCriteriaByResourceGroupType: (id: number) => void;
  resourceGroupTypeList: ResourceGroupTypeDto[] | undefined;
  filterData: any;
}

const SearchCriteriaList: React.FC<SearchCriteriaListProps> = (props) => {
  const {
    filterData,
    removeCriteriaByType,
    removeCriteriaByResourceGroupType,
    resourceGroupTypeList
  } = props;
  //   const resourceGroupTypeList = useResourceGroupType();
  const [searchCriteria, setSearchCriteria] = useState<React.ReactNode | undefined>(undefined);
  const { t } = useTranslation(["translation", "resource"]);

  const searchCriteriaDisplay = (data: ResourceSearchFormData): React.ReactNode => {
    let resource = <></>;
    let resource_group_type = <></>;
    let status = <></>;
    // console.log("data", data);
    if (data) {
      if (data.resource) {
        resource = (
          <Chip
            label={`${t("resource:label.resource")}: ${data.resource}`}
            onDelete={() => {
              props.removeCriteriaByType(CRITERIA_TYPE_RESOURCE);
            }}
          />
        );
      }
      if (data && data?.status && data?.status >= 0) {
        status = (
          <Chip
            label={`${t("label.status")}: ${getStatusLabel(Number(data?.status), t)}`}
            onDelete={() => {
              props.removeCriteriaByType(CRITERIA_TYPE_STATUS);
            }}
          />
        );
      }
      if (data.resource_group_type) {
        const convertedResourceGroupTypeIdList: number[] = [...data.resource_group_type];
        const consolidatedGroupTypeList = convertedResourceGroupTypeIdList.map(
          (resGroupType: number) => {
            const resGroupTypeNameItem =
              resourceGroupTypeList?.find((item) => {
                return Number(item.id) === Number(resGroupType);
              }) || null;
            return (
              <Chip
                key={`${resGroupType}res_group_type_filter_result`}
                label={GetTranslatedOptionLabel(resGroupTypeNameItem) || ""}
                onDelete={() => {
                  return removeCriteriaByResourceGroupType(resGroupType);
                }}
              />
            );
          }
        );
        resource_group_type = <>{consolidatedGroupTypeList}</>;
      }
      if (data.resource || data.resource_group_type || data.status) {
        return (
          <Stack
            direction="row"
            style={{ marginTop: "5px" }}
            spacing={{ xs: 0, md: 1 }}
            sx={{ display: { xs: "block" } }}
          >
            {resource}
            {resource_group_type}
            {status}
            <Button
              color="primary"
              endIcon={<ClearIcon />}
              onClick={() => {
                removeCriteriaByType("");
              }}
            >
              {t("button.clear-all")}
            </Button>
          </Stack>
        );
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (filterData && resourceGroupTypeList) {
      setSearchCriteria(searchCriteriaDisplay(filterData));
    }
  }, [filterData, resourceGroupTypeList]);

  return <>{searchCriteria && searchCriteria}</>;
};

export default SearchCriteriaList;
