import React, { useState } from "react";
import { Typography, MenuItem, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import UserProfile from "admin/user-profile/index";
// import { selectAnchorEl, setAnchorEl } from "./AccountMenuSlice";

export interface AccountMenuProps {
  open: boolean;
  anchorEl: any;
  handleClose: () => void;
  signOut: () => void;
}

export const menuID = "account-menu";

/* *****************
 * AccountMenu
 * props menuID={menuID} open={isMenuOpen} anchorEl={anchorEl}, handleClose={handleClose}
 ***************** */
export const AccountMenu: React.FC<AccountMenuProps> = (props) => {
  // const history = useHistory();
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const { t } = useTranslation();
  const { anchorEl, open: showMenu } = props;
  const handleClose = () => {
    props.handleClose();
  };
  const handleLogout = () => {
    props.signOut();
    // console.log("handleLogout 1");
  };

  return (
    <>
      <Menu
        id={menuID}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={showMenu}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpenUserProfile(true);
            handleClose();
          }}
          style={{ minWidth: "150px" }}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Typography>{t("button.profile")}</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <ExitToAppIcon />
          </IconButton>
          <Typography>{t("button.logout")}</Typography>
        </MenuItem>
      </Menu>
      <UserProfile
        open={openUserProfile}
        onClose={() => {
          setOpenUserProfile(false);
        }}
      />
    </>
  );
};
