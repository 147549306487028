import React, { useState, useEffect, useContext } from "react";
import { Box, Divider, Avatar, Typography, IconButton, Badge } from "@mui/material";
import { AxiosResponse } from "axios";
import { AlertContext } from "component/alert/alertContext";
import { ResourceDto, ResourceGroupListDto } from "res/resource/data.d";
import { getResourceGroupByTopic } from "res/resource/service";
import ResolveError from "util/connUtil";
import { saveAndDownloadFile } from "component/upload/service";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns";
import styles from "style/common.module.css";
import ResourceList from "./resourceList";

interface ResourceGroupListProps {
  topic_id: number;
  highlight_res_group_id?: number;
}

const InfoResourceGroupList: React.FC<ResourceGroupListProps> = (props) => {
  const { topic_id, highlight_res_group_id } = props;
  const [openMultipleDownload, setOpenMultipleDownload] = useState<boolean>(false);
  const [currentResourceGroupDownloadPressed, setCurrentResourceGroupDownloadPressed] = useState<
    ResourceGroupListDto | undefined
  >(undefined);
  const [resourceGroupList, setResourceGroupList] = useState<ResourceGroupListDto[]>([]);
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (topic_id > 0) {
      setLoadingBackdrop(true);
      getResourceGroupByTopic(topic_id)
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            setResourceGroupList(response.data.data as ResourceGroupListDto[]);
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    }
  }, [topic_id]);

  const displayDownloadButton = (
    resourceGroup: ResourceGroupListDto,
    resource: ResourceDto[]
  ): React.ReactNode => {
    if (!resource) {
      return <></>;
    }

    if (resource.length === 1) {
      return (
        <IconButton
          onClick={() => {
            saveAndDownloadFile(
              resource[0].id.toString(),
              resource[0].filename,
              setLoadingBackdrop
            ).catch((error) => {
              console.log("download error", error);
              setAlertInfo({
                open: true,
                message: t("error.fail-to-download")
              });
            });
          }}
          color="secondary"
          edge="end"
          aria-label="Download"
        >
          <CloudDownloadIcon />
        </IconButton>
      );
    }

    return (
      <IconButton
        onClick={() => {
          setCurrentResourceGroupDownloadPressed(resourceGroup);
          setOpenMultipleDownload(true);
        }}
        color="secondary"
        edge="end"
        aria-label="download"
      >
        <Badge badgeContent={resource.length} color="success">
          <CloudDownloadIcon />
        </Badge>
      </IconButton>
    );
  };

  const listResGroupUi = (): React.ReactNode => {
    if (resourceGroupList.length > 0) {
      let i = 0;
      return resourceGroupList.map((resGroup) => {
        i += 1;
        const publishAt = formatDate(new Date(resGroup.publish_at), "yyyy-MM-dd");
        let className =
          highlight_res_group_id === resGroup.id
            ? styles.highlightSelected
            : styles.nomralWhiteBackground;
        if (resGroup.not_ready_to_publish) {
          className = styles.grayStyleBackground;
        }
        if (resGroup.status === 0) {
          className = styles.inactiveBackground;
        }
        return (
          <Box key={`${resGroup.id}resgroup_div`} className={className}>
            <Box
              sx={{ margin: "10px", display: "flex", flexDirection: "row" }}
              key={`${resGroup.id}resgroup_outer_box`}
            >
              <Box sx={{ paddingRight: "15px" }}>
                <Avatar sx={{ bgcolor: "primary.main" }}>{i}</Avatar>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography color="primary.main">
                  {resGroup.name} <span style={{ color: "black" }}>({publishAt})</span>
                </Typography>
                <Typography className={styles.grayStyleLevel2}>{resGroup.description}</Typography>
              </Box>
              <Box sx={{ paddingRight: "5px" }}>
                {displayDownloadButton(resGroup, resGroup.resource)}
              </Box>
            </Box>
            <Divider variant="middle" />
          </Box>
        );
      });
    }
    return <></>;
  };

  const multipleDownloadDialogClose = () => {
    setOpenMultipleDownload(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>{listResGroupUi()}</Box>
      {currentResourceGroupDownloadPressed && (
        <ResourceList
          resource_group={currentResourceGroupDownloadPressed}
          open={openMultipleDownload}
          onClose={multipleDownloadDialogClose}
        />
      )}
    </>
  );
};

export default InfoResourceGroupList;

InfoResourceGroupList.defaultProps = {
  highlight_res_group_id: 0
};
