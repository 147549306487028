import React from "react";
import { Typography, Link } from "@mui/material";
import { useTranslation } from "react-i18next";

const Copyright: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {t("site.copyright")}
      <Link color="inherit" href="/">
        {t("site.name")}
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
