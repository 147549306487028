import { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import type { ResourceGroupTypeDto } from "res/resource/data.d";
import { getResourceGroupTypeList } from "res/resource/service";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useResourceGroupType = (groupTypeId?: number[]) => {
  // console.log("groupTypeId", groupTypeId);
  const [resGroupTypeList, setResGroupTypeList] = useState<ResourceGroupTypeDto[] | undefined>([]);

  useEffect(() => {
    getResourceGroupTypeList()
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data) {
          // const newResGroupTypeDto: ResourceGroupTypeDto[] = [];
          // for (let i = 0; i < response.data.data.length; i += 1) {
          //   const newDto = response.data.data[i];
          //   newResGroupTypeDto.push({ id: Number(newDto.id), name: newDto.name });
          // }
          // console.log("newResGroupTypeDto", newResGroupTypeDto);
          setResGroupTypeList(response.data.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
    return () => {
      setResGroupTypeList(undefined);
    };
  }, []);

  return resGroupTypeList;
};

export default useResourceGroupType;
