import { SITE_API_URL } from "config/config";

export const LOGIN_URL = `${SITE_API_URL}/login`;
export const AZUREAD_TOKEN_EXCHANGE = `${SITE_API_URL}/auth/azuread/token/exchange`;
// export const GOOGLE_TOKEN_EXCHANGE = `${SITE_API_URL}/auth/google/token/exchange`;
export const CODE_TOKEN_EXCHANGE = `${SITE_API_URL}/auth/code/exchange`;

export const SAVED_REDIRECT_PATH = "saved_redirect_path";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const AUTH_PAYLOAD = "auth_payload";
export const API_DEFAULT_TIMEOUT = 5000; // 5s

// Pagination
export const RECORD_PER_PAGE = "record_per_page";
export const DEFAULT_RECORD_PER_PAGE_OPTION = {
  options: [3, 5, 10, 15, 20, 50],
  defaultOption: 10
};

export const SAVED_RECORD_PER_PAGE = "saved_record_per_page";
export const SAVED_CURRENT_PAGE = "saved_current_page";
export const SAVED_FILTER_VALUE = "saved_filter_value";

// Permission
export const CREATE_EDIT_RESOURCE = "create_edit_resource";
export const VIEW_USER_INFORMATION = "view_user_information";
export const EDIT_USER_INFORMATION = "edit_user_information";

// Feature Names
export const FEATURE_NAME_USER_MANAGEMENT = "user_management";
export const FEATURE_NAME_TOPIC = "topic";
export const FEATURE_NAME_RESOURCE = "resource";
export const FEATURE_NAME_GALLERY = "gallery";

export const SSO_TOKEN = "sso_token";
export const SSO_TOKEN_EXCHANGE_URL = "sso_token_exchange_url";

export const SAVED_LANG = "saved-lang";
export const TERMS_ACCEPTED = "terms_accepted";
export const REMEMBER_ME_SETTING = "remember_me";
export const SAVED_EMAIL = "saved_email";

// Feature path
export const USER_MANAGEMENT_PATH = "/auth/admin/user";
export const TOPIC_PATH = "/auth/res/topic";
export const RESOURCE_PATH = "/auth/res/resource";
export const GALLERY_PATH = "/auth/gallery";

// UI
export const DRAWER_WIDTH = 240;
export const LANG_EN = "en";
export const LANG_ZH_HK = "zh-HK";

// Category
export const SAVED_CATEGORY_NODE_STATE = "saved-category-node-state";

// Gallery
export const SAVED_GALLERY_NODE_STATE = "saved-gallery-node-state";

// Validation Rule
export const IsValidEmail = (email: string) => {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  }
  return false;
};

export const IsValidPhone = (phone: string) => {
  if (/^[0-9\s()+]{8,15}$/.test(phone)) {
    return true;
  }
  return false;
};

export const IsValidChineseName = (chi: string) => {
  if (/\p{Script=Han}/u.test(chi)) {
    return true;
  }
  return false;
};

export const IsValidEnglishName = (eng: string) => {
  if (/^[a-zA-Z\s]+$/.test(eng)) {
    return true;
  }
  return false;
};

export const IsValidReferee = (ref: string) => {
  if (/^[-a-zA-Z\p{Script=Han}\s/]+$/u.test(ref)) {
    return true;
  }
  return false;
};

export const IsValidPasswordFormat = (pass: string) => {
  if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])(?!.*\s).{8,}$/.test(pass)) {
    return true;
  }
  return false;
};

export const twoDecimal = (inputNo: number) => {
  return inputNo.toFixed(2);
};
