import React from "react";
import { axios } from "util/request";
import { Typography } from "@mui/material";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_REJECTED,
  UpdateUser,
  UserFormFilterProps
} from "./data.d";

export const fetchApproveUser = (ID: number) => {
  const url = `/user/approve/${ID}`;
  return axios.post(url);
};

export const fetchRejectUser = (ID: number) => {
  const url = `/user/reject/${ID}`;
  return axios.post(url);
};

export const fetchUpdateUser = (ID: number, user: UpdateUser) => {
  const url = `/user/${ID}`;
  const updatePart = user;
  return axios.put(url, { ...updatePart });
};

export const displayStatus = (status: number, statusLabel: string) => {
  let statusDisplay: React.ReactNode = "";
  if (status === STATUS_INACTIVE || status === STATUS_REJECTED) {
    statusDisplay = <Typography color="error">{statusLabel}</Typography>;
  } else if (status === STATUS_ACTIVE) {
    statusDisplay = <Typography color="primary">{statusLabel}</Typography>;
  } else {
    statusDisplay = (
      <Typography
        style={{
          color: "#853deb"
          // borderRadius: 25,
          // backgroundColor: "#000000",
        }}
      >
        {statusLabel}
      </Typography>
    );
  }
  return statusDisplay;
};

export const dataConvertToUrlParameter = (queryParam: UserFormFilterProps) => {
  const preparePara: any = {};
  if (queryParam) {
    if (queryParam.email) {
      preparePara.email = queryParam.email;
    }
    if (queryParam.name) {
      preparePara.name = queryParam.name;
    }
    if (queryParam.referee) {
      preparePara.referee = queryParam.referee;
    }
    if (queryParam.status) {
      preparePara.status = queryParam.status?.map((status) => {
        return status.value;
      });
    }
    if (queryParam.church) {
      preparePara.church_id = queryParam.church?.map((church) => {
        return church.id;
      });
    }
    if (queryParam.job_nature) {
      preparePara.job_nature_id = queryParam.job_nature?.map((job_nature) => {
        return job_nature.id;
      });
    }
    if (queryParam.authority) {
      preparePara.authority_id = queryParam.authority?.map((authority) => {
        return authority.id;
      });
    }
    if (queryParam.country) {
      preparePara.country_id = queryParam.country?.map((country) => {
        return country.id;
      });
    }
    if (queryParam.role) {
      preparePara.role_id = queryParam.role?.map((role) => {
        return role.id;
      });
    }
    if (queryParam.registration_from) {
      preparePara.registration_from = new Date(queryParam.registration_from).toISOString();
    }
    if (queryParam.registration_to) {
      preparePara.registration_to = new Date(queryParam.registration_to).toISOString();
    }
  }
  return preparePara;
};

export const getEmailList = (memberType: string) => {
  const url = `/user/email/list/${memberType}`;
  return axios.get(url);
};
