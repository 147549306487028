import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Table,
  Card,
  Grid,
  CardContent,
  CardActions,
  Dialog,
  Button,
  Tooltip,
  Box,
  LinearProgress,
  TableContainer,
  TableHead,
  TableBody,
  TableFooter,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  IconButton,
  Typography
} from "@mui/material";
import { axios } from "util/request";
import TablePaginator from "component/table/paginator";
import {
  loadNewPageData,
  getFilterValueStringifyed,
  getFilterValue
} from "component/table/paginatorHelper";
import { SaveSetting, GetSavedSetting } from "component/table/paginatorSetting";
import { AlertContext } from "component/alert/alertContext";
import { V_SITE_API_URL } from "config/config";
import { useTranslation } from "react-i18next";
import Qs from "qs";
import { format as formatDate } from "date-fns";
import {
  FEATURE_NAME_USER_MANAGEMENT,
  USER_MANAGEMENT_PATH,
  VIEW_USER_INFORMATION,
  EDIT_USER_INFORMATION
} from "util/const";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import InfoIcon from "@mui/icons-material/Info";
import BlockIcon from "@mui/icons-material/Block";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import { useLocation, useNavigate } from "react-router-dom";
import { getScopes, userHasPerission } from "util/token";
import CustomizedTableCell from "component/table/customizedTableCell";
import CustomizedTableRow from "component/table/customizedTableRow";
import styles from "style/common.module.css";
import {
  // LoadDataByCheckingUrl,
  MonitorLocationUpdateAction
  // RegisterPushPopHistoryAction
} from "component/table/filterHelper";
import { UserTableData, User, STATUS_PENDING_FOR_REVIEW, UserFormFilterProps } from "./data.d";
import TableFilter from "./filter";
import {
  fetchApproveUser,
  fetchRejectUser,
  displayStatus,
  dataConvertToUrlParameter
} from "./service";
import { fetchUserRoleList } from "../role/service";
import EditUser, { EditUserProps } from "./edit";
import { UserOptionContextProvider } from "./userOptionContext";
import EmailListDialog from "./emailList/emailListDialog";

interface IActionDialog {
  open: boolean;
  message?: string;
  okButtonText?: string;
  // okAction:
  //   | undefined
  //   | ((fetch: (ID: number) => Promise<AxiosResponse<any>>) => void);
  fetchedBy: ((ID: number) => Promise<AxiosResponse<any>>) | undefined;
}

const UserManagement: React.FC = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);
  const location = useLocation();
  const navigate = useNavigate();
  // let urlQueryObj = queryString.parse(location.search);
  // preparedData is the data used for submitting to the server
  // const [preparedData, setPreparedData] = useState<any>();
  // filterInitValue is the data for initialize the filter form
  // const [filterInitValue, setFilterInitValue] = useState<any>();
  const [userTableData, setUserTableData] = useState<UserTableData>();
  const [openEmailListDialog, setOpenEmailListDialog] = useState<boolean>(false);
  // we use a number only, once this number changed with an userID, list will be reloaded
  const [actionDialog, setActionDialog] = useState<IActionDialog>({
    open: false,
    fetchedBy: undefined
  });
  const [editUserProps, setEditUserProps] = useState<EditUserProps>({
    open: false
  });
  const [currentEditingUser, setCurrentEditingUser] = useState<User | undefined>();
  const { t } = useTranslation(["translation", "registration", "user-management"]);
  const [userList, setUserList] = useState<any>();
  // const [getUserListError, setGetUserListError] = useState<any>();
  const scopes = getScopes();
  const hasViewUserPermission = userHasPerission(scopes, [VIEW_USER_INFORMATION]);
  const hasEditUserPermission = userHasPerission(scopes, [EDIT_USER_INFORMATION]);

  const executeFetchUser = (iParams: any) => {
    // console.log("fetched");
    setLoadingBackdrop(true);
    // setUserTableData(undefined);
    const actualUrl = "/user/search";
    axios
      .get(actualUrl, {
        params: iParams,
        baseURL: V_SITE_API_URL,
        paramsSerializer: (params: any) => {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        }
      })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setUserList(response.data as UserTableData);
        } else {
          throw new Error(ResolveError(response.data));
        }
        setLoadingBackdrop(false);
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error.message}, ${t("error.connection")}`
          });
        setLoadingBackdrop(false);
      });
  };

  // we don't need to function anymore, because we rely on history.search listener
  const localLoadNewPageData = () => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_USER_MANAGEMENT);
    loadNewPageData(
      pageSetting.current_page!,
      pageSetting.record_per_page!,
      FEATURE_NAME_USER_MANAGEMENT,
      pageSetting.filter_value,
      executeFetchUser
    );
  };

  const runExecuteFetchUser = () => {
    const pageSetting = GetSavedSetting(FEATURE_NAME_USER_MANAGEMENT);
    // console.log("pageSetting.filter_value", pageSetting.filter_value);
    executeFetchUser &&
      executeFetchUser({
        ...pageSetting.filter_value,
        page_size: pageSetting.record_per_page,
        current_page: pageSetting.current_page
      });
  };

  useEffect(() => {
    // console.log("in location");
    MonitorLocationUpdateAction({
      location,
      featureName: FEATURE_NAME_USER_MANAGEMENT,
      featurePath: USER_MANAGEMENT_PATH,
      fnrExecuteFetchList: runExecuteFetchUser
    });
  }, [location]);

  // rely on useEffect [location]
  // useEffect(() => {
  //   console.log("general");
  //   LoadDataByCheckingUrl({
  //     iLocation: location,
  //     featureName: FEATURE_NAME_USER_MANAGEMENT,
  //     fnrExecuteFetchList: runExecuteFetchUser
  //   });
  // }, []);

  const handleEditUser = (inputUser: User) => {
    // console.log("handleEditUser", inputUser);
    setLoadingBackdrop(true);
    fetchUserRoleList(inputUser.id)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data && response.data.data) {
          const paraUser = { ...inputUser, role_list: response.data.data };
          setEditUserProps({ open: true, user: paraUser });
          setLoadingBackdrop(false);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
        setLoadingBackdrop(false);
      });
  };

  const actionButton = (user: User): React.ReactNode => {
    let approveRejectButton: React.ReactNode = "";
    let editButton: React.ReactNode = "";
    const tooltipsApproveString: string = t("user-management:button.approve");
    const tooltipsRejectString: string = t("user-management:button.reject");
    const tooltipsEditString: string = t("button.more-info-and-edit");

    if (user.status === STATUS_PENDING_FOR_REVIEW && hasEditUserPermission) {
      approveRejectButton = (
        <Box key={`action-icon-group-box${user.id}`}>
          <IconButton
            aria-label="approve"
            key={`approve_button${user.id}`}
            onClick={() => {
              setCurrentEditingUser(user);
              setActionDialog({
                open: true,
                message: "user-management:dialog.approve-warning-messsage",
                okButtonText: t("user-management:button.approve"),
                fetchedBy: fetchApproveUser
              });
            }}
            className={styles.actionColor}
          >
            <Tooltip title={tooltipsApproveString} placement="right">
              <CheckCircleIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            aria-label="reject"
            key={`reject_button${user.id}`}
            onClick={() => {
              setCurrentEditingUser(user);
              setActionDialog({
                open: true,
                message: "user-management:dialog.reject-warning-messsage",
                okButtonText: t("user-management:button.reject"),
                fetchedBy: fetchRejectUser
              });
            }}
          >
            <Tooltip title={tooltipsRejectString} placement="right">
              <BlockIcon color="error" />
            </Tooltip>
          </IconButton>
        </Box>
      );
    }

    editButton = (
      <IconButton
        aria-label="edit"
        key={`edit_button${user.id}`}
        onClick={() => {
          return handleEditUser(user);
        }}
      >
        <Tooltip title={tooltipsEditString} placement="right">
          <InfoIcon color="secondary" />
        </Tooltip>
      </IconButton>
    );

    // }
    return [approveRejectButton, editButton];
  };

  const handleActionDialogClose = () => {
    setCurrentEditingUser(undefined);
    setActionDialog({ open: false, fetchedBy: undefined });
  };

  const handleActionDialogOKClicked = (fetch: (ID: number) => Promise<AxiosResponse<any>>) => {
    if (currentEditingUser) {
      fetch(currentEditingUser.id)
        .then((response: AxiosResponse) => {
          if (response && response.status === 200 && response.data) {
            setSuccessInfo({
              open: true,
              message: response.data.message
            });
            localLoadNewPageData();
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        });
      setActionDialog({
        open: false,
        fetchedBy: undefined
      });
      setCurrentEditingUser(undefined);
    } else {
      setAlertInfo({
        open: true,
        message: t("error.unknown")
      });
    }
  };

  useEffect(() => {
    if (userList && userList.data) {
      setUserTableData({
        data: userList.data.map((row: User) => {
          return (
            <CustomizedTableRow key={row.email + row.id}>
              <CustomizedTableCell key={`user${row.id}`}>{actionButton(row)}</CustomizedTableCell>
              <CustomizedTableCell key={`user_email${row.id}`} component="th" scope="row">
                {row.email}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_name_chi${row.id}`} style={{ minWidth: "100px" }}>
                {row.name_chi}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_name_eng${row.id}`} style={{ minWidth: "150px" }}>
                {row.name_eng}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_registration${row.id}`}>
                {formatDate(new Date(row.registration_date), "yyyy-MM-dd")}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_country${row.id}`} style={{ minWidth: "150px" }}>
                {row.country}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_church${row.id}`} style={{ minWidth: "150px" }}>
                {row.church}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_job_nature${row.id}`} style={{ minWidth: "150px" }}>
                {row.job_nature}
              </CustomizedTableCell>
              <CustomizedTableCell key={`user_authority${row.id}`} style={{ minWidth: "150px" }}>
                {row.authority}
              </CustomizedTableCell>
              <CustomizedTableCell key={`referee${row.id}`}>{row.referee}</CustomizedTableCell>
              <CustomizedTableCell key={`user_status${row.id}`} style={{ minWidth: "150px" }}>
                {displayStatus(row.status, row.status_label)}
              </CustomizedTableCell>
            </CustomizedTableRow>
          );
        }),
        meta: userList.meta
      });
    }
    // console.log("userList", userList);
  }, [userList]);

  const resetAndSearchCallback = (data: UserFormFilterProps) => {
    SaveSetting({
      current_page: 1,
      feature_name: FEATURE_NAME_USER_MANAGEMENT,
      filter_value: data
    });

    const urlPara = Qs.stringify(dataConvertToUrlParameter(data), {
      arrayFormat: "repeat"
    });
    let historyPath = USER_MANAGEMENT_PATH;
    if (urlPara !== "") {
      historyPath += `?${urlPara}`;
    }
    // console.log("historyPath", historyPath);
    navigate(historyPath);
  };

  return (
    <>
      {hasViewUserPermission && (
        <UserOptionContextProvider>
          <Grid container>
            <Grid item xs={12}>
              <Card sx={{ marginRight: "10px" }}>
                <Box
                  sx={{
                    display: "flex",
                    paddingLeft: "20px",
                    paddingTop: "10px",
                    flexDirection: "row"
                  }}
                >
                  <Typography variant="h5">{t("nav.user-management")}</Typography>
                  <IconButton
                    onClick={() => {
                      setOpenEmailListDialog(true);
                    }}
                    color="primary"
                    sx={{ marginLeft: "10px" }}
                    aria-label="Email List"
                  >
                    <ContactMailIcon />
                  </IconButton>
                  <div style={{ flexGrow: 1 }} />
                </Box>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} className={styles.filterAreaSpacing}>
                      <TableFilter
                        resetAndSearchCallback={resetAndSearchCallback}
                        initValue={getFilterValue(FEATURE_NAME_USER_MANAGEMENT)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableContainer>
                        <Table aria-label="customized table">
                          <TableHead>
                            <CustomizedTableRow key="table_header_row">
                              <CustomizedTableCell key="table_header_action">
                                {t("label.action")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_email">
                                {t("registration:label.email")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_name_chi">
                                {t("registration:pi.name-chi")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_name_eng">
                                {t("registration:pi.name-eng")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_registration_at">
                                {t("user-management:label.registration-at")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_district">
                                {t("registration:pi.district")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_church">
                                {t("user-management:label.parish")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_job_nature">
                                {t("registration:pi.job-nature")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_authority">
                                {t("registration:pi.authority")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_referee">
                                {t("registration:pi.referee")}
                              </CustomizedTableCell>
                              <CustomizedTableCell key="table_header_status">
                                {t("label.status")}
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          </TableHead>
                          {userTableData && userTableData.data ? (
                            <TableBody>{userTableData.data}</TableBody>
                          ) : (
                            <TableBody>
                              <CustomizedTableRow>
                                <CustomizedTableCell colSpan={11}>
                                  <LinearProgress />
                                </CustomizedTableCell>
                              </CustomizedTableRow>
                            </TableBody>
                          )}
                          <TableFooter>
                            <CustomizedTableRow key="table_footer_row" />
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions disableSpacing>
                  {userTableData && userTableData.meta && (
                    <TablePaginator
                      key="table-paginator"
                      // handleRecordPerPageChange={localHandleRecordPerPage}
                      // handlePageChange={localLoadNewPageData}
                      recordPerPageOptions={{
                        defaultOption: userTableData.meta.record_per_page
                      }}
                      recordFrom={userTableData.meta.from}
                      recordTo={userTableData.meta.to}
                      currentPage={userTableData.meta.current_page}
                      recordTotal={userTableData.meta.total_record}
                      noOfPages={userTableData.meta.total_page}
                      featureName={FEATURE_NAME_USER_MANAGEMENT}
                      urlPath={USER_MANAGEMENT_PATH}
                      urlQuery={getFilterValueStringifyed(FEATURE_NAME_USER_MANAGEMENT)}
                    />
                  )}
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          {actionDialog && (
            <Dialog
              fullScreen={fullScreen}
              open={actionDialog.open}
              onClose={handleActionDialogClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">{t("dialog.title-warning")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t(actionDialog.message!, {
                    name: `${currentEditingUser?.email}-${currentEditingUser?.name_chi}`
                  })}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleActionDialogClose}>
                  {t("button.cancel")}
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={() => {
                    if (actionDialog && actionDialog.fetchedBy !== undefined) {
                      handleActionDialogOKClicked(actionDialog.fetchedBy);
                    }
                  }}
                >
                  {actionDialog.okButtonText}
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <EditUser
            user={editUserProps.user}
            open={editUserProps.open}
            onClose={() => {
              setEditUserProps({ open: false, user: undefined });
            }}
            submitUpdateCallback={localLoadNewPageData}
          />
          <EmailListDialog open={openEmailListDialog} setOpen={setOpenEmailListDialog} />
        </UserOptionContextProvider>
      )}
    </>
  );
};

export default UserManagement;
