import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider
} from "@mui/material/styles";
import themeJson from "style/theme";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
// import store from "./util/Store";
// import { Provider } from "react-redux";

let theme = createTheme(themeJson);
theme = responsiveFontSizes(theme);

const Loader = () => {
  return <div>loading...</div>;
};

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <Provider store={store}> */}
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
        {/* </Provider> */}
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
