import React, { useState } from "react";
import {
  Box,
  Divider,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@mui/material";
import { ResourceGroupListDto } from "res/resource/data.d";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";
import styles from "style/common.module.css";
import { Topic } from "./data.d";
import DragableResourceGroupContainer from "./sortable/dragableResourceGroupContainer";

interface ResourceListProps {
  topic: Topic;
  resourceGroupList: ResourceGroupListDto[];
  open: boolean;
  onClose: (sortedList: ResourceGroupListDto[]) => void;
}

const SelectResourceGroupList: React.FC<ResourceListProps> = (props) => {
  const { topic, open: dialogOpen, onClose, resourceGroupList } = props;
  // const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [sortedResGroupList, setSortedResGroupList] =
    useState<ResourceGroupListDto[]>(resourceGroupList);
  const { t } = useTranslation(["translation", "topic"]);

  const handleClose = () => {
    // because user click cancelled, send back the original list
    onClose(resourceGroupList);
  };

  const handleSet = () => {
    onClose(sortedResGroupList);
  };

  // const isTouchDevice = () => {
  //   if ("ontouchstart" in window) {
  //     return true;
  //   }
  //   return false;
  // };
  // const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;

  return (
    <>
      {topic && (
        <Dialog
          key="topic-info-modal-dialog"
          open={dialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              handleClose();
            }
          }}
          aria-labelledby={topic.name}
          aria-describedby={topic.name}
          scroll="paper"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="resource_group-download-multiple-file">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography color="primary" variant="h6">
                {topic.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row"
                  }}
                >
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Divider className={styles.modalDivider} />
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <Typography variant="h6" className={styles.grayStyleLevel2}>
                {topic.description}
              </Typography>
            </Box>
            {/* https://stackoverflow.com/questions/69532940/is-there-a-material-ui-component-to-search-select-tags-like-in-stackoverflow */}
            <DndProvider backend={HTML5Backend}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <DragableResourceGroupContainer
                  resGroupList={resourceGroupList}
                  onChangeResGroupList={setSortedResGroupList}
                />
              </Box>
            </DndProvider>
          </DialogContent>
          <DialogActions
            style={{
              margin: "15px",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              {t("button.cancel")}
            </Button>
            <Button
              type="submit"
              onClick={handleSet}
              variant="contained"
              style={{ marginLeft: "auto", order: 2 }}
            >
              {t("button.set")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SelectResourceGroupList;
