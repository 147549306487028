import React, { ReactNode, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  Paper,
  InputBase,
  Divider,
  Popover,
  Autocomplete,
  TextField,
  Typography,
  useMediaQuery,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { TreeSelect } from "antd";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { userHasPermissionSimple } from "util/token";
import { CREATE_EDIT_RESOURCE } from "util/const";
import { Category } from "admin/category/data.d";
import { renderWholeTreeSelectNode } from "admin/category/service";
import { ResourceSearchFormData, ResourceGroupTypeDto } from "../data.d";

export interface ResourceFilterProps {
  resetAndSearchCallback: (data: ResourceSearchFormData) => void;
  initValue?: ResourceSearchFormData;
  resourceGroupTypeList: ResourceGroupTypeDto[] | undefined;
}

const OnCancel = "onCancelled";

const ResourceFilter: React.FC<ResourceFilterProps> = (props) => {
  const { initValue, resetAndSearchCallback, resourceGroupTypeList } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation(["translation", "resource"]);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors: clearFormErrors
  } = useForm();
  const hasResourcesPermission = userHasPermissionSimple(CREATE_EDIT_RESOURCE);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [showMoreOption, setShowMoreOption] = useState<boolean>();
  const [resGroupTypeTreeSelectItemUI, setResGroupTypeTreeSelectItemUI] = useState<ReactNode>();
  const [selectedResGroupTypes, setSelectedResGroupTypes] = useState<
    number[] | null | undefined | string
  >([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    clearFormErrors();
    if (resourceGroupTypeList && resourceGroupTypeList.length > 0) {
      const rgCategory = resourceGroupTypeList?.map((rg) => {
        return { id: rg.id, name: rg.name } as Partial<Category>;
      });
      setResGroupTypeTreeSelectItemUI(renderWholeTreeSelectNode(rgCategory));
    }
  }, [resourceGroupTypeList]);

  useEffect(() => {
    const showMore = hasResourcesPermission || isMobile;
    setShowMoreOption(showMore);
  }, [isMobile]);

  const handleSearch = (formData: ResourceSearchFormData) => {
    resetAndSearchCallback(formData);
  };

  const handleStatusChange = (/* event: React.SyntheticEvent<Element, Event> */) => {
    const formValues = getValues();
    handleSearch({
      resource: formValues.resource,
      status: formValues.status,
      resource_group_type: formValues.resource_group_type
    });
    // if (!(hasResourcesPermission && isMobile)) {
    //   setAnchorEl(null);
    // }
  };

  const statusOptions = [
    { label: t("status.inactive"), id: 1, value: 0 },
    { label: t("status.active"), id: 2, value: 1 }
  ];

  const convertResGroupTypeIdToNumber = (resGroupTypeInitValue?: number[]): number[] => {
    const resGroupTypeListId: number[] = [];
    if (!resGroupTypeInitValue) {
      return [];
    }

    for (let i = 0; i < resGroupTypeInitValue.length!; i += 1) {
      const resGroupId = resGroupTypeInitValue[i];
      resGroupTypeListId.push(Number(resGroupId));
    }
    return resGroupTypeListId;
  };

  useEffect(() => {
    if (initValue && resGroupTypeTreeSelectItemUI) {
      // we check form value, because we send the form value out and don't want to init again
      const formValues = getValues();
      setValue("resource", initValue?.resource);
      if (
        formValues.status === undefined ||
        Number(formValues.status) !== Number(initValue?.status)
      ) {
        const initStatus = statusOptions.find((a) => {
          return a.value.toString() === initValue?.status?.toString();
        });
        setValue("status", initStatus?.value);
        setStatusId(initStatus?.id || 0);
      }
      if (
        formValues.resource_group_type === undefined ||
        formValues.resource_group_type !== initValue?.resource_group_type
      ) {
        // convert to number first
        const resGroupTypeListId = convertResGroupTypeIdToNumber(initValue?.resource_group_type);
        setSelectedResGroupTypes(resGroupTypeListId);
        setValue("resource_group_type", resGroupTypeListId);
        // console.log("s", resGroupTypeListId);
      }
    }
  }, [initValue, resGroupTypeTreeSelectItemUI]);
  const { ref: resourceFormHookRef, ...resourceFormHookRest } = register("resource");
  const { ref: statusFormHookRef } = register("status");

  return (
    <form ref={formEl} autoComplete="off" onSubmit={handleSubmit(handleSearch)}>
      <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", opacity: "0.7" }}>
        <InputBase
          {...resourceFormHookRest}
          inputRef={resourceFormHookRef}
          sx={{ ml: 1, flex: 1 }}
          placeholder={t("resource:label.search-resource")}
          inputProps={{ "aria-label": t("resource:label.search-resource") }}
        />
        {!isMobile && (
          <Controller
            control={control}
            name="resource_group_type"
            defaultValue={selectedResGroupTypes}
            render={(fProps) => {
              return (
                <TreeSelect
                  showSearch
                  maxTagCount={1}
                  style={{ width: "200px" }}
                  value={fProps.field.value}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                    zIndex: 9999
                  }}
                  placeholder={t("resource:place-holder.resource-group-type-as-filter")}
                  multiple
                  treeDefaultExpandAll
                  treeNodeFilterProp="displayName"
                  onChange={(value) => {
                    //   console.log("test", value);
                    fProps.field.onChange(value);
                  }}
                >
                  {resGroupTypeTreeSelectItemUI}
                </TreeSelect>
              );
            }}
          />
        )}
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <IconButton
          hidden={!showMoreOption}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          }}
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
        >
          <MoreVertIcon />
        </IconButton>
      </Paper>
      <Popover
        id="more-filter"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
      >
        {hasResourcesPermission && statusOptions && (
          <Autocomplete
            options={statusOptions}
            value={
              statusOptions.find((a) => {
                return a.id === statusId;
              }) || null
            }
            // getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option: any) => {
              return option.label;
            }}
            onChange={(event, newValue) => {
              setValue("status", newValue?.value); // value for the formValue
              setStatusId(newValue?.id!); // id for the internal state checking
              handleStatusChange();
            }}
            sx={{ minWidth: "150px", m: 1 }}
            renderInput={(params) => {
              // console.log("params", params);
              return (
                <TextField
                  {...params}
                  inputRef={statusFormHookRef}
                  label={t("label.status")}
                  variant="standard"
                />
              );
            }}
          />
        )}
        {isMobile && (
          <Box
            sx={{ width: "30em", paddingTop: "10px", paddingRight: "15px", marginBottom: "20px" }}
          >
            <Typography variant="subtitle2" style={{ marginLeft: "10px", color: "#00000099" }}>
              {t("resource:label.resource-group-type")}
            </Typography>
            <Controller
              control={control}
              name="resource_group_type"
              rules={{ required: true }}
              defaultValue={selectedResGroupTypes}
              render={(iProps) => {
                // console.log("props", props);
                return (
                  <TreeSelect
                    showSearch
                    allowClear
                    multiple
                    style={{ width: "100%" }}
                    value={iProps.field.value}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                      zIndex: 9999
                    }}
                    bordered={false}
                    placeholder={t("resource:place-holder.resource-group-type-as-filter")}
                    treeDefaultExpandAll
                    treeNodeFilterProp="displayName"
                    onChange={(value) => {
                      iProps.field.onChange(value);
                      if (value === undefined) {
                        // Set to another value so that I can identify user has click the "cross" button
                        setSelectedResGroupTypes(OnCancel);
                        setValue("resource_group_type", undefined);
                      }
                      handleStatusChange();
                    }}
                  >
                    {resGroupTypeTreeSelectItemUI}
                  </TreeSelect>
                );
              }}
            />
          </Box>
        )}
      </Popover>
    </form>
  );
};

export default ResourceFilter;
