import type { PaletteMode } from "@mui/material";

export const blackColor = "#000";
export const whiteColor = "#FFF";
export const redColor = "#FF0000";
export const actionColor = "rgba(26,193,123,1)";
export const defaultFont = {
  fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
  fontWeight: "300",
  lineHeight: "1.5em"
};
export const infoColor = ["#00acc1", "#26c6da", "#00acc1", "#00d3ee"];

const themeDefault = {
  palette: {
    mode: "light" as PaletteMode,
    primary: {
      main: "rgba(42,140,146,1)"
    },
    secondary: {
      main: "#0099f5"
    },
    info: {
      main: "rgba(108,131,152,0.8)"
    },
    error: {
      main: "#ef5350"
    }
    // general: {
    //   border: "rgba(0, 0, 0, 0.12)",
    //   fieldDefault: "rgba(0, 0, 0, 0.67)"
    // }
    // action: {
    //   main: "rgba(108,131,152,0.1)",
    // },
  },
  typography: {
    fontSize: 14
  }
  //   props: {
  //     MuiAppBar: {
  //       color: "default",
  //     },
  //   },
};

export default themeDefault;
