import { CODE_TOKEN_EXCHANGE, LOGIN_URL, SAVED_REDIRECT_PATH } from "util/const";
// RESOURCE_PATH
import { ResolveError } from "util/connUtil";
import { checkTokenIsValid, saveAuth, removeToken } from "util/token";
import { axios } from "util/request";
import { parse, stringify } from "qs";
import { NavigateFunction } from "react-router-dom";

export const fetchCodeExchange = (inputCode: string) => {
  return axios
    .post(CODE_TOKEN_EXCHANGE, {
      code: inputCode
    })
    .then((response) => {
      console.log("code exchange response", response, response.status);
      if (response.status < 200 || response.status >= 300) {
        // console.log("response.json()", response.data);
        // return response.data.then((body) => {
        //   console.log("body.data", body);
        throw new Error(ResolveError(response.data));
        // return Promise.reject(ResolveError(body.errors));
        // });
      }
      return response.data;
    })
    .then((auth) => {
      console.log("auth", auth);
      if (auth && auth.data && auth.data.access_token) {
        saveAuth(auth.data.access_token, auth.data.refresh_token, JSON.stringify(auth.data));
        return Promise.resolve(true);
      }
      localStorage.clear();
      throw new Error("unknown error");
      // return Promise.reject();
    })
    .catch((e) => {
      // console.log("fetchCodeExchange catch", e);
      throw e;
    });
};

export const fetchTokenExchange = (exchangeTokenUrl: string, inputAccessToken: string) => {
  return axios
    .post(exchangeTokenUrl, {
      accessToken: inputAccessToken
    })
    .then((response) => {
      console.log("token exchange response", response, response.status);
      if (response.status < 200 || response.status >= 300) {
        // console.log("response.json()", response.data);
        // return response.data.then((body) => {
        //   console.log("body.data", body);
        throw new Error(ResolveError(response.data));
        // return Promise.reject(ResolveError(body.errors));
        // });
      }
      return response.data;
    })
    .then((auth) => {
      // console.log("auth", auth);
      if (auth && auth.data && auth.data.access_token) {
        saveAuth(auth.data.access_token, auth.data.refresh_token, JSON.stringify(auth.data));
        return Promise.resolve(true);
      }
      localStorage.clear();
      throw new Error("unknown error");
      // return Promise.reject();
    })
    .catch((e) => {
      // console.log("4", e);
      throw e;
    });
};

export function getPageQuery() {
  return parse(window.location.href.split("?")[1]);
}

export const whereToGoAfterLogin = (navigate: NavigateFunction) => {
  const defaultPage = "auth/res/resource";
  const urlParams = new URL(window.location.href);
  let { redirect } = getPageQuery();
  // console.log("redirect 0", redirect);
  if (!redirect) {
    redirect = sessionStorage.getItem(SAVED_REDIRECT_PATH) ?? undefined;
    sessionStorage.clear();
  }
  if (redirect) {
    let redirectUrlParams: URL;
    try {
      redirectUrlParams = new URL(redirect as string);
      if (redirectUrlParams.origin === urlParams.origin) {
        redirect = (redirect as string).substring(urlParams.origin.length);
        if (redirect.match(/^\/.*#/)) {
          redirect = redirect.substring(redirect.indexOf("#") + 1);
        }
      } else {
        // console.log("redirect 1", redirect);
        window.location.href = redirect as string;
        return;
      }
      // console.log("redirect 2", redirect);
      const sdbeiSiteUrl = process.env.REACT_APP_SITE_URL || "";
      window.location.href = `${sdbeiSiteUrl}${redirect}` || "";
    } catch (error) {
      console.log(error);
      navigate(defaultPage);
    }
  } else {
    navigate(defaultPage);
  }
};

export const saveRedirectPathToSession = (redirectPath: string) => {
  if (redirectPath !== "") {
    const sdbeiSiteUrl = process.env.REACT_APP_SITE_URL || "";
    const trimmedRedirectPath = redirectPath.replace(sdbeiSiteUrl, "");
    sessionStorage.setItem(SAVED_REDIRECT_PATH, `${trimmedRedirectPath}`);
  }
};

export const keepTheLogoutPathToRedirect = (needToReturnPath: boolean) => {
  const { redirect } = getPageQuery();
  if (window.location.pathname !== "/" && !redirect) {
    saveRedirectPathToSession(window.location.href);
    const redirectUrl = stringify({ redirect: window.location.href });
    if (!needToReturnPath) {
      window.location.href = `/?${redirectUrl}`;
    } else {
      return `/?${redirectUrl}`;
    }
  }
  return "/";
};

interface AuthProviderProps {
  email: string;
  password: string;
  navigate: NavigateFunction;
  setGlobalBackdropOpen?: (open: boolean) => void;
}

const authProvider = {
  login: (props: AuthProviderProps) => {
    const { email, password, navigate, setGlobalBackdropOpen } = props;
    const request = new Request(LOGIN_URL, {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: new Headers({ "Content-Type": "application/json" })
    });
    // console.log("0", email, password, history, setBackDropOpen, props);
    return fetch(request)
      .then((response) => {
        // console.log("1");
        if (response.status < 200 || response.status >= 300) {
          return response.json().then((body) => {
            // console.log("auth fail", body);
            throw new Error(ResolveError(body));
            // return Promise.reject(ResolveError(body.errors));
          });
        }
        return response.json();
      })
      .then((auth) => {
        // console.log("auth 1", auth);
        if (auth && auth.data && auth.data.access_token) {
          saveAuth(auth.data.access_token, auth.data.refresh_token, JSON.stringify(auth.data));
          if (setGlobalBackdropOpen) {
            setGlobalBackdropOpen(false);
          }
          whereToGoAfterLogin(navigate);
          // history.push("/res/topic");
        } else {
          localStorage.clear();
          throw new Error("unknow error");
          // return Promise.reject();
        }
      })
      .catch((e) => {
        // console.log("4");
        // repeat the throw to allow others to do some UI action
        throw e;
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    console.log("remove token");
    removeToken();
    sessionStorage.clear();
    return Promise.resolve();
  },
  // called when the API returns an error
  // checkError: ({ error }: AxiosResponse) => {
  //   // console.log("checkError", error)
  //   if (error && error.status && (error.status === 401 || error.status === 403)) {
  //     console.log(error.status);
  //   }
  //   localStorage.removeItem(ACCESS_TOKEN);
  //   // return Promise.reject();
  //   return Promise.resolve();
  // },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return checkTokenIsValid();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return Promise.resolve();
  }
};

export default authProvider;
