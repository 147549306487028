import React, { ReactNode, useContext, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  Grid,
  Typography,
  DialogActions,
  Button,
  Divider,
  IconButton,
  Autocomplete,
  TextField
} from "@mui/material";
import { TreeSelect } from "antd";
import { Controller, useForm } from "react-hook-form";
import "antd/dist/antd.min.css";
import { CategoryContext } from "admin/category/categoryContext";
import { useTranslation } from "react-i18next";
import { renderWholeTreeSelectNode } from "admin/category/service";
import { TopicDto } from "res/topic/data.d";
import CloseIcon from "@mui/icons-material/Close";
import styles from "style/common.module.css";
import { AlertContext } from "component/alert/alertContext";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import { getTopicDtoByCategory } from "./service";

interface SelectTopicProps {
  open: boolean;
  onClose: (topic?: TopicDto) => void;
}

const SelectTopicDialog: React.FC<SelectTopicProps> = (props) => {
  const { open: openDialog, onClose: onDialogClose } = props;
  const { t } = useTranslation(["translation", "topic"]);
  const formEl = React.useRef<HTMLFormElement | null>(null);
  const { topicCatTreeData } = useContext(CategoryContext);
  const [topicCategoryTreeItemUI, setTopicCategoryTreeItemUI] = useState<ReactNode>();
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [topicList, setTopicList] = useState<TopicDto[]>([]);
  const [selectedTopic, setSelectedTopic] = useState<TopicDto | null>(null);

  const {
    handleSubmit,
    control,
    reset,
    resetField,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm({
    defaultValues: { topic_category: undefined, topic: null }
  });
  // const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    if (topicCatTreeData) {
      setTopicCategoryTreeItemUI(renderWholeTreeSelectNode(topicCatTreeData));
    }
  }, [topicCatTreeData]);

  const resetForm = () => {
    setTopicList([]);
    setSelectedTopic(null);
    reset();
    clearFormErrors();
  };

  const handleClose = () => {
    resetForm();
    onDialogClose();
  };

  const fetchFindTopics = (categoryId: number | undefined) => {
    const formValues = getValues();
    if (categoryId && formValues.topic_category !== categoryId) {
      setLoadingBackdrop(true);
      getTopicDtoByCategory(categoryId)
        .then((response: AxiosResponse) => {
          if (response && response.status === 200 && response.data && response.data.data) {
            resetField("topic");
            setSelectedTopic(null);
            clearFormErrors();
            setTopicList(response.data.data);
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: error.message
            });
        })
        .finally(() => {
          setLoadingBackdrop(false);
        });
    } else if (formValues.topic_category !== categoryId) {
      reset();
    }
  };

  const handleAddTopic = (formData: { topic_cateogry: number; topic: TopicDto }) => {
    resetForm();
    onDialogClose(formData.topic);
  };

  return (
    <Dialog
      key="assign-topic-modal-dialog"
      keepMounted
      open={openDialog}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography
              key="create-resource-group-modal-title"
              id="create-resource-group-modal-title"
              variant="h6"
              component="h2"
            >
              {t("resource:label.resource-assign-to-topic")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon color="action" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider className={styles.modalDivider} />
      </DialogTitle>
      <form ref={formEl} autoComplete="off" onSubmit={handleSubmit(handleAddTopic)}>
        <DialogContent>
          {/* <DialogContentText>{t("topic:message.how-to-select-topic")}</DialogContentText> */}
          <Grid container>
            <Grid item xs={12}>
              {topicCategoryTreeItemUI && (
                <>
                  <Typography variant="body1">{t("topic:label.category")}</Typography>
                  <Controller
                    control={control}
                    name="topic_category"
                    render={(fProps) => {
                      return (
                        <TreeSelect
                          showSearch
                          allowClear
                          style={{ width: "100%" }}
                          value={fProps.field.value}
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                            zIndex: 9999
                          }}
                          bordered={false}
                          placeholder={t("topic:place-holder.how-to-select-topic")}
                          treeDefaultExpandAll
                          treeNodeFilterProp="displayName"
                          onChange={(value) => {
                            fetchFindTopics(value);
                            fProps.field.onChange(value);
                          }}
                        >
                          {topicCategoryTreeItemUI}
                        </TreeSelect>
                      );
                    }}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <>
                <Controller
                  control={control}
                  name="topic"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Autocomplete
                        noOptionsText={t("topic:message.no-topics")}
                        options={topicList}
                        // cannot set value directly
                        // https://stackoverflow.com/questions/63295924/a-component-is-changing-an-uncontrolled-autocomplete-to-be-controlled
                        value={value}
                        getOptionLabel={(option: any) => {
                          return option.name;
                        }}
                        onChange={(event, newValue) => {
                          onChange(newValue);
                          console.log("newValue", newValue);
                          setSelectedTopic(newValue);
                        }}
                        renderInput={(params) => {
                          return (
                            <>
                              <TextField
                                {...params}
                                label={t("topic:label.select-a-topic")}
                                variant="standard"
                                error={!!formErrors.topic}
                                helperText={
                                  !!formErrors.topic && t("topic:errors.topic-is-required")
                                }
                              />
                            </>
                          );
                        }}
                      />
                    );
                  }}
                />
                <Typography variant="subtitle2" color="primary">
                  {selectedTopic && selectedTopic.description}
                </Typography>
              </>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            margin: "15px",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Button onClick={handleClose} variant="outlined">
            {t("button.cancel")}
          </Button>
          <Button type="submit" variant="contained" style={{ marginLeft: "auto", order: 2 }}>
            {t("button.add")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SelectTopicDialog;
