import { axios } from "util/request";
import { ErrorType } from "util/helper";
import type { ResourceGroupFormData } from "./data.d";

export const fetchCreateResourceGroup = (resource: ResourceGroupFormData) => {
  const url = "/resource";
  return axios.post(url, resource);
};

export const fetchUpdateResourceGroup = (resource: ResourceGroupFormData) => {
  const url = `/resource/${resource.id!}`;
  return axios.put(url, resource);
};

export const getResourceGroupTypeList = () => {
  const url = "/resource/group/type/tree/list";
  return axios.get(url);
};

export const nameHelperText = (nameError: ErrorType, t: (params: string | string[]) => string) => {
  if (nameError?.type === "required") {
    return t("resource:errors.name-is-required");
  }
  if (nameError?.type === "maxLength") {
    return t("resource:errors.name-length");
  }
  return "";
};

export const getResourceGroupByTopic = (topic_id: number) => {
  const url = `/resource/group/${topic_id}/topic`;
  return axios.get(url);
};

export const getResourceGroupByTopicWithoutResource = (topic_id: number) => {
  const url = `/resource/group/${topic_id}/topic/noresource`;
  return axios.get(url);
};

export const getTopicDtoByResGroup = (resGroupId: number) => {
  const url = `/topic/list/${resGroupId}/resource/group`;
  return axios.get(url);
};
