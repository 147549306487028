import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const CustomizedRegistrationPaper = styled(Paper)(({ theme }) => {
  return {
    margin: theme.spacing(4, 16),
    padding: theme.spacing(2, 1),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 1)
    },
    display: "flex",
    flexDirection: "column"
  };
});

export default CustomizedRegistrationPaper;
