import {
  SAVED_RECORD_PER_PAGE,
  SAVED_CURRENT_PAGE,
  SAVED_FILTER_VALUE,
  FEATURE_NAME_USER_MANAGEMENT,
  DEFAULT_RECORD_PER_PAGE_OPTION
} from "util/const";
import Qs from "qs";

export interface PageSetting {
  record_per_page?: number;
  current_page?: number;
  filter_value?: any;
  feature_name: string;
}

const getFeatureStorageKey = (featureName: string, key: string) => {
  return `${featureName}_${key}`;
};

export const SaveSetting = (pageSetting: Partial<PageSetting>) => {
  if (pageSetting.record_per_page !== undefined && pageSetting.feature_name !== undefined) {
    localStorage.setItem(
      getFeatureStorageKey(pageSetting.feature_name, SAVED_RECORD_PER_PAGE),
      pageSetting.record_per_page.toString()
    );
  }
  if (pageSetting.current_page !== undefined && pageSetting.feature_name !== undefined) {
    localStorage.setItem(
      getFeatureStorageKey(pageSetting.feature_name, SAVED_CURRENT_PAGE),
      pageSetting.current_page.toString()
    );
  }
  if (pageSetting.filter_value !== undefined && pageSetting.feature_name !== undefined) {
    localStorage.setItem(
      getFeatureStorageKey(pageSetting.feature_name, SAVED_FILTER_VALUE),
      Qs.stringify(pageSetting.filter_value, { arrayFormat: "repeat" })
    );
  }
};

export const ClearAllPageSetting = () => {
  localStorage.removeItem(
    getFeatureStorageKey(FEATURE_NAME_USER_MANAGEMENT, SAVED_RECORD_PER_PAGE)
  );
  localStorage.removeItem(getFeatureStorageKey(FEATURE_NAME_USER_MANAGEMENT, SAVED_CURRENT_PAGE));
};

export const GetSavedSetting = (featureName: string): PageSetting => {
  const recordPerPage = localStorage.getItem(
    getFeatureStorageKey(featureName, SAVED_RECORD_PER_PAGE)
  );
  const currentPage = localStorage.getItem(getFeatureStorageKey(featureName, SAVED_CURRENT_PAGE));
  const filterValue = localStorage.getItem(getFeatureStorageKey(featureName, SAVED_FILTER_VALUE));
  const pageSetting = {
    record_per_page: recordPerPage
      ? Number(recordPerPage)
      : DEFAULT_RECORD_PER_PAGE_OPTION.defaultOption,
    current_page: currentPage ? Number(currentPage) : 1,
    filter_value: Qs.parse(filterValue ?? ""),
    feature_name: featureName
  };
  return pageSetting;
};
