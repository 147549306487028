import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { Outlet, useLocation } from "react-router-dom";
// , Navigate, useLocation
import { DRAWER_WIDTH as drawerWidth } from "util/const";
import { useTranslation } from "react-i18next";
// import routes from "admin/route";
// import PrivateRoute from "util/privateRoute";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import Copyright from "noauth/component/copyright";
import { LayoutContext } from "./layoutContext";
// import ContentBackground from "../../public/page-content-background.jpg";
// import { CustomizedDrawerHeaderDiv } from "./myDrawer";

// function RequireAuth({ children }: { children: any }) {
//   const location = useLocation();

//   if (!authProvider.checkAuth()) {
//     // Redirect them to the /login page, but save the current location they were
//     // trying to go to when they were redirected. This allows us to send them
//     // along to that page after they login, which is a nicer user experience
//     // than dropping them off on the home page.
//     return <Navigate to="/login" state={{ from: location }} />;
//   }

//   return children;
// }

// const switchRoutes = (
//   <Routes>
//     {routes.map((prop) => {
//       if (prop.layout === "/admin" || prop.layout === "/res") {
//         return (
//           <Route
//             path={prop.layout + prop.path}
//             element={
//               <RequireAuth>
//                 <prop.element />
//               </RequireAuth>
//             }
//             key={prop.name}
//           />
//           // <PrivateRoute
//           //   {...prop}
//           //   routes={routes}
//           //   path={prop.layout + prop.path}
//           //   // component={prop.component}
//           //   key={prop.name}
//           // />
//         );
//       }
//       return null;
//     })}
//     {/* <Route path="/admin" element={<Dashboard />} key="AdminRedirectToDashboard" /> */}
//   </Routes>
// );

const PREFIX = "Content";
const classes = {
  content: `${PREFIX}-content`,
  contentShift: `${PREFIX}-contentShift`
};

export const CustomizedMain = styled("main")(({ theme }) => {
  return {
    [`&.${classes.content}`]: {
      flexGrow: 1,
      minHeight: "500px",
      paddingTop: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundImage: `url(${process.env.PUBLIC_URL}/page-content-background.jpg)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "top 100px left 30px",
      marginLeft: -drawerWidth
    },
    [`&.${classes.contentShift}`]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    }
  };
});

const Content = () => {
  const { open } = useContext(LayoutContext);
  const { t } = useTranslation();
  const location = useLocation();
  const [browserTitle, setBrowserTitle] = useState<string>("");
  const titleMapping: any = {
    "/auth/admin/dashboard": t("nav.dashboard"),
    "/auth/admin/user": t("nav.user-management"),
    "/auth/res/topic": t("nav.topic"),
    "/auth/res/resource": t("nav.resource"),
    "/auth/gallery": t("nav.gallery")
  };

  useEffect(() => {
    const name = t("site.name");
    if (location.pathname !== "") {
      setBrowserTitle(titleMapping[`${location.pathname}`]);
    } else {
      setBrowserTitle(name);
    }
    // console.log("location", location.pathname);
  }, [location]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{browserTitle}</title>
          <meta name="description" content={browserTitle} />
        </Helmet>
        <CustomizedMain
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <Outlet />
          <div style={{ marginTop: "40px", marginBottom: "20px" }}>
            <Copyright />
          </div>
        </CustomizedMain>
      </HelmetProvider>
    </>
  );
};

export default Content;
