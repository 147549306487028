import React, { useEffect, useState, useContext } from "react";

import { Stack, Chip, Button } from "@mui/material";
import { findCatNameStringOnlyWithParentById } from "admin/category/service";
import { CategoryContext } from "admin/category/categoryContext";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { getStatusLabel } from "util/helper";
import {
  CRITERIA_TYPE_TOPIC,
  CRITERIA_TYPE_CATEGORY,
  CRITERIA_TYPE_STATUS,
  TopicSearchFormData
} from "../data.d";

interface SearchCriteriaListProps {
  removeCriteriaByType: (type: string) => void;
  filterData: any;
}

const SearchCriteriaList: React.FC<SearchCriteriaListProps> = (props) => {
  const { filterData, removeCriteriaByType } = props;
  const { fullCatTreeData } = useContext(CategoryContext);
  const [searchCriteria, setSearchCriteria] = useState<React.ReactNode | undefined>(undefined);
  const { t } = useTranslation(["translation", "topic"]);

  const searchCriteriaDisplay = (data: TopicSearchFormData): React.ReactNode => {
    let topic = <></>;
    let category = <></>;
    let status = <></>;
    // console.log("data", data);
    if (data) {
      if (data.topic) {
        topic = (
          <Chip
            label={`${t("topic:label.topic")}: ${data.topic}`}
            onDelete={() => {
              props.removeCriteriaByType(CRITERIA_TYPE_TOPIC);
            }}
          />
        );
      }
      if (data && data?.status && data?.status >= 0) {
        status = (
          <Chip
            label={`${t("label.status")}: ${getStatusLabel(Number(data?.status), t)}`}
            onDelete={() => {
              props.removeCriteriaByType(CRITERIA_TYPE_STATUS);
            }}
          />
        );
      }
      if (data.category) {
        const catName = findCatNameStringOnlyWithParentById(
          fullCatTreeData.data,
          Number(data.category)
        );
        // console.log("cat", catName, data.category);
        const catChipLabel = (
          <>
            {`${t("topic:label.category")}: `}
            {catName}
          </>
        );
        category = (
          <Chip
            label={catChipLabel}
            onDelete={() => {
              return removeCriteriaByType(CRITERIA_TYPE_CATEGORY);
            }}
          />
        );
      }
      if (data.topic || data.category || data.status) {
        return (
          <Stack
            direction="row"
            style={{ marginTop: "5px" }}
            spacing={{ xs: 0, md: 1 }}
            sx={{ display: { xs: "block" } }}
          >
            {topic}
            {category}
            {status}
            <Button
              color="primary"
              endIcon={<ClearIcon />}
              onClick={() => {
                return removeCriteriaByType("");
              }}
            >
              {t("button.clear-all")}
            </Button>
          </Stack>
        );
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (fullCatTreeData) {
      setSearchCriteria(searchCriteriaDisplay(filterData));
    }
  }, [filterData, fullCatTreeData]);

  return <>{searchCriteria && searchCriteria}</>;
};

export default SearchCriteriaList;
