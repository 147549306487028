import React, { useState, useContext, useEffect } from "react";
import {
  Divider,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from "@mui/material";
import styles from "style/common.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { AlertContext } from "component/alert/alertContext";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
// import { MyFileUpload } from "component/upload/file";
import DropZoneFileUpload, { FileUploaded } from "component/upload/dropZoneFileUpload";
import { fetchCreateGallery, getSimpleGalleryByCategory } from "gallery/service";
import { FileStatusError, FileStatusUploading } from "component/upload/file";
import ResolveError from "util/connUtil";
import ComfirmationDialog from "component/alert/confirmationDialog";

// const { Mutex } = require("async-mutex");

interface UploadPictureDialogProps {
  galleryCatId: number;
  galleryCatName: string;
  open: boolean;
  onClose: (refresh: boolean) => void;
}

const UploadPictureDialog: React.FC<UploadPictureDialogProps> = (props) => {
  const { galleryCatId, open: dialogOpen, onClose, galleryCatName } = props;
  const [imageFiles, setImageFiles] = useState<FileUploaded[]>([]);
  const [openIncompeleteUpdateWarning, setOpenIncompeleteUpdateWarning] = useState<boolean>(false);
  const [initialImagesList, setInitialImagesList] = useState<FileUploaded[]>([]);
  const { setAlertInfo, setSuccessInfo, setDialogInfo, setLoadingBackdrop } =
    useContext(AlertContext);
  const { t } = useTranslation(["translation", "gallery"]);
  // const [fileResult, setFileResult] = useState<FileUploadResult[]>([]);
  // const [fileList, setFileList] = useState<MyFileUpload[]>([]);
  // const mutex = useRef(new Mutex());

  const validateIfAllImageHasBeenUploaded = (): string => {
    for (let i = 0; i < imageFiles.length; i += 1) {
      // console.log("fileList[i].status", fileList[i].status);
      if (!imageFiles[i].thumbnail_file) {
        if (imageFiles[i].status === FileStatusError) {
          return FileStatusError;
        }
        if (imageFiles[i].status === FileStatusUploading) {
          return FileStatusUploading;
        }
      }
      // if (imageFiles[i].status === FileStatusError) {
      //   return FileStatusError;
      // }
    }
    return "";
  };

  const hasNewUpload = () => {
    if (initialImagesList.length !== imageFiles.length && imageFiles.length > 0) {
      return true;
    }
    return false;
  };

  const closeDialogOnly = () => {
    onClose(false);
    setInitialImagesList([]);
    setImageFiles([]);
  };

  const userConfirmToCloseDialog = () => {
    setOpenIncompeleteUpdateWarning(false);
    closeDialogOnly();
  };

  const handleClose = (refresh: boolean) => {
    // !refresh means close dialog only
    if (!refresh && hasNewUpload()) {
      setOpenIncompeleteUpdateWarning(true);
    } else {
      onClose(refresh);
      setInitialImagesList([]);
    }
  };

  const resetForm = () => {
    setImageFiles([]);
  };

  const saveToDb = () => {
    const submitFunc = fetchCreateGallery;
    // console.log("formData", formData.files);
    const submitData = { category_id: galleryCatId, files: imageFiles };
    const validation = validateIfAllImageHasBeenUploaded();
    if (validation !== "") {
      setDialogInfo({
        open: true,
        title: t("dialog.title-warning"),
        content:
          validation === FileStatusError
            ? t("gallery:dialog.file-need-to-remove")
            : t("gallery:dialog.image-is-uploading"),
        onOk: () => {}
      });
      return;
    }
    setLoadingBackdrop(true);
    submitFunc(submitData)
      .then((response: AxiosResponse) => {
        if (response.status === 201 || response.status === 200) {
          setSuccessInfo({ open: true, message: response.data.message });
          handleClose(true);
          resetForm();
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const loadAllImages = () => {
    setLoadingBackdrop(true);
    getSimpleGalleryByCategory(galleryCatId)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setInitialImagesList(response.data.data);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  useEffect(() => {
    if (galleryCatId > 0 && dialogOpen) {
      loadAllImages();
    }
  }, [galleryCatId, dialogOpen]);

  return (
    <>
      <Dialog
        key="upload-picture-modal-dialog"
        open={dialogOpen}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(false);
          }
        }}
        aria-labelledby={t("gallery:label.manage-picture")}
        aria-describedby={t("gallery:label.manage-picture")}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="upload-picture-dialog-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                key="upload-picture-modal-title"
                id="upload-picture-modal-title"
                variant="h6"
                component="h2"
              >
                {t("gallery:dialog.title", { name: galleryCatName })}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleClose(false);
                }}
              >
                <CloseIcon color="action" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={styles.modalDivider} />
        </DialogTitle>
        <DialogContent style={{ minHeight: "600px" }}>
          <Grid container>
            <Grid item xs={12}>
              <DropZoneFileUpload
                setCallbackImageList={setImageFiles}
                initialImages={initialImagesList}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            margin: "15px",
            display: "flex",
            flexDirection: "row"
          }}
        >
          <Button
            onClick={() => {
              handleClose(false);
            }}
            variant="outlined"
          >
            {t("button.cancel")}
          </Button>
          <Button
            disabled={initialImagesList.length === 0 && imageFiles.length === 0}
            type="submit"
            onClick={() => {
              saveToDb();
            }}
            variant="contained"
            style={{ marginLeft: "auto", order: 2 }}
          >
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <ComfirmationDialog
        open={openIncompeleteUpdateWarning}
        messageToDisplay={t("gallery:dialog.close-dialog-warning")}
        setOpen={setOpenIncompeleteUpdateWarning}
        okAction={userConfirmToCloseDialog}
      />
    </>
  );
};

export default UploadPictureDialog;
