import React, { useContext, useEffect, useState } from "react";
import { Drawer, Divider, List, ListItemButton, ListItemText } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { DRAWER_WIDTH as drawerWidth } from "util/const";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getScopes, userHasPerission } from "util/token";
import routes from "admin/route";
import { hexToRgb } from "util/helper";
import { blackColor, defaultFont, infoColor, whiteColor } from "style/theme";
import clsx from "clsx";
import classNames from "classnames";
import { SaveSetting } from "component/table/paginatorSetting";
import { LayoutContext } from "./layoutContext";
// import { SaveSetting } from "component/table/paginatorSetting";
// import styles from "./myDrawer.module.css";

const PREFIX = "MyDrawer";

const classes = {
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  list: `${PREFIX}-list`,
  item: `${PREFIX}-item`,
  itemText: `${PREFIX}-itemText`,
  itemLink: `${PREFIX}-itemLink`,
  itemIcon: `${PREFIX}-itemIcon`,
  active: `${PREFIX}-active`,
  whiteFont: `${PREFIX}-whiteFont`
};

export const CustomizedDrawerHeaderDiv = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  };
});

const CustomizedDrawer = styled(Drawer)(() => {
  return {
    [`&.${classes.drawer}`]: {
      width: drawerWidth,
      flexShrink: 0
    },
    [`& .${classes.drawerPaper}`]: {
      width: drawerWidth
    },
    [`& .${classes.list}`]: {
      marginTop: "20px",
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 0,
      listStyle: "none",
      position: "unset"
    },
    [`& .${classes.item}`]: {
      position: "relative",
      display: "block",
      textDecoration: "none",
      "&:hover,&:focus,&:visited,&": {
        color: blackColor
      }
    },
    [`& .${classes.itemIcon}`]: {
      width: "24px",
      height: "30px",
      fontSize: "24px",
      lineHeight: "30px",
      float: "left",
      marginRight: "15px",
      textAlign: "center",
      verticalAlign: "middle",
      color: `rgba(${hexToRgb(blackColor)}, 0.8)`
    },
    [`& .${classes.itemLink}`]: {
      width: "auto",
      transition: "all 300ms linear",
      // margin: "10px 15px 0",
      borderRadius: "3px",
      position: "relative",
      display: "block",
      ...defaultFont
    },
    [`& .${classes.itemText}`]: {
      ...defaultFont,
      lineHeight: "30px",
      fontSize: "14px",
      color: blackColor,
      marginTop: 0,
      textDecoration: "none"
    },
    [`& .${classes.active}`]: {
      backgroundColor: infoColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        infoColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
        infoColor[0]
      )},.2)`,
      "&:hover,&:focus": {
        backgroundColor: infoColor[0],
        boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
          infoColor[0]
        )},.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)},.12), 0 7px 8px -5px rgba(${hexToRgb(
          infoColor[0]
        )},.2)`
      }
    },
    [`& .${classes.whiteFont}`]: {
      color: whiteColor
    }
  };
});

// const activeRoute = (routeName: string) => {
//   return window.location.href.indexOf(routeName) > -1;
// };

const MyDrawer = () => {
  const { open, setOpen } = useContext(LayoutContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const scopes = getScopes();
  const location = useLocation();
  // console.log(listItem);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [listItem, setListItem] = useState<any>();

  const activeLocation = (path: string) => {
    return location && location.pathname && location.pathname.indexOf(path) >= 0;
  };

  useEffect(() => {
    if (location) {
      const displayListItem = routes.map((menuItem) => {
        const fullPath = menuItem.layout + menuItem.path;
        const listItemClasses = classNames({
          [` ${classes.active}`]: activeLocation(fullPath)
        });
        // console.log("menuItem", menuItem);
        const hasPermission = userHasPerission(scopes, menuItem.scope);
        return (
          <div key={`NavParent${menuItem.name}`}>
            {hasPermission ? (
              <NavLink
                to={fullPath}
                // className={({ isActive }) => {
                //   return `${isActive ? classes.active : classes.item}`;
                // }}
                style={{ textDecoration: "none" }}
                // style={({ isActive }) => {
                //   return {
                //     color: isActive ? "#000" : "#545e6f",
                //     background: isActive ? "#7600dc" : "#f0f0f0"
                //   };
                // }}
                key={`NavLink${menuItem.name}`}
              >
                <ListItemButton
                  onClick={() => {
                    menuItem.feature_name &&
                      SaveSetting({
                        current_page: 1,
                        feature_name: menuItem.feature_name
                      });
                  }}
                  key={`ListItem${menuItem.name}`}
                  className={classNames(classes.itemLink + listItemClasses)}
                >
                  <menuItem.icon
                    key={`menuItem.icon${menuItem.name}`}
                    className={clsx(classes.itemIcon, {
                      [classes.whiteFont]: activeLocation(fullPath)
                    })}
                  />
                  <ListItemText
                    key={`ListItemText${menuItem.name}`}
                    primary={t(menuItem.name)}
                    className={clsx(classes.itemText, {
                      [classes.whiteFont]: activeLocation(fullPath)
                    })}
                    disableTypography
                  />
                </ListItemButton>
              </NavLink>
            ) : (
              <div key={`NavLinkEmpty${menuItem.name}`} />
            )}
          </div>
        );
      });
      setListItem(displayListItem);
    }
  }, [location]);

  return (
    <CustomizedDrawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <CustomizedDrawerHeaderDiv>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </CustomizedDrawerHeaderDiv>
      <Divider />
      <List className={classes.list}>{listItem}</List>
      <Divider />
    </CustomizedDrawer>
  );
};

export default MyDrawer;
