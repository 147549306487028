import { axios } from "util/request";
import { saveAs } from "file-saver";

const DOWNLOAD_TIMEOUT = 6000000; // 100 minutes
// eslint-disable-next-line import/prefer-default-export
export const fetchReserveTempFile = (fileUid: string, fileName: string, md5: string) => {
  const url = `/file/reserve`;
  return axios.post(url, { file_uid: fileUid, file_name: fileName, md5 });
};

export const downloadResourceFile = (resId: string) => {
  const url = `/resource/file/${resId}`;
  return axios.get(url, { responseType: "blob", timeout: DOWNLOAD_TIMEOUT });
};

export const saveAndDownloadFile = (
  resId: string,
  defaultFilename: string,
  setLoadingBackdrop?: (input: boolean) => void
): Promise<any> => {
  setLoadingBackdrop?.(true);
  return (
    downloadResourceFile(resId)
      .then((res) => {
        // console.log("res", res);
        // const { data, headers, status } = res;
        const { data, status } = res;
        if (status === 200) {
          // let filename = headers["content-disposition"];
          // if (filename && filename !== "") {
          //   filename = filename.substring(filename.indexOf("=") + 1, filename.length);
          // }
          saveAs(data, defaultFilename);
          return Promise.resolve();
        }
        return Promise.reject(res);
      })
      // .catch((error) => {
      //   console.log("error", error);
      //   // return Promise.reject(error);
      // })
      .finally(() => {
        setLoadingBackdrop?.(false);
      })
  );
};

export const getImageSrc = async (path: string) => {
  const res = await axios.get(path, { responseType: "arraybuffer", timeout: DOWNLOAD_TIMEOUT });
  return res;
};
