import { Attachment, Description, Person, Image } from "@mui/icons-material";
// import Dashboard from "@mui/icons-material/Dashboard";
// import Attachment from "@mui/icons-material/Attachment";
// import Person from "@mui/icons-material/Person";
// import Description from "@mui/icons-material/Description";
// import {
//   FEATURE_NAME_USER_MANAGEMENT,
//   FEATURE_NAME_TOPIC,
//   FEATURE_NAME_RESOURCE
// } from "util/const";
// import Content1 from "admin/content1";
import Gallery from "gallery";
import ResResourceGroup from "res/resource";
import TopicWithCategoryProvider from "res/topic/indexWithCategoryProvider";
import {
  FEATURE_NAME_RESOURCE,
  FEATURE_NAME_TOPIC,
  FEATURE_NAME_USER_MANAGEMENT,
  FEATURE_NAME_GALLERY
} from "util/const";
import UserManagement from "./user";
// import TopicWithCategoryProvider from "../res/topic/IndexWithCategoryProvider";
// import ResResourceGroup from "../res/resource/Index";
// import UserManagement from "../admin/user/Index";

// We use Component in capital letter,
// because there is a props in Route called component
const adminRoutes = [
  // {
  //   path: "/dashboard",
  //   element: Content1,
  //   name: "nav.dashboard",
  //   icon: Dashboard,
  //   layout: "/auth/admin",
  //   feature_name: "",
  //   scope: ["view_user_information"]
  // },
  {
    path: "/resource",
    name: "nav.resource",
    icon: Attachment,
    Component: ResResourceGroup,
    layout: "/auth/res",
    feature_name: FEATURE_NAME_RESOURCE,
    scope: []
  },
  {
    path: "/topic",
    name: "nav.topic",
    icon: Description,
    Component: TopicWithCategoryProvider,
    layout: "/auth/res",
    feature_name: FEATURE_NAME_TOPIC,
    scope: []
  },
  {
    path: "/gallery",
    name: "nav.gallery",
    icon: Image,
    Component: Gallery,
    layout: "/auth",
    feature_name: FEATURE_NAME_GALLERY,
    scope: []
  },
  {
    path: "/user",
    name: "nav.user-management",
    icon: Person,
    Component: UserManagement,
    layout: "/auth/admin",
    feature_name: FEATURE_NAME_USER_MANAGEMENT,
    scope: ["view_user_information", "edit_user_information"]
  }
];

export default adminRoutes;
