import queryString from "query-string";
import { Location } from "react-router-dom";
import { SaveSetting, GetSavedSetting } from "./paginatorSetting";

export interface loadDataByURLProps {
  iLocation: any;
  featureName: string;
  fnrExecuteFetchList: () => void;
}

export interface PushPopHistoryActionProps {
  location: Location;
  featureName: string;
  featurePath: string;
  fnrExecuteFetchList: () => void;
}

export const LoadDataByCheckingUrl = (paras: loadDataByURLProps) => {
  const { iLocation, featureName, fnrExecuteFetchList } = paras;
  const pageSetting = GetSavedSetting(paras.featureName);
  let currentPage = pageSetting.current_page;
  let recordPerPage = pageSetting.record_per_page;
  const urlQuery = queryString.parse(iLocation.search);
  const preparedData = { ...urlQuery };
  if (urlQuery) {
    if (urlQuery.current_page) {
      currentPage = Number(urlQuery.current_page);
      recordPerPage = Number(urlQuery.record_per_page);
    } else {
      currentPage = 1;
    }
  }
  if (preparedData && preparedData.current_page) {
    delete preparedData.current_page;
  }
  if (preparedData && preparedData.record_per_page) {
    delete preparedData.record_per_page;
  }
  SaveSetting({
    current_page: currentPage,
    record_per_page: recordPerPage,
    feature_name: featureName,
    filter_value: preparedData
  });
  fnrExecuteFetchList();
};

export const MonitorLocationUpdateAction = (params: PushPopHistoryActionProps) => {
  const { location, featureName, featurePath, fnrExecuteFetchList } = params;
  // push pop means either forward or backward and we are in a listener
  // console.log("useEffect 2.1", location, history.action);
  if (location && location.pathname && location.pathname.indexOf(featurePath) >= 0) {
    // console.log("useEffect 2.2", history.action);
    LoadDataByCheckingUrl({
      iLocation: location,
      featureName,
      fnrExecuteFetchList
    });
  }
};

// export const RegisterPushPopHistoryAction = (params: PushPopHistoryActionProps) => {
//   const { history, featureName, featurePath, fnrExecuteFetchList } = params;
//   return history.listen((location: any) => {
//     // console.log("useEffect 2.3", location, history.action);
//     if (history.action === "PUSH" || history.action === "POP") {
//       // push pop means either forward or backward and we are in a listener
//       // console.log("useEffect 2.1", location, history.action);
//       if (location && location.pathname && location.pathname.indexOf(featurePath) >= 0) {
//         // console.log("useEffect 2.2", history.action);
//         LoadDataByCheckingUrl({
//           iLocation: location,
//           featureName,
//           fnrExecuteFetchList
//         });
//       }
//     }
//   });
// };
