import React from "react";
// import { CategoryContextProvider } from "../../admin/category/CategoryContext";
import ResTopic from "./index";

const TopicWithCategoryProvider = () => {
  return (
    // finally move the category context provider to AdminLayout
    // <CategoryContextProvider>
    <ResTopic />
    // </CategoryContextProvider>
  );
};

export default TopicWithCategoryProvider;
