import React, { useContext, useEffect, useState, useCallback, ReactElement } from "react";
import { Upload } from "antd";
import type {
  UploadRequestOption as RcCustomRequestOptions,
  RcFile
} from "rc-upload/lib/interface";
import "antd/dist/antd.min.css";
import { Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ResolveError from "util/connUtil";
import { useTranslation } from "react-i18next";
import { AlertContext } from "component/alert/alertContext";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { DndProvider } from "react-dnd";
import { UploadChangeParam, UploadFile, UploadListType } from "antd/lib/upload/interface";
import FileToUpload from "./file";
import type { MyFileUpload } from "./file";
import { saveAndDownloadFile, fetchReserveTempFile } from "./service";
import DragableUploadListItem from "./dragableUploadListItem";

export const LayoutList = "list";
export const LayoutPicture = "picture";

const { Dragger } = Upload;

interface ChunkFileUploadProps {
  acceptFileType: string;
  fileResult: FileUploadResult[];
  setFileResult: (files: FileUploadResult[]) => void;
  fileList: MyFileUpload[];
  onChangeFileList: (files: MyFileUpload[]) => void;
  layout?: string;
  // ref: React.Ref<ExposeFileAction | undefined>;
}

// interface ChunkFileUploadForwardHandler {}
export interface FileUploadResult {
  file_uid: string;
  uploaded_filename: string;
}

const ChunkFileUpload: React.FC<ChunkFileUploadProps> = (props) => {
  const { onChangeFileList, fileResult, setFileResult, fileList, acceptFileType, layout } = props;
  const { t } = useTranslation(["translation", "resource"]);
  const { setAlertInfo, setDialogInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [fuList, setFuList] = useState<FileToUpload[]>([]);

  // const [fileResultSt, setFileResultSt] = useState<FileUploadResult[]>([]);
  // const [formFileList, setFormFileList] = useState<MyFileUpload[]>([]);

  useEffect(() => {
    if (fileList && fileList.length === 0) {
      setFuList([]);
    }
  }, [fileList]);

  const uploadSingleFile = async (options: RcCustomRequestOptions) => {
    const { file, onError } = options;
    const typedFile = file as RcFile;
    const fu = new FileToUpload(typedFile, typedFile.name);
    const newFuList = [...fuList];
    newFuList.push(fu);
    setFuList(newFuList);
    // console.log("fuList pushed", newFuList);
    // Put the fu to fileList
    // setFtuToFile(typedFile.uid, fu);
    await fu.init();
    fetchReserveTempFile(typedFile.uid, typedFile.name, fu.fileMd5)
      .then(async (response) => {
        if (response) {
          if (response.data && response.data?.data?.file_name) {
            // upload now
            // console.log("upload now", response.data.data.file_path);
            // need to set the filename first because we will call separately for the upload
            fu.sessionFile = response.data.data.file_name;
            await fu.uploadFile(options);
            if (fu.result.success) {
              const fR: FileUploadResult = {
                file_uid: typedFile.uid,
                uploaded_filename: response.data?.data?.file_name
              };
              // eslint-disable-next-line react/prop-types
              fileResult.push(fR);
              // console.log("pushed", fileResult);
              setFileResult([...fileResult]);
            }
          } else {
            setAlertInfo({ open: true, message: ResolveError(response.data) });
            onError?.(response.data);
          }
        }
      })
      .catch((error) => {
        onError?.(error);
        // console.log("error", error);
        let errorMessage = error;
        if (errorMessage instanceof Error) {
          errorMessage = errorMessage.message;
        }
        if (errorMessage !== "File Removed") {
          setAlertInfo({
            open: true,
            message: errorMessage
          });
        }
      });
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      onChangeFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        })
      );
    },
    [fileList]
  );

  const onFileChange = (info: UploadChangeParam<UploadFile>) => {
    const { fileList: newFileList } = info;
    // console.log("onfilechange", newFileList);
    onChangeFileList(newFileList as MyFileUpload[]);
  };

  const removeFile = (file: MyFileUpload) => {
    const gotFu = fuList.find((fu) => {
      return fu.file.uid === file.uid;
    });
    if (gotFu) {
      // this can break the chunk file upload immediately
      gotFu.fileRemoved = true;
    }
    for (let i = 0; i < fileList.length; i += 1) {
      if (fileList[i].uid === file.uid) {
        const newFileList = [...fileList];
        newFileList.splice(i, 1);
        onChangeFileList(newFileList as MyFileUpload[]);
      }
    }
  };

  const downloadFile = (file: MyFileUpload) => {
    if (Number(file.uid).toString() === file.uid) {
      // console.log("file", file);
      saveAndDownloadFile(file.uid, file.fileName || "", setLoadingBackdrop).catch(() => {
        setAlertInfo({
          open: true,
          message: t("error.fail-to-download")
        });
      });
    } else {
      setDialogInfo({
        open: true,
        title: t("dialog.title-warning"),
        content: t("resource:dialog.save-before-download"),
        onOk: () => {}
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const itemListRender = (
    originNode: ReactElement,
    file: MyFileUpload,
    iFileList: MyFileUpload[]
  ) => {
    // if (layout === LayoutList) {
    return (
      <DragableUploadListItem
        file={file}
        removeFile={removeFile}
        downloadFile={downloadFile}
        fileList={iFileList}
        moveRow={moveRow}
      />
    );
    // }
    // return <DragableUploadPictureItem file={file} removeFile={removeFile} fileList={iFileList} />;
  };

  const draggerProps = {
    // name: "file",
    height: 100,
    multiple: true,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068"
      },
      strokeWidth: 5,
      style: { width: "97%" },
      showInfo: true,
      format: (percent?: number) => {
        return `${percent ? parseFloat(percent.toFixed(2)) : ""}%`;
      }
    },
    // className: classNames({
    //   [`${classes.filename}`]: true
    // }),
    // onDownload: (file: any) => {
    //   if (Number(file.uid).toString() === file.uid) {
    //     console.log("file", file);
    //     saveAndDownloadFile(file.uid, file.fileName, setLoadingBackdrop).catch(() => {
    //       setAlertInfo({
    //         open: true,
    //         message: t("error.fail-to-download")
    //       });
    //     });
    //   } else {
    //     setDialogInfo({
    //       open: true,
    //       title: t("dialog.title-warning"),
    //       content: t("resource:dialog.save-before-download"),
    //       onOk: () => {}
    //     });
    //   }
    // },
    // onRemove: (file: any) => {
    //   const gotFu = fuList.find((fu) => {
    //     return fu.file.uid === file.uid;
    //   });
    //   if (gotFu) {
    //     // this can break the chunk file upload immediately
    //     gotFu.fileRemoved = true;
    //   }
    // },
    // action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    customRequest: async (options: RcCustomRequestOptions) => {
      if (typeof options.file !== "string") {
        uploadSingleFile(options);
      }
      return {
        abort() {
          console.log("Upload Progress is Aborted.");
        }
      };
    },
    showUploadList: {
      showPreviewIcon: false,
      showDownloadIcon: false,
      // downloadIcon: <DownloadForOfflineRoundedIcon sx={{ color: "black" }} />,
      showRemoveIcon: true,
      removeIcon: <RemoveCircleIcon color="error" />
    },
    fileList,
    onDrop: (event: React.DragEvent<HTMLDivElement>) => {
      // onDrop will prevent the event to shared to drag and drop (react-dnd), as we do sorting at the same time
      event.stopPropagation();
      event.preventDefault();
    },
    onChange: onFileChange,
    listType: (layout === LayoutList ? "text" : "picture-card") as UploadListType,
    accept: acceptFileType
  };

  const myUploadProps =
    layout === LayoutPicture
      ? { ...draggerProps }
      : { ...draggerProps, itemRender: itemListRender };

  return (
    <DndProvider backend={HTML5Backend}>
      <Dragger {...myUploadProps} style={{ marginBottom: "10px" }}>
        <p className="ant-upload-drag-icon">
          <CloudUploadIcon />
        </p>
        <Typography sx={{ fontSize: { xs: "12px", md: "16px" } }}>
          {t("label.upload-message")}
        </Typography>
      </Dragger>
    </DndProvider>
  );
};

export default ChunkFileUpload;

// forwardRef(ChunkFileUpload).defaultProps = {
//   defaultFileList: []
// };

ChunkFileUpload.defaultProps = {
  layout: LayoutList
};
