import { TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedTableRow = styled(TableRow)(({ theme }) => {
  return {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0
    }
  };
});

export default CustomizedTableRow;
