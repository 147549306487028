import React, { useState, useRef, useContext, useEffect } from "react";
import { Grid, TextField, Autocomplete, Button, LinearProgress } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { IsValidPhone } from "util/const";
import { ResolveError } from "util/connUtil";
import { GetStandardOptionLabel, GetCountryGroupOptionLabel } from "util/display";
import { Authority } from "admin/authority/data.d";
import { Church } from "admin/church/data.d";
import { JobNature } from "admin/job-nature/data.d";
import { Country } from "admin/country/data.d";
import { User } from "admin/user/data.d";
import CustomizedUserPaper from "component/customizedUserPaper";
import { AlertContext } from "component/alert/alertContext";
import { fetchUserAttrOption, fetchUpdateUserProfile } from "./service";
import { UpdateUserProfile } from "./data.d";

interface EditInfoProps {
  infoUpdatedCallback: () => void;
  infoCancelCallback: () => void;
  user: User;
  show: boolean;
}

interface UserAttrOption {
  countryList: Country[];
  churchList: Church[];
  jobNatureList: JobNature[];
  authorityList: Authority[];
}

const EditInfo: React.FC<EditInfoProps> = (props) => {
  const { user, show, infoCancelCallback, infoUpdatedCallback } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);
  const [dobValue, setDobValue] = useState<Date | null>(null);
  const [selectedAutoCCountryValue, setSelectedAutoCCountryValue] = useState<Country | null>(null);
  const [selectedAuthorityID, setSelectedAuthorityID] = useState<number>(0);
  const [selectedChurchID, setSelectedChurchID] = useState<number>(0);
  const [selectedJobNatureID, setSelectedJobNatureID] = useState<number>(0);
  const [optionData, setOptionData] = useState<UserAttrOption | undefined>(undefined);
  const { t } = useTranslation(["translation", "user-management", "registration"]);

  useEffect(() => {
    fetchUserAttrOption()
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setOptionData({
            countryList: response.data.data.country,
            churchList: response.data.data.church,
            jobNatureList: response.data.data.job_nature,
            authorityList: response.data.data.authority
          });
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: `${error.message}, ${t("error.connection")}`
          });
      });
  }, [user]);

  useEffect(() => {
    if (user && optionData) {
      clearFormErrors();
      setValue("telephone", user?.telephone);

      setDobValue(user?.dob ? new Date(user?.dob) : null);
      setValue("dob", user?.dob ? new Date(user?.dob) : null);
      user?.country_id &&
        setSelectedAutoCCountryValue(
          optionData?.countryList.find((c) => {
            return c.id === user?.country_id;
          }) || null
        );
      user?.country_id && setValue("country_id", user?.country_id);

      setSelectedChurchID(user?.church_id || 0);
      setValue("church_id", user?.church_id);

      setSelectedJobNatureID(user?.job_nature_id || 0);
      setValue("job_nature_id", user?.job_nature_id);

      setSelectedAuthorityID(user?.authority_id || 0);
      setValue("authority_id", user?.authority_id);
    }
  }, [setValue, clearFormErrors, user, optionData]);

  const submitForm = (formData: UpdateUserProfile) => {
    // console.log("submitForm", formData);
    const postData = { ...formData };
    if (!formData.church_id) {
      delete postData.church_id;
    }
    if (!formData.job_nature_id) {
      delete postData.job_nature_id;
    }
    if (!formData.authority_id) {
      delete postData.authority_id;
    }
    if (formData.dob === "") {
      delete postData.dob;
    } else {
      postData.dob = new Date(formData.dob!).toISOString();
    }

    setLoadingBackdrop(true);
    fetchUpdateUserProfile(postData)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data) {
          setSuccessInfo({
            open: true,
            message: response.data.message
          });
          infoUpdatedCallback();
          setLoadingBackdrop(false);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setLoadingBackdrop(false);
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      });
  };

  const { ref: telephoneHookRef, ...telephoneHookRest } = register("telephone", {
    required: true,
    validate: IsValidPhone
  });
  const { ref: countryIDRef } = register("country_id", {
    required: true
  });
  const { ref: churchIDRef } = register("church_id");
  const { ref: jobNatureIDRef } = register("job_nature_id");
  const { ref: authorityIDRef } = register("authority_id");

  return (
    <CustomizedUserPaper elevation={3} hidden={!show}>
      <form ref={formEl} onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              {...telephoneHookRest}
              inputRef={telephoneHookRef}
              defaultValue={user?.telephone}
              fullWidth
              variant="standard"
              type="text"
              label={`${t("registration:pi.telephone")}*`}
              error={!!formErrors.telephone}
              helperText={formErrors.telephone && t("registration:errors.telephone-is-required")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                disableFuture
                label={t("registration:pi.dob")}
                openTo="year"
                views={["year", "month", "day"]}
                value={dobValue}
                onChange={(newValue) => {
                  setDobValue(newValue);
                  setValue("dob", newValue);
                }}
                mask="____-__-__"
                inputFormat="yyyy-MM-dd"
                renderInput={(params) => {
                  return (
                    <TextField fullWidth {...params} {...register("dob")} variant="standard" />
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            {optionData && optionData.countryList ? (
              <Autocomplete
                options={optionData.countryList}
                value={selectedAutoCCountryValue}
                onChange={(event, newValue) => {
                  clearFormErrors("country_id");
                  setValue("country_id", newValue?.id);
                  setSelectedAutoCCountryValue(
                    optionData?.countryList.find((c) => {
                      return c.id === newValue?.id;
                    }) || null
                  );
                }}
                getOptionLabel={GetStandardOptionLabel}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={countryIDRef}
                      label={`${t("registration:pi.district")}*`}
                      variant="standard"
                      error={!!formErrors.country_id}
                      helperText={
                        formErrors.country_id && t("registration:errors.district-is-required")
                      }
                    />
                  );
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {optionData && optionData.churchList ? (
              <Autocomplete
                options={optionData.churchList}
                groupBy={GetCountryGroupOptionLabel}
                getOptionLabel={GetStandardOptionLabel}
                value={
                  optionData.churchList.find((c) => {
                    return c.id === selectedChurchID;
                  }) || null
                }
                onChange={(event, newValue) => {
                  clearFormErrors("church_id");
                  setValue("church_id", newValue?.id);
                  setSelectedChurchID(newValue?.id || 0);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={churchIDRef}
                      label={t("registration:pi.parish")}
                      variant="standard"
                    />
                  );
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {optionData && optionData.jobNatureList ? (
              <Autocomplete
                options={optionData.jobNatureList}
                getOptionLabel={GetStandardOptionLabel}
                value={
                  optionData.jobNatureList.find((j) => {
                    return j.id === selectedJobNatureID;
                  }) || null
                }
                onChange={(event, newValue) => {
                  clearFormErrors("job_nature_id");
                  setValue("job_nature_id", newValue?.id);
                  setSelectedJobNatureID(newValue?.id || 0);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={jobNatureIDRef}
                      label={t("registration:pi.job-nature")}
                      variant="standard"
                    />
                  );
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {optionData && optionData.authorityList ? (
              <Autocomplete
                options={optionData.authorityList}
                groupBy={GetCountryGroupOptionLabel}
                getOptionLabel={GetStandardOptionLabel}
                value={
                  optionData.authorityList.find((a) => {
                    return a.id === selectedAuthorityID;
                  }) || null
                }
                onChange={(event, newValue) => {
                  clearFormErrors("authority_id");
                  setValue("authority_id", newValue?.id);
                  setSelectedAuthorityID(newValue?.id || 0);
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={authorityIDRef}
                      label={t("registration:pi.authority")}
                      variant="standard"
                    />
                  );
                }}
              />
            ) : (
              <LinearProgress />
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button variant="outlined" onClick={infoCancelCallback}>
                  {t("button.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained">
                  {t("button.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CustomizedUserPaper>
  );
};

export default EditInfo;
