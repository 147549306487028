import { V_SITE_API_URL } from "config/config";
import Axios, { AxiosRequestConfig } from "axios";
import { makeUseAxios, configure } from "axios-hooks";
import LRU from "lru-cache";
import { ACCESS_TOKEN, API_DEFAULT_TIMEOUT, SAVED_LANG } from "util/const";
import { removeToken } from "./token";

export const axios = Axios.create({
  baseURL: V_SITE_API_URL,
  validateStatus: (status) => {
    return status >= 200;
  }
});

// response interceptor to handle 403, 401
axios.interceptors.response.use(
  (response) => {
    // console.log("response", response);
    if (response.status === 403) {
      console.log("no permission", response.status);
      // removeToken();
      // this.props.history.push("/");
      // authProvider.logout();
      window.location.href = "/page-not-found";
    } else if (response.status === 401) {
      console.log("token expire");
      removeToken();
      // window.location.href = "/";
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// request interceptor to add token to request headers
axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    const copyOfConfig = { ...config };
    if (token) {
      copyOfConfig.headers = {
        Authorization: `Bearer ${token}`
      };
    }
    copyOfConfig.headers = { ...copyOfConfig.headers, "Content-Type": "application/json" };
    const lang = localStorage.getItem(SAVED_LANG);
    if (lang) {
      copyOfConfig.headers = { ...copyOfConfig.headers, "Accept-Language": lang };
    }
    if (!copyOfConfig.timeout) {
      copyOfConfig.timeout = API_DEFAULT_TIMEOUT;
    }
    return copyOfConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const useAxios = makeUseAxios({
  axios
});

// export const useAxios = () => {
//   const history = useHistory();
//   return makeUseAxios({
//     axios: axios,
//   });
// };

const cache = new LRU({ max: 10 });
configure({ axios, cache });
