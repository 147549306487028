import React, { useRef, useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { XYCoord, Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { ResourceGroupListDto } from "res/resource/data.d";
import { Box, Avatar, Typography, IconButton } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SortIcon from "@mui/icons-material/Sort";
import styles from "style/common.module.css";
import { blackColor } from "style/theme";
import { ItemTypes } from "./itemType";

export interface DragableResourceGroupItemProps {
  id: string;
  index: number;
  resource_group: ResourceGroupListDto;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  removeResource: (resGroupId: number) => void;
}

interface DragItem {
  index: number;
  resource_group: ResourceGroupListDto;
  type: string;
}

const DragableResourceGroupItem: React.FC<DragableResourceGroupItemProps> = ({
  index: indexFromParent,
  resource_group,
  moveRow,
  removeResource
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [displayIndex, setDisplayIndex] = useState<number>(indexFromParent + 1);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.RESOURCE_GROUP,
    collect(monitor) {
      //   console.log("monitor.getHandlerId()", monitor.getHandlerId());
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    // drop: (item: any) => {
    //   return resource_group;
    // },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = indexFromParent;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.RESOURCE_GROUP,
    item: () => {
      return {
        resource_group,
        id: resource_group.id,
        index: indexFromParent,
        type: ItemTypes.RESOURCE_GROUP
      };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  useEffect(() => {
    setDisplayIndex(indexFromParent + 1);
  }, [indexFromParent]);

  const opacity = isDragging ? 0 : 1;
  const backgroundColor = resource_group.status === 0 ? "pink" : "white";

  drag(drop(ref));
  return (
    // <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        cursor: "move"
      }}
    >
      <Box sx={{ paddingRight: "15px", paddingTop: "15px" }}>
        <Avatar sx={{ bgcolor: "secondary.main" }}>{displayIndex}</Avatar>
      </Box>
      <Box
        className={resource_group.not_ready_to_publish ? styles.grayStyleBackground : ""}
        data-handler-id={handlerId}
        ref={ref}
        sx={{
          backgroundColor,
          flexGrow: 1,
          opacity,
          margin: "5px",
          padding: "5px",
          display: "flex",
          flexDirection: "row",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          cursor: "move"
        }}
        key={`${resource_group.id}resGroup_outer_box`}
      >
        <Box sx={{ margin: "auto", paddingRight: "5px" }}>
          <SortIcon />
        </Box>
        <Box sx={{ margin: "auto", flexGrow: 1 }}>
          <Typography variant="h6" color="primary">
            {resource_group.name}
          </Typography>
          <Typography color={blackColor}>{resource_group.description}</Typography>
        </Box>
        <Box sx={{ paddingRight: "5px" }}>
          <IconButton
            aria-label="close"
            color="error"
            onClick={() => {
              removeResource(resource_group.id);
            }}
          >
            <RemoveCircleIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DragableResourceGroupItem;
