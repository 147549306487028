import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { AlertContext } from "./alertContext";

const CustomizedBackdrop = styled(Backdrop)(() => {
  return `
    z-index: 9999;
    color: "#fff";
    `;
});

const SnackBarAlert: React.FC = () => {
  const {
    alertInfo,
    setAlertInfo,
    successInfo,
    setSuccessInfo,
    dialogInfo,
    setDialogInfo,
    loadingBackdrop
  } = useContext(AlertContext);
  const { t } = useTranslation(["translation"]);

  const handleAlertClose = () => {
    setAlertInfo({ open: false, message: "" });
  };

  const handleSuccessInfoClose = () => {
    setSuccessInfo({ open: false, message: "" });
  };

  const handleOk = () => {
    const replacement = { ...dialogInfo!, open: false };
    setDialogInfo(replacement);
    dialogInfo?.onOk();
  };

  return (
    <>
      {alertInfo && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          open={alertInfo.open}
          autoHideDuration={3000}
          onClose={handleAlertClose}
        >
          <Alert severity="error">{alertInfo.message}</Alert>
        </Snackbar>
      )}
      {successInfo && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={successInfo.open}
          autoHideDuration={3000}
          onClose={handleSuccessInfoClose}
        >
          <Alert severity="success">{successInfo.message}</Alert>
        </Snackbar>
      )}
      {dialogInfo && (
        <Dialog
          open={dialogInfo.open}
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogInfo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogInfo.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOk} color="primary" variant="contained" autoFocus>
              {t("button.ok")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {loadingBackdrop && (
        <CustomizedBackdrop open={loadingBackdrop}>
          <CircularProgress />
        </CustomizedBackdrop>
      )}
    </>
  );
};

export default SnackBarAlert;
