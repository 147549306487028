import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  List,
  ListItem,
  ListItemText
} from "@mui/material";
import React, { ReactNode, useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { TreeSelect } from "antd";
import "antd/dist/antd.min.css";
import { DesktopDatePicker } from "@mui/lab";
import { renderWholeTreeSelectNode } from "admin/category/service";
import { Category } from "admin/category/data.d";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import styles from "style/common.module.css";
import { AlertContext } from "component/alert/alertContext";
import useResourceGroupType from "hook/resourceGroupTypeList";
import ChunkFileUpload, { FileUploadResult } from "component/upload/antFileUpload";
// import { UploadFile } from "antd/lib/upload/interface";
import {
  MyFileUpload,
  FileStatusDone,
  FileStatusError,
  FileStatusUploading
} from "component/upload/file";
import ComfirmationDialog from "component/alert/confirmationDialog";
import AddBoxIcon from "@mui/icons-material/AddBox";
import type { TopicDto } from "res/topic/data.d";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SelectTopicDialog from "res/topic/selectTopicDialog";
// import { V_SITE_API_URL } from "config/config";
import { nameHelperText, fetchCreateResourceGroup, fetchUpdateResourceGroup } from "./service";
import { ResourceGroup, ResourceGroupFormData } from "./data.d";

export interface CreateResourceProps {
  open: boolean;
  onClose: (success: boolean) => void;
  createCallback?: () => void;
  resourceGroup?: ResourceGroup;
}

const CreateEditResourceGroup: React.FC<CreateResourceProps> = (props) => {
  const { t } = useTranslation(["translation", "resource"]);
  const resourceGroupTypeList = useResourceGroupType();
  const [openIncompeleteUpdateWarning, setOpenIncompeleteUpdateWarning] = useState<boolean>(false);
  const { setAlertInfo, setSuccessInfo, setDialogInfo, setLoadingBackdrop } =
    useContext(AlertContext);
  const [selectedStatus, setSelectedStatus] = useState<number>(1);
  // const [clearFiles, setClearFiles] = useState<boolean>(false);
  // const [selectedGroupType, setSelectedGroupType] = useState<number[]>([]);
  const [publishAt, setPublishAt] = useState<Date | null>(null);
  const formEl = useRef<HTMLFormElement | null>(null);
  const [mediaCategoryTreeSelectItemUI, setMediaCategoryTreeSelectItemUI] = useState<ReactNode>();
  const { resourceGroup, open: openDialog } = props;
  const [topicDtoList, setTopicDtoList] = useState<TopicDto[]>([]);
  // let fileResult: FileUploadResult[] = [];
  const [fileResult, setFileResult] = useState<FileUploadResult[]>([]);
  const [fileList, setFileList] = useState<MyFileUpload[]>([]);
  const [openTopicAssignment, setOpenTopicAssignment] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  const setDefaultFilesToChildren = (res: ResourceGroup) => {
    const lfileList: MyFileUpload[] = [];
    res.resource?.forEach((resFile) => {
      const displayName = resFile.display_file_size
        ? `${resFile.filename} (${resFile.display_file_size})`
        : resFile.filename;
      const constructFile: MyFileUpload = {
        uid: resFile.id.toString(),
        name: displayName,
        fileName: resFile.filename,
        // url: `${V_SITE_API_URL}/resource/file/${resFile.id}`,
        status: FileStatusDone
      };
      lfileList.push(constructFile);
    });
    // console.log("lfileList", lfileList);
    setFileList(lfileList);
  };

  const resetFormByDefaultResource = (res: ResourceGroup) => {
    setValue("name", res.name);
    setValue("description", res.description);
    setPublishAt(new Date(res.republish_at));
    setValue("publish_at", new Date(res.republish_at));
    setSelectedStatus(res.status);
    setValue("status", res.status);
    const groupTypeIdList = res.resource_group_type?.map((gType) => {
      return gType.id;
    });
    // setSelectedGroupType(groupTypeIdList);
    setDefaultFilesToChildren(res);
    setValue("resource_group_type", groupTypeIdList);
  };

  useEffect(() => {
    clearFormErrors();
    if (resourceGroupTypeList && resourceGroupTypeList.length > 0) {
      const rgCategory = resourceGroupTypeList?.map((rg) => {
        return { id: rg.id, name: rg.name } as Partial<Category>;
      });
      setMediaCategoryTreeSelectItemUI(renderWholeTreeSelectNode(rgCategory));
    }
  }, [resourceGroupTypeList]);

  const resetPublishAtToNow = () => {
    setPublishAt(new Date());
    setValue("publish_at", new Date());
  };

  useEffect(() => {
    if (resourceGroup) {
      clearFormErrors();
      setTopicDtoList(resourceGroup.topic);
      resetFormByDefaultResource(resourceGroup);
    } else {
      resetPublishAtToNow();
    }
  }, [resourceGroup]);

  // const setFormFileList = (files: MyFileUpload[]) => {
  //   setValue("files", files);
  // };

  const reassemblyFileList = () => {
    if (fileList && fileResult) {
      // for each file
      const modifedFileList = fileList.map((fileItem) => {
        const newFileItem = { ...fileItem };
        const gotFileResult =
          fileResult.find((c) => {
            return c.file_uid === fileItem.uid;
          }) || null;
        if (gotFileResult) {
          newFileItem.uploaded_filename = gotFileResult.uploaded_filename;
        }
        return newFileItem;
      });
      // console.log("modifedFileList", fileList, modifedFileList, fileResult);
      return modifedFileList;
    }
    // console.log("modifedFileList 2", fileList);
    return fileList;
  };

  const resetForm = () => {
    reset();
    setFileList([]);
    resetPublishAtToNow();
    setSelectedStatus(1);
    setFileResult([]);
    setTopicDtoList([]);
  };

  const validateIfAllFileHasBeenUploaded = (): string => {
    for (let i = 0; i < fileList.length; i += 1) {
      // console.log("fileList[i].status", fileList[i].status);
      if (fileList[i].status === FileStatusUploading) {
        return FileStatusUploading;
      }
      if (fileList[i].status === FileStatusError) {
        return FileStatusError;
      }
    }
    return "";
  };

  const submitForm = (formData: ResourceGroupFormData) => {
    let submitFunc = fetchCreateResourceGroup;
    console.log("formData", formData.publish_at);
    const submitData = { ...formData };
    submitData.files = reassemblyFileList();
    if (formData.publish_at.length === 10) {
      submitData.publish_at = `${formData.publish_at}T00:00:00Z`;
    }

    if (topicDtoList) {
      submitData.topic_id = topicDtoList.map((topics) => {
        return topics.id;
      });
    }
    const validation = validateIfAllFileHasBeenUploaded();
    if (validation !== "") {
      setDialogInfo({
        open: true,
        title: t("dialog.title-warning"),
        content:
          validation === FileStatusError
            ? t("resource:dialog.file-need-to-remove")
            : t("resource:dialog.file-is-uploading"),
        onOk: () => {}
      });
      return;
    }
    setLoadingBackdrop(true);
    if (resourceGroup) {
      submitData.id = resourceGroup.id;
      submitFunc = fetchUpdateResourceGroup;
    }
    submitFunc(submitData)
      .then((response: AxiosResponse) => {
        if (response.status === 201 || response.status === 200) {
          setSuccessInfo({ open: true, message: response.data.message });
          props.onClose(true);
          resetForm();
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      })
      .finally(() => {
        setLoadingBackdrop(false);
      });
  };

  const userConfirmToCloseDialog = () => {
    props.onClose(false);
    setFileList([]);
    setOpenIncompeleteUpdateWarning(false);
  };

  const determineIfAnyNewUpload = (files: MyFileUpload[] | undefined) => {
    if (files) {
      for (let i = 0; i < files?.length; i += 1) {
        if (files[i].lastModified) {
          return true;
        }
      }
    }
    return false;
  };

  const handleClose = () => {
    // console.log("fileList", fileList);
    // if (fileList && fileList.length > 0) {
    if (determineIfAnyNewUpload(fileList)) {
      setOpenIncompeleteUpdateWarning(true);
    } else {
      userConfirmToCloseDialog();
    }
  };

  const removeTopicAssignment = (topicID: number) => {
    for (let i = 0; i < topicDtoList.length; i += 1) {
      if (topicDtoList[i].id === topicID) {
        const newTopicDtoList = [...topicDtoList];
        newTopicDtoList.splice(i, 1);
        setTopicDtoList(newTopicDtoList);
      }
    }
  };

  const { ref: nameHookRef, ...nameHookRest } = register("name", {
    required: true
  });

  const { ref: descriptionHookRef, ...descriptionHookRest } = register("description", {
    required: true
  });
  const { ref: statusHookRef, ...statusHookRest } = register("status", {
    required: true
  });

  const showResourceTopicList = (): React.ReactNode => {
    if (topicDtoList) {
      return topicDtoList.map((topic) => {
        return (
          <div key={`${topic.id}topic_list_div`}>
            <ListItem
              sx={topic.status === 0 ? { backgroundColor: "pink" } : { backgroundColor: "white" }}
              className={topic.not_ready_to_publish ? styles.grayStyleBackground : ""}
              key={`${topic.id}topic_list_item`}
              secondaryAction={
                <IconButton
                  onClick={() => {
                    removeTopicAssignment(topic.id);
                  }}
                  color="error"
                  edge="end"
                  aria-label="delete"
                >
                  <RemoveCircleIcon />
                </IconButton>
              }
            >
              <ListItemText
                key={`${topic.id}topic_list_item_text`}
                primaryTypographyProps={{ color: "primary" }}
                primary={topic.name}
                secondary={topic.description}
              />
            </ListItem>
            <Divider variant="middle" />
          </div>
        );
      });
    }
    return <></>;
  };

  const onTopicAssignmentClose = (topic?: TopicDto) => {
    let isValid = true;
    if (topicDtoList?.length > 0 && topic) {
      // do a validation first
      for (let i = 0; i < topicDtoList.length; i += 1) {
        if (topicDtoList[i].id === topic.id) {
          isValid = false;
          break;
        }
      }
    }
    if (isValid && topic) {
      let newTopicDtoList: TopicDto[] = [];
      if (topicDtoList) {
        newTopicDtoList = [...topicDtoList];
      }
      newTopicDtoList.push(topic);
      setTopicDtoList(newTopicDtoList);
    }
    setOpenTopicAssignment(false);
  };

  const tooltipAssignToNewTopic: string = t("resource:button.assign-to-new-topic");
  return (
    <>
      <Dialog
        key="create-resource-group-modal-dialog"
        open={openDialog}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby={t("resource:label.add-resource-group")}
        aria-describedby={t("resource:label.add-resource-group")}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="create-resource-group-dialog-title">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography
                key="create-resource-group-modal-title"
                id="create-resource-group-modal-title"
                variant="h6"
                component="h2"
              >
                {resourceGroup
                  ? t("resource:label.edit-resource-group")
                  : t("resource:label.add-resource-group")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon color="action" />
              </IconButton>
            </Grid>
          </Grid>
          <Divider className={styles.modalDivider} />
        </DialogTitle>
        <form ref={formEl} onSubmit={handleSubmit(submitForm)}>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label={`${t("resource:label.resource-name")} *`}
                  {...nameHookRest}
                  inputRef={nameHookRef}
                  fullWidth
                  autoComplete="off"
                  variant="standard"
                  type="text"
                  error={!!formErrors.name}
                  helperText={nameHelperText(formErrors.name, t)}
                  placeholder={t("resource:label.resource-name")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...descriptionHookRest}
                  inputRef={descriptionHookRef}
                  fullWidth
                  autoComplete="off"
                  multiline
                  aria-label="resource-description"
                  label={`${t("resource:label.description")} *`}
                  placeholder={t("resource:label.description")}
                  variant="standard"
                  type="text"
                  error={!!formErrors.description}
                  helperText={
                    formErrors.description && t("resource:errors.description-is-required")
                  }
                />
              </Grid>
              <Grid item xs={12}>
                {mediaCategoryTreeSelectItemUI && (
                  <>
                    <Typography
                      variant="body1"
                      color={formErrors.category ? "error" : "general.fieldDefault"}
                    >
                      {t("resource:label.resource-group-type")}
                    </Typography>
                    <Controller
                      control={control}
                      name="resource_group_type"
                      render={(fProps) => {
                        return (
                          <TreeSelect
                            showSearch
                            allowClear
                            style={{ width: "100%" }}
                            value={fProps.field.value}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                              zIndex: 9999
                            }}
                            bordered={false}
                            placeholder={t("resource:place-holder.resource-group-type")}
                            multiple
                            treeDefaultExpandAll
                            treeNodeFilterProp="displayName"
                            onChange={(value) => {
                              fProps.field.onChange(value);
                            }}
                          >
                            {mediaCategoryTreeSelectItemUI}
                          </TreeSelect>
                        );
                      }}
                    />
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <Typography>{t("resource:label.resource-assign-to-topic")}</Typography>
                <IconButton
                  style={{ padding: "0 0 0 8px" }}
                  color="primary"
                  onClick={() => {
                    setOpenTopicAssignment(true);
                  }}
                >
                  <Tooltip title={tooltipAssignToNewTopic} placement="top">
                    <AddBoxIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: "0px", marginTop: "0px" }}>
                {topicDtoList && <List>{showResourceTopicList()}</List>}
              </Grid>
              <Grid item xs={12}>
                <DesktopDatePicker
                  label={`${t("resource:label.publish-at")} *`}
                  openTo="year"
                  views={["day"]}
                  value={publishAt}
                  onChange={(newValue) => {
                    setPublishAt(newValue);
                    setValue("publish_at", newValue);
                  }}
                  mask="____-__-__"
                  inputFormat="yyyy-MM-dd"
                  renderInput={(params) => {
                    return (
                      <TextField
                        autoComplete="off"
                        fullWidth
                        {...params}
                        {...register("publish_at", { required: true })}
                        variant="standard"
                        error={!!formErrors.publish_at}
                        helperText={
                          formErrors.publish_at && t("resource:errors.publish-at-is-required")
                        }
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ChunkFileUpload
                  acceptFileType=".pdf,.doc,.docx,.mp3,.mp4,.jpg,.png,.tiff,.gif,.ppt,.wma,.wmv,.pps,.xls,.xlsx,.rar,.pptx"
                  setFileResult={setFileResult}
                  fileResult={fileResult}
                  fileList={fileList}
                  onChangeFileList={setFileList}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="resource-status-label">{`${t("label.status")} *`}</InputLabel>
                  <Select
                    {...statusHookRest}
                    inputRef={statusHookRef}
                    labelId="resource-status-label"
                    id="resource-status"
                    value={selectedStatus}
                    onChange={(event: SelectChangeEvent<number>) => {
                      setSelectedStatus(Number(event.target.value));
                    }}
                    label={t("label.status")}
                  >
                    <MenuItem value={1}>{t("status.active")}</MenuItem>
                    <MenuItem value={0}>{t("status.inactive")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              margin: "15px",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <Button onClick={handleClose} variant="outlined">
              {t("button.cancel")}
            </Button>
            <Button type="submit" variant="contained" style={{ marginLeft: "auto", order: 2 }}>
              {t("button.submit")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <ComfirmationDialog
        open={openIncompeleteUpdateWarning}
        messageToDisplay={t("resource:dialog.close-dialog-warning")}
        setOpen={setOpenIncompeleteUpdateWarning}
        okAction={userConfirmToCloseDialog}
      />
      <SelectTopicDialog open={openTopicAssignment} onClose={onTopicAssignmentClose} />
    </>
  );
};

export default CreateEditResourceGroup;
