/* eslint-disable indent */
import React, { useContext } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { format as formatDate } from "date-fns";
import { GetStandardOptionWithCorrectLangLabel } from "util/display";
import { styled } from "@mui/material/styles";
import { displayStatus } from "./service";
import { User } from "./data.d";
import { UserOptionContext } from "./userOptionContext";

interface InfoUserProps {
  user: User;
}

export const CustomizedGrid = styled(Grid)(() => {
  return {
    [`&.MuiGrid-item`]: {
      paddingLeft: 4,
      fontSize: 14
    }
  };
});

// const CustomizedGrid = styled(Grid)(() => {
//   return `
//     padding-left: 0;
//   `;
// });

const CustomizedTypography = styled(Typography)(() => {
  return `
    overflow-wrap: break-word;
  `;
});

const Info: React.FC<InfoUserProps> = (props) => {
  const { user } = props;
  const { data: optionData } = useContext(UserOptionContext);
  const { t } = useTranslation(["translation", "registration", "user-management"]);

  const displayUserRole = (): React.ReactNode => {
    return (
      user.role_list &&
      user.role_list.map((role) => {
        return (
          <Button key={role.id} variant="outlined" color="primary" style={{ marginRight: "2px" }}>
            {GetStandardOptionWithCorrectLangLabel(role)}
          </Button>
        );
      })
    );
  };

  return (
    <>
      <CustomizedGrid item xs={2}>
        {t("registration:label.email")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>{user.email}</CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.name-chi")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>{user.name_chi}</CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.name-eng")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>{user.name_eng}</CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.dob")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>
          {user.dob && formatDate(new Date(user.dob!), "yyyy-MM-dd")}
        </CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.telephone")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>{user.telephone}</CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.referee")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>{user.referee}</CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.district")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>
          {user.country
            ? user.country
            : optionData.countryList.find((c) => {
                return c.id === user.country_id;
              })?.name_chi}
        </CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("user-management:label.parish")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>
          {user.church
            ? user.church
            : optionData.churchList.find((c) => {
                return c.id === user.church_id;
              })?.name_chi}
        </CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.job-nature")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>
          {user.job_nature
            ? user.job_nature
            : optionData.jobNatureList.find((c) => {
                return c.id === user.job_nature_id;
              })?.name_chi}
        </CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("registration:pi.authority")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        <CustomizedTypography>
          {user.authority
            ? user.authority
            : optionData.authorityList.find((c) => {
                return c.id === user.authority_id;
              })?.name_chi}
        </CustomizedTypography>
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("user-management:label.role")}:
      </CustomizedGrid>
      <Grid item xs={10}>
        {displayUserRole()}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("user-management:label.registration-at")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        {user.registration_date && formatDate(new Date(user.registration_date), "yyyy-MM-dd")}
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("user-management:label.last-login-at")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        {user.user_last_login && formatDate(new Date(user.user_last_login!), "yyyy-MM-dd HH:mm")}
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("user-management:label.login-count")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        {user.login_count}
      </Grid>
      <CustomizedGrid item xs={2}>
        {t("label.status")}:
      </CustomizedGrid>
      <Grid item xs={4}>
        {displayStatus(user.status, user.status_label)}
      </Grid>
    </>
  );
};

export default Info;
