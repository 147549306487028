import React, { ReactNode, useState, useEffect } from "react";
import { Paper, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { renderWholeTreeSelectNode } from "admin/category/service";
import { TreeSelect } from "antd";
import { GalleryCategory } from "../data.d";

interface GallerySelectFilterProps {
  galleryTreeData: GalleryCategory[];
  searchBy: (selectedId: string) => void;
  initSelected?: string[];
}

const GallerySelectFilter: React.FC<GallerySelectFilterProps> = (props) => {
  const { galleryTreeData, searchBy, initSelected } = props;
  const [galleryAntCategorySelectUi, setGalleryAntCategorySelectUi] = useState<ReactNode>();
  const [selectedGalleryCategoryId, setSelectedGalleryCategoryId] = useState<string[]>(
    initSelected || []
  );
  // const [nodeSelected, setNodeSelected] = useState<string[]>(initSelected || []);
  const { t } = useTranslation(["translation", "gallery"]);
  const {
    // getValues,
    control,
    clearErrors: clearFormErrors
  } = useForm();

  useEffect(() => {
    setSelectedGalleryCategoryId(initSelected || []);
  }, [initSelected]);

  useEffect(() => {
    clearFormErrors();
    setGalleryAntCategorySelectUi(renderWholeTreeSelectNode(galleryTreeData));
  }, [galleryTreeData]);

  return (
    <Paper sx={{ display: "flex", alignItems: "center", opacity: "0.7" }}>
      <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "row" }}>
        <Box sx={{ width: "100%" }}>
          <Controller
            control={control}
            name="gallery_category"
            defaultValue={selectedGalleryCategoryId}
            render={(fProps) => {
              return (
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  value={fProps.field.value}
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                    zIndex: 9999
                  }}
                  placeholder={t("gallery:place-holder.gallery-category-filter")}
                  treeDefaultExpandAll
                  treeNodeFilterProp="displayName"
                  onChange={(value) => {
                    searchBy(value);
                    fProps.field.onChange(value);
                  }}
                >
                  {galleryAntCategorySelectUi}
                </TreeSelect>
              );
            }}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default GallerySelectFilter;

GallerySelectFilter.defaultProps = {
  initSelected: []
};
