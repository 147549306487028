import React, { useRef, useContext } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { AlertContext } from "component/alert/alertContext";
import { ResolveError } from "util/connUtil";
import { IsValidPasswordFormat } from "util/const";
import { passwordHelperText, confirmPasswordHelperText } from "util/helper";
import CustomizedUserPaper from "component/customizedUserPaper";
import { UpdateUserPassword } from "./data.d";
import { fetchUpdatePasswordOnProfile } from "./service";

interface EditPasswordProps {
  infoUpdatedCallback: () => void;
  infoCancelCallback: () => void;
  show: boolean;
}

const EditPassword: React.FC<EditPasswordProps> = (props) => {
  const { show, infoCancelCallback, infoUpdatedCallback } = props;
  const formEl = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation(["translation", "registration"]);
  const { setAlertInfo, setSuccessInfo, setLoadingBackdrop } = useContext(AlertContext);
  const {
    register,
    handleSubmit,
    reset: resetForm,
    getValues: getFormValues,
    // clearErrors: clearFormErrors,
    formState: { errors: formErrors }
  } = useForm();

  const submitForm = (formData: UpdateUserPassword) => {
    // console.log("submitForm", formData);
    const postData = { ...formData };
    setLoadingBackdrop(true);
    fetchUpdatePasswordOnProfile(postData)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data) {
          setSuccessInfo({
            open: true,
            message: response.data.message
          });
          infoUpdatedCallback();
          setLoadingBackdrop(false);
          resetForm();
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setLoadingBackdrop(false);
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      });
  };
  const { ref: currentPasswordRef, ...currentPasswordRest } = register("current_password", {
    required: true
    //   validate: IsValidPasswordFormat,
  });
  const IsCorrectConfirmPassword = (confirmPassword: string) => {
    const password = getFormValues("new_password");
    if (password === confirmPassword) {
      return true;
    }
    return false;
  };
  const { ref: newPasswordRef, ...newPasswordRest } = register("new_password", {
    required: true,
    validate: IsValidPasswordFormat
  });
  const { ref: confirmPasswordRef, ...confirmPasswordRest } = register("confirm_password", {
    required: true,
    validate: IsCorrectConfirmPassword
  });
  return (
    <CustomizedUserPaper elevation={3} hidden={!show}>
      <form ref={formEl} onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...currentPasswordRest}
              inputRef={currentPasswordRef}
              fullWidth
              variant="standard"
              type="password"
              autoComplete="new-password"
              label={`${t("label.current-password")}*`}
              error={!!formErrors.current_password}
              helperText={
                formErrors.current_password && t("registration:errors.current-password-is-required")
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...newPasswordRest}
              inputRef={newPasswordRef}
              fullWidth
              variant="standard"
              type="password"
              autoComplete="new-password"
              label={`${t("label.new-password")}*`}
              error={!!formErrors.new_password}
              helperText={passwordHelperText(formErrors.new_password, t)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...confirmPasswordRest}
              inputRef={confirmPasswordRef}
              label={t("label.confirm-password")}
              type="password"
              fullWidth
              variant="standard"
              autoComplete="new-password"
              error={!!formErrors.confirm_password}
              helperText={confirmPasswordHelperText(formErrors.confirm_password, t)}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button variant="outlined" onClick={infoCancelCallback}>
                  {t("button.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button type="submit" variant="contained">
                  {t("button.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CustomizedUserPaper>
  );
};

export default EditPassword;
