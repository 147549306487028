import React, { ReactNode, useState, useEffect, useContext } from "react";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import { AlertContext } from "component/alert/alertContext";
import { Outlet } from "react-router-dom";
import { CategoryTree, Category } from "./data.d";
import {
  getStructuredCategoryList,
  renderWholeTreeSelectNode,
  renderAntWholeTree,
  AntTreeNode
} from "./service";

export interface CategoryContextState {
  fullCatTreeData: CategoryTree;
  mediaCatTreeData: Category[];
  topicCatTreeData: Category[];
  categoryTreeSelectItemUI: ReactNode;
  mediaAntCategoryTreeData: AntTreeNode[] | undefined;
  topicAntCategoryTreeData: AntTreeNode[] | undefined;
}

// Create Context Object
export const CategoryContext = React.createContext<CategoryContextState>({
  fullCatTreeData: { data: [] },
  mediaCatTreeData: [],
  topicCatTreeData: [],
  categoryTreeSelectItemUI: <></>,
  mediaAntCategoryTreeData: undefined,
  topicAntCategoryTreeData: undefined
});

export const CategoryContextProvider: React.FC = ({ children }) => {
  const [fullCatTreeData, setFullCatTreeData] = useState<CategoryTree>({
    data: []
  });
  const [mediaCatTreeData, setMediaCatTreeData] = useState<Category[]>([]);
  const [topicCatTreeData, setTopicCatTreeData] = useState<Category[]>([]);
  const [mediaAntCategoryTreeData, setMediaAntCategoryTreeData] = useState<
    AntTreeNode[] | undefined
  >(undefined);
  const [topicAntCategoryTreeData, setTopicAntCategoryTreeData] = useState<
    AntTreeNode[] | undefined
  >(undefined);

  const [categoryTreeSelectItemUI, setCategoryTreeSelectItemUI] = useState<ReactNode>();
  const { setAlertInfo } = useContext(AlertContext);
  useEffect(() => {
    getStructuredCategoryList()
      .then((response: AxiosResponse) => {
        if (response && response.status === 200 && response.data && response.data.data) {
          setTopicCatTreeData(response.data.data.topic_type_category);
          setMediaCatTreeData(response.data.data.media_type_category);
          const fullCatTree: CategoryTree = { data: [] };
          fullCatTree.data = [
            ...response.data.data.topic_type_category,
            ...response.data.data.media_type_category
          ];
          setFullCatTreeData(fullCatTree);
        } else {
          throw new Error(ResolveError(response.data));
        }
      })
      .catch((error) => {
        setAlertInfo &&
          setAlertInfo({
            open: true,
            message: error.message
          });
      });
  }, []);
  useEffect(() => {
    if (fullCatTreeData && topicCatTreeData && mediaCatTreeData) {
      setCategoryTreeSelectItemUI(renderWholeTreeSelectNode(fullCatTreeData.data));
      setTopicAntCategoryTreeData(renderAntWholeTree(topicCatTreeData));
      setMediaAntCategoryTreeData(renderAntWholeTree(mediaCatTreeData));
    }
  }, [fullCatTreeData, topicCatTreeData, mediaCatTreeData]);

  return (
    <CategoryContext.Provider
      value={{
        fullCatTreeData,
        topicCatTreeData,
        mediaCatTreeData,
        categoryTreeSelectItemUI,
        mediaAntCategoryTreeData,
        topicAntCategoryTreeData
      }}
    >
      {children}
      <Outlet />
    </CategoryContext.Provider>
  );
};
