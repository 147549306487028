import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomizedFilterPaper = styled(Paper)(() => {
  return `
        font-size: 12px;
        padding: 2px;
      `;
});

export default CustomizedFilterPaper;
