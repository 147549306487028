import { Meta } from "component/table/data.d";
import { ResourceGroupListDto } from "res/resource/data.d";

export const CRITERIA_TYPE_TOPIC = "topic";
export const CRITERIA_TYPE_CATEGORY = "category";
export const CRITERIA_TYPE_STATUS = "status";

export interface TopicFormData {
  id?: number;
  name: string;
  description: string;
  publish_at: string;
  category: number[];
  status: number;
  resource_group_list?: ResourceGroupListDto[];
}

export interface TopicCategory {
  topic_id: number;
  category_id: number;
}
export interface Topic {
  id: number;
  name: string;
  description: string;
  first_publish_at: string;
  republish_at: string;
  // resource_last_updated_at: string;
  view_count: number;
  category: number[];
  status: number;
  updated_at: string;
  show_edit_button: boolean;
  not_ready_to_publish: boolean;
  score: number;
  topic_category: TopicCategory[];
}

export interface TopicTableData {
  data: Topic[];
  meta: Meta;
}

export interface TopicSearchFormData {
  topic: string;
  status?: number;
  category?: number;
}

export interface TopicDto {
  id: number;
  name: string;
  description: string;
  status: number;
  not_ready_to_publish: boolean;
}
