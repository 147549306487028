import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

const CustomizedRegistrationDivider = styled(Divider)(({ theme }) => {
  return {
    margin: theme.spacing(3, 2)
  };
});

export default CustomizedRegistrationDivider;
