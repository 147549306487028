import React, { useState, createContext, FC } from "react";
import SnackBarAlert from "./alert";

interface AlertContextProviderProps {
  alertInfo: SimpleInfoProps | undefined;
  setAlertInfo: (info: SimpleInfoProps | undefined) => void;
  successInfo: SimpleInfoProps | undefined;
  setSuccessInfo: (info: SimpleInfoProps | undefined) => void;
  dialogInfo: SimpleDialogProps | undefined;
  setDialogInfo: (info: SimpleDialogProps | undefined) => void;
  loadingBackdrop: boolean;
  setLoadingBackdrop: (show: boolean) => void;
}

interface SimpleInfoProps {
  open: boolean;
  message: string;
}

interface SimpleDialogProps {
  open: boolean;
  title: string;
  content: string;
  onOk: () => void;
}

// Create Context Object
export const AlertContext = createContext<AlertContextProviderProps>({} as AlertContextProviderProps);

// Create a provider for components to consume and subscribe to changes
export const AlertContextProvider: FC = ({ children }) => {
  const [alertInfo, setAlertInfo] = useState<SimpleInfoProps | undefined>();
  const [successInfo, setSuccessInfo] = useState<SimpleInfoProps | undefined>();
  const [dialogInfo, setDialogInfo] = useState<SimpleDialogProps | undefined>();
  const [loadingBackdrop, setLoadingBackdrop] = useState<boolean>(false);

  return (
    <AlertContext.Provider
      value={{
        alertInfo,
        setAlertInfo,
        successInfo,
        setSuccessInfo,
        dialogInfo,
        setDialogInfo,
        loadingBackdrop,
        setLoadingBackdrop
      }}
    >
      <SnackBarAlert />
      {children}
    </AlertContext.Provider>
  );
};
