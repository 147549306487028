import { styled } from "@mui/material/styles";

const CustomizedRegistrationPaperContent = styled("div")(({ theme }) => {
  return {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  };
});

export default CustomizedRegistrationPaperContent;
