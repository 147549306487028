import Qs from "qs";
import { SaveSetting, GetSavedSetting } from "./paginatorSetting";

export const handleRecordPerPage = (
  currentPage: number,
  recordPerPage: number,
  featureName: string,
  preparePara: any,
  fetchTableData?: (iParams: any) => void
) => {
  // console.log("handleRecordPerPage", currentPage);
  SaveSetting({
    record_per_page: recordPerPage,
    current_page: currentPage,
    feature_name: featureName
  });
  fetchTableData &&
    fetchTableData({
      ...preparePara,
      page_size: recordPerPage,
      current_page: currentPage
    });
};

export const loadNewPageData = (
  newPage: number,
  recordPerPage: number,
  featureName: string,
  preparePara: any,
  fetchTableData: (iParams: any) => void
) => {
  // console.log("loadNewPageData", newPage);
  SaveSetting({
    record_per_page: recordPerPage,
    current_page: newPage,
    feature_name: featureName
  });
  fetchTableData({
    ...preparePara,
    page_size: recordPerPage,
    current_page: newPage
  });
};

export const getFilterValueStringifyed = (featureName: string) => {
  const pageSetting = GetSavedSetting(featureName);
  return Qs.stringify(pageSetting.filter_value, {
    arrayFormat: "repeat"
  });
};

export const getFilterValue = (featureName: string) => {
  const pageSetting = GetSavedSetting(featureName);
  return pageSetting.filter_value;
};
