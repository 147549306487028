import React, { useContext } from "react";
import {
  Box,
  Divider,
  Avatar,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from "@mui/material";
import { AlertContext } from "component/alert/alertContext";
import { ResourceDto, ResourceGroupListDto } from "res/resource/data.d";
import { saveAndDownloadFile } from "component/upload/service";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import styles from "style/common.module.css";
import { blackColor } from "style/theme";

interface ResourceListProps {
  resource_group: ResourceGroupListDto;
  open: boolean;
  onClose: () => void;
}

const ResourceList: React.FC<ResourceListProps> = (props) => {
  const { resource_group, open: dialogOpen, onClose } = props;
  //   const [resourceList, setResourceList] = useState<ResourceDto[]>([]);
  const { setAlertInfo, setLoadingBackdrop } = useContext(AlertContext);
  const { t } = useTranslation();

  const displayDownloadButton = (resource: ResourceDto): React.ReactNode => {
    if (!resource) {
      return <></>;
    }

    return (
      <IconButton
        onClick={() => {
          saveAndDownloadFile(resource.id.toString(), resource.filename, setLoadingBackdrop).catch(
            (error) => {
              console.log("download error", error);
              setAlertInfo({
                open: true,
                message: t("error.fail-to-download")
              });
            }
          );
        }}
        color="secondary"
        edge="end"
        aria-label="Download"
      >
        <CloudDownloadIcon />
      </IconButton>
    );
  };

  const listResGroupUi = (): React.ReactNode => {
    if (resource_group.resource.length > 0) {
      let i = 0;
      return resource_group.resource.map((res) => {
        i += 1;
        return (
          <div key={`${res.id}res_div`}>
            <Box
              sx={{ margin: "10px", display: "flex", flexDirection: "row" }}
              key={`${res.id}res_outer_box`}
            >
              <Box sx={{ paddingRight: "15px" }}>
                <Avatar sx={{ bgcolor: "secondary.main" }}>{i}</Avatar>
              </Box>
              <Box sx={{ margin: "auto", flexGrow: 1 }}>
                <Typography variant="h6" color={blackColor}>
                  {res.filename}
                </Typography>
              </Box>
              <Box sx={{ paddingRight: "5px" }}>{displayDownloadButton(res)}</Box>
            </Box>
            <Divider variant="middle" />
          </div>
        );
      });
    }
    return <></>;
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {resource_group && (
        <Dialog
          key="topic-info-modal-dialog"
          open={dialogOpen}
          onClose={handleClose}
          aria-labelledby={resource_group.name}
          aria-describedby={resource_group.name}
          scroll="paper"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle id="resource_group-download-multiple-file">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Typography color="primary" variant="h6">
                {resource_group.name}
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexDirection: "row"
                  }}
                >
                  <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
            <Divider className={styles.modalDivider} />
          </DialogTitle>
          <DialogContent>
            <Box sx={{ width: "100%", marginBottom: "10px" }}>
              <Typography variant="h6" className={styles.grayStyleLevel2}>
                {resource_group.description}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>{listResGroupUi()}</Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ResourceList;
