import React, { useEffect, useState, useRef, useContext } from "react";
import { Identifier } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { IconButton } from "@mui/material";
import { Buffer } from "buffer";
import { RcFile, UploadProgressEvent, UploadRequestMethod } from "rc-upload/lib/interface";
import { AlertContext } from "component/alert/alertContext";
import ResolveError from "util/connUtil";
import FileToUpload, { FileStatusDone, FileStatusError } from "./file";
import { fetchReserveTempFile, getImageSrc } from "./service";
import { FileUploaded } from "./dropZoneFileUpload";

export const ImageItemTypes = {
  GALLERY_IMAGE: "ImageItem"
};

interface DragItem {
  index: number;
  file: FileUploaded;
  type: string;
}
interface DropZoneUploadPictureItemProps {
  index: number;
  moveImage: (dragIndex: number, hoverIndex: number) => void;
  file: FileUploaded;
  hasRoomToSend: boolean;
  uploadFinished: (file: any, tempFilename: string) => void;
  removeImage: (file: any) => void;
}

const CustomizedThumbDiv = styled("div")(() => {
  return {
    display: "inline-flex",
    borderRadius: 2,
    justifyContent: "center",
    border: "2px solid #eaeaea",
    marginBottom: 8,
    marginRight: 20,
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: "border-box"
  };
});

const CustomizedThumbInner = styled("div")(() => {
  return {
    display: "flex",
    // minWidth: 0,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    color: "white"
  };
});

const CustomizedImg = styled("img")(() => {
  return {
    display: "block",
    width: "auto",
    height: "100%",
    margin: "auto"
    // top: 0,
    // left: 0,
    // position: "absolute"
  };
});

const CustomizedOverlayDiv = styled("div")(() => {
  return {
    // zIndex: 9999,
    // position: "relative",
    opacity: "0.7",
    width: "140px",
    backgroundColor: "black",
    // color: "#FFFFFF",
    // textAlign: "center"
    position: "absolute",
    top: "0",
    marginTop: "12px",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };
});

const CustomizedDeleteButton = styled(IconButton)(() => {
  return {
    position: "absolute",
    top: "80%",
    marginTop: "12px",
    left: "50%",
    transform: "translate(-50%, -50%)"
  };
});

const DropZoneUploadPictureItem: React.FC<DropZoneUploadPictureItemProps> = (props) => {
  const {
    file,
    hasRoomToSend,
    uploadFinished,
    removeImage,
    index: indexFromParent,
    moveImage
  } = props;
  const [percentage, setPercentage] = useState<number>(0);
  const [imageData, setImageData] = useState<string>("");
  const { t } = useTranslation(["translation", "gallery"]);
  const ref = useRef<HTMLDivElement>(null);
  const { setAlertInfo } = useContext(AlertContext);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ImageItemTypes.GALLERY_IMAGE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item: DragItem) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      // current element where the dragged element is hovered on
      const hoverIndex = indexFromParent;
      // If the dragged element is hovered in the same place, then do nothing
      if (dragIndex === hoverIndex) {
        return;
      }

      // // Determine rectangle on screen
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // // Get vertical middle
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 8;
      // const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 8;

      // console.log("hoverMiddleY", hoverMiddleY, hoverMiddleX);
      // // Determine mouse position
      // const clientOffset = monitor.getClientOffset();

      // // Get pixels to the top
      // const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      // console.log("dragIndex", dragIndex, hoverIndex);
      // console.log("hoverClientY", hoverClientY, hoverMiddleY);
      // console.log("hoverClientX", hoverClientX, hoverMiddleX);

      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }

      // // Dragging upwards
      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      // // Dragging left
      // if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      //   return;
      // }

      // // Dragging right
      // if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      //   return;
      // }

      // If it is dragged around other elements, then move the image and set the state with position changes
      moveImage(dragIndex, hoverIndex);
      /*
        Update the index for dragged item directly to avoid flickering
        when the image was half dragged into the next
      */
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    type: ImageItemTypes.GALLERY_IMAGE,
    item: () => {
      return {
        file,
        id: file.uid,
        index: indexFromParent,
        type: ImageItemTypes.GALLERY_IMAGE
      };
    },
    collect: (monitor: any) => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  });

  const onProgress = (event: UploadProgressEvent) => {
    setPercentage(event.percent || 0);
  };

  const uploadSingleFile = async (iFile: FileUploaded) => {
    const typedFile = iFile as RcFile;
    const fu = new FileToUpload(typedFile, typedFile.name);
    await fu.init();
    try {
      const response = await fetchReserveTempFile(typedFile.uid, typedFile.name, fu.fileMd5);
      if (response) {
        if (response.data && response.data?.data?.file_name) {
          // upload now
          // console.log("upload now", response.data.data.file_path);
          // need to set the filename first because we will call separately for the upload
          fu.sessionFile = response.data.data.file_name;
          const uploadParameter = {
            filename: typedFile.name,
            file: iFile as RcFile,
            withCredentials: true,
            action: "upload",
            method: "PUT" as UploadRequestMethod,
            onProgress
          };
          await fu.uploadFile(uploadParameter);
          uploadFinished(iFile, response.data?.data?.file_name);
          // console.log("result", fu.result, response.data);
        } else {
          // console.log("response x", response);
          // console.log("response error", ResolveError(response.data));
          uploadFinished(iFile, "");
          throw new Error(ResolveError(response.data));
        }
      }
    } catch (error) {
      let message = error;
      if (error instanceof Error) {
        message = error.message;
      }
      setAlertInfo({
        open: true,
        message: `${message}, ${t("error.connection")}`
      });
      uploadFinished(iFile, "");
    }
  };

  useEffect(() => {
    if (hasRoomToSend) {
      uploadSingleFile(file);
    }
  }, [hasRoomToSend]);

  const showWhatText = (iFile: FileUploaded, iPercentage: number) => {
    if (iFile.thumbnail_file && iFile.thumbnail_file !== "") {
      return "";
    }
    if (iFile.status === FileStatusDone) {
      return t("gallery:label.done");
    }
    if (iFile.status === FileStatusError) {
      return t("gallery:label.error");
    }

    if (iPercentage > 0) {
      return `${iPercentage}%`;
    }
    if (iPercentage === 0) {
      return t("gallery:label.waiting");
    }
    return "";
  };

  const showThumbnail = (iFile: FileUploaded) => {
    if (iFile.thumbnail_file && iFile.thumbnail_file !== "") {
      return getImageSrc(file.thumbnail_file).then((response) => {
        const content = `data:${response.headers[
          "content-type"
        ].toLowerCase()};base64,${Buffer.from(response.data, "binary").toString("base64")}`;
        setImageData(content);
      });
    }
    return setImageData(iFile.preview);
  };

  useEffect(() => {
    showThumbnail(file);
  }, [file]);

  const whichClass = (iFile: FileUploaded) => {
    if (iFile && Number(iFile.id)) {
      return " file-from-db";
    }
    if (iFile && iFile.status === FileStatusError) {
      return " file-upload-err";
    }
    return "";
  };
  drag(drop(ref));
  return (
    <CustomizedThumbDiv
      className={whichClass(file)}
      key={`${file.id ? file.id : file.uid}div`}
      ref={ref}
      data-handler-id={handlerId}
    >
      <CustomizedThumbInner>
        <CustomizedImg src={imageData} />
        <CustomizedOverlayDiv>{showWhatText(file, percentage)}</CustomizedOverlayDiv>
        <CustomizedDeleteButton
          aria-label="delete"
          key={`remove_button${file.id ? file.id : file.uid}`}
          onClick={() => {
            removeImage(file);
          }}
        >
          <RemoveCircleIcon color="error" />
        </CustomizedDeleteButton>
      </CustomizedThumbInner>
    </CustomizedThumbDiv>
  );
};

export default DropZoneUploadPictureItem;
