import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  IconButton,
  Divider,
  Typography,
  Grid,
  Tabs,
  Tab
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import { ResolveError } from "util/connUtil";
import { AlertContext } from "component/alert/alertContext";
import styles from "style/common.module.css";
import { User } from "../user/data.d";
import { fetchCurrentUserInfo } from "./service";
import EditInfo from "./editInfo";
import EditPassword from "./editPassword";

interface UserProfileProps {
  open: boolean;
  onClose: () => void;
}

const USER_INFO_TAB = 0;
const PASSWORD_TAB = 1;

const UserProfile: React.FC<UserProfileProps> = (props) => {
  const { open, onClose } = props;
  const [user, setUser] = useState<User | undefined>(undefined);
  const [tabValue, setTabValue] = useState<number>(USER_INFO_TAB);
  const { t } = useTranslation(["translation", "user-management", "registration"]);
  const { setAlertInfo } = useContext(AlertContext);

  useEffect(() => {
    if (open) {
      fetchCurrentUserInfo()
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            response.data && setUser(response.data.data as User);
          } else {
            throw new Error(ResolveError(response.data));
          }
        })
        .catch((error) => {
          setAlertInfo &&
            setAlertInfo({
              open: true,
              message: `${error.message}, ${t("error.connection")}`
            });
        });
    }
  }, [open]);

  const handleClose = () => {
    onClose();
    setUser(undefined);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    // console.log(newValue);
    setTabValue(newValue);
  };

  return (
    <Dialog
      key="topic-info-modal-dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby={t("user-management:user-profile.title")}
      aria-describedby={t("user-management:user-profile.title")}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="topic-info-dialog-title">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6">{t("user-management:user-profile.title")}</Typography>
          </Box>
          <Box>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon color="action" />
            </IconButton>
          </Box>
        </Box>
        <Divider className={styles.modalDivider} />
      </DialogTitle>
      {user ? (
        <DialogContent>
          <Box>
            <Grid container>
              <Grid sx={{ display: { sm: "none", md: "block" } }} item xs={6} md={2}>
                <Typography>{t("registration:label.email")}:</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{user.email}</Typography>
              </Grid>
              <Grid sx={{ display: { sm: "none", md: "block" } }} item xs={6} md={2}>
                <Typography>{t("registration:pi.name-chi")}:</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{user.name_chi}</Typography>
              </Grid>
              <Grid sx={{ display: { sm: "none", md: "block" } }} item xs={6} md={2}>
                <Typography>{t("registration:pi.name-eng")}:</Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{user.name_eng}</Typography>
              </Grid>
            </Grid>
          </Box>
          {user && (
            <>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="User Profile Tabs">
                <Tab label="User Info" id="user-info" aria-controls="user-profile-tabpanel-0" />
                <Tab
                  label="Update Password"
                  id="update-password"
                  aria-controls="user-profile-tabpanel-1"
                />
              </Tabs>
              <EditInfo
                infoUpdatedCallback={handleClose}
                infoCancelCallback={handleClose}
                user={user}
                show={tabValue === USER_INFO_TAB}
              />
              <EditPassword
                infoUpdatedCallback={handleClose}
                infoCancelCallback={handleClose}
                show={tabValue === PASSWORD_TAB}
              />
            </>
          )}
        </DialogContent>
      ) : (
        <LinearProgress />
      )}
    </Dialog>
  );
};

export default UserProfile;
