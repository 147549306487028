/* eslint-disable indent */
import React from "react";
import { TreeSelect } from "antd";
// import { Typography } from "@mui/material";
import { axios } from "util/request";
// import commonStyles from "style/common.module.css";
import { Category } from "./data.d";

const { TreeNode } = TreeSelect;

export const getStructuredCategoryList = () => {
  const url = "/category/topic/tree/list";
  return axios.get(url);
};

const styles = {
  leafNode: {
    color: "#1643e3"
  },
  leafDisabled: {
    color: "#9d9b9b"
  }
};

// below 2 functions are material design tree node generation
// export const renderMuiTreeNode = (nodes: Category) => {
//   const hasChild = Array.isArray(nodes.children) ? true : false;
//   let nodeStyle = { color: "primary.main" };
//   if (hasChild) {
//     nodeStyle = { color: "secondary.main" };
//   }
//   return (
//     <TreeItem
//       sx={nodeStyle}
//       key={nodes.id}
//       nodeId={nodes.id.toString()}
//       label={nodes.name}
//     >
//       {Array.isArray(nodes.children)
//         ? nodes.children.map((node) => renderMuiTreeNode(node))
//         : null}
//     </TreeItem>
//   );
// };

// export const renderMuiWholeTree = (nodes: Category[]) => {
//   return nodes?.map((category) => {
//     return renderMuiTreeNode(category);
//   });
// };

export interface AntTreeNode {
  title: string;
  key: string;
  selectable?: boolean;
  children?: AntTreeNode[];
}

export const renderAntTreeNode = (nodes: Category): AntTreeNode => {
  const hasChild = !!Array.isArray(nodes.children);
  if (hasChild) {
    // console.log(nodes.children?.map((node) => renderAntTreeNode(node)));
    return {
      title: nodes.name,
      key: nodes.id.toString(),
      selectable: true,
      children: nodes.children?.map((node) => {
        return renderAntTreeNode(node);
      })
    };
  }
  return {
    title: nodes.name,
    key: nodes.id.toString()
  };
};

export const renderAntWholeTree = (nodes: Category[]): AntTreeNode[] => {
  return nodes?.map((category) => {
    return renderAntTreeNode(category);
  });
};

// below 2 functions are ant design tree node generation
export const renderTreeSelectNode = (node: Partial<Category>, parentName?: string) => {
  const titleWithoutStyle = node!.name!.toString();
  const hasChild = !!Array.isArray(node.children);
  let displayTitle = <>{node?.name?.toString()}</>;
  let deepParentName = parentName;
  if (parentName) {
    displayTitle = (
      <>
        <span style={styles.leafDisabled}>{parentName}</span>
        <span style={hasChild ? styles.leafDisabled : styles.leafNode}>
          {node?.name?.toString()}
        </span>
      </>
    );
    deepParentName = `${parentName + node.name} -> `;
  } else {
    if (!hasChild) {
      displayTitle = <span style={styles.leafNode}>{node?.name?.toString()}</span>;
    }
    deepParentName = `${node.name} -> `;
  }

  return (
    <TreeNode
      value={node?.id!}
      key={node.id}
      displayName={node?.id! + titleWithoutStyle}
      title={displayTitle}
      disabled={hasChild}
    >
      {Array.isArray(node.children)
        ? node.children.map((insideNode) => {
            return renderTreeSelectNode(insideNode, deepParentName);
          })
        : null}
    </TreeNode>
  );
};

export const renderWholeTreeSelectNode = (nodes: Partial<Category>[] | undefined) => {
  return nodes?.map((category) => {
    return renderTreeSelectNode(category);
  });
};

export function findCatById(data: Category[], id: number): Category | undefined {
  for (let i = 0; i < data.length; i += 1) {
    if (data[i].id === id) {
      return data[i];
    }
    if (data[i].children) {
      const gotit = findCatById(data[i].children as Category[], id);
      if (gotit) {
        return gotit;
      }
    }
  }
  return undefined;
}

export function findCatNameWithParentById(
  data: Category[],
  id: number,
  parentName?: React.ReactNode
): React.ReactNode | undefined | string {
  for (let i = 0; i < data.length; i += 1) {
    const hasChild = !!Array.isArray(data[i].children);
    let nodeText = <></>;
    if (parentName) {
      nodeText = (
        <>
          {parentName}
          <div style={!hasChild ? styles.leafNode : {}}>{` -> ${data[i].name}`}</div>
        </>
      );
    } else {
      nodeText = <div style={!hasChild ? styles.leafNode : {}}>{data[i].name} </div>;
    }
    if (data[i].id === id) {
      return nodeText;
    }
    if (data[i].children) {
      const gotit = findCatNameWithParentById(data[i].children as Category[], id, nodeText);
      if (gotit) {
        return gotit;
      }
    }
  }

  return undefined;
}

export function findCatNameStringOnlyWithParentById(
  data: Category[],
  id: number,
  parentName?: React.ReactNode
): string {
  for (let i = 0; i < data.length; i += 1) {
    let nodeText = "";
    if (parentName) {
      nodeText = parentName + data[i].name;
    } else {
      nodeText = data[i].name;
    }
    if (data[i].id === id) {
      return nodeText;
    }
    if (data[i].children) {
      const gotit = findCatNameStringOnlyWithParentById(
        data[i].children as Category[],
        id,
        nodeText
      );
      if (gotit) {
        return gotit;
      }
    }
  }

  return "";
}
