import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { TERMS_ACCEPTED } from "util/const";
import CustomizedRegistrationPaper from "noauth/component/registrationPaper";
import Copyright from "noauth/component/copyright";
import { redColor } from "style/theme";
import styles from "./registration.module.css";
import CustomizedRegistrationPaperContent from "./component/registrationContentDiv";
import CustomizedRegistrationDivider from "./component/registrationDivider";

const CustomizedSubTitleDiv = styled("div")(({ theme }) => {
  return `
      margin-top: ${theme.spacing(4)};
      `;
});

const CustomizedContentDiv = styled("div")(({ theme }) => {
  return `
      padding-left: ${theme.spacing(2)};
      `;
});

const UserRegulation: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["translation", "registration"]);
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    localStorage.setItem(TERMS_ACCEPTED, "false");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
    navigate("/");
  };

  const handleAgree = () => {
    localStorage.setItem(TERMS_ACCEPTED, "true");
    navigate("/registration/register-user");
  };
  return (
    <>
      <CustomizedRegistrationPaper>
        <Typography className={styles.paperTitle} variant="h5">
          {t("site.name")}
        </Typography>
        <Typography className={styles.paperTitle} variant="h6">
          {t("registration:regulation.subtitle")}
        </Typography>
        <CustomizedRegistrationDivider />
        <CustomizedRegistrationPaperContent>
          <Typography>
            在閣下有權限下載資源作為教育用途前，請細看以下條款；清楚知悉後，
            <span style={{ color: redColor }}>必須同意</span>
            及遵守以下條款，違者必究：
          </Typography>
          <CustomizedSubTitleDiv>
            <Typography color="primary">用戶登記、密碼、及安全</Typography>
            <CustomizedContentDiv>
              <Typography>
                1. 閣下若要使用資源，必須填寫登記表，登記時請選擇私人用戶名稱及密碼。
              </Typography>
              <Typography>
                2.
                登記時，將會要求提供個人識別資料，閣下須確保提供的資料是準確、真實、最新及完整的。否則本辦事處有權註銷閣下的登記。
              </Typography>
              <Typography>
                3. 資源網所有資料謹供牧民工作者作教育用途參考，本辦事處不接受非牧民工作者登記。
              </Typography>
              <Typography>
                4.
                本辦事處尊重登記用戶的的私隱，我們將採取一切實際可行的步驟，確保我們通過網站收集的任何資料安全，不受第三方的干擾。
              </Typography>
            </CustomizedContentDiv>
          </CustomizedSubTitleDiv>
          <CustomizedSubTitleDiv>
            <Typography color="primary">會員使用守則</Typography>
            <CustomizedContentDiv>
              <Typography>
                1.
                閣下並無網頁管理的權限，如欲上載資源以供分享或發現資料有錯漏，可致電或電郵聯絡辦事處職員。
              </Typography>
              <Typography>
                2.
                資源網所有資料謹供牧民工作者校內教學牧民用途。否則，未經授權同意，任何人不得重製、公開播送、公開上映、公開演出、公開傳輸、改作、散布等向公眾提供資源的行為。
              </Typography>
            </CustomizedContentDiv>
          </CustomizedSubTitleDiv>
          <Box
            sx={{
              m: 4,
              p: 1,
              display: "flex",
              alignItems: "center"
            }}
          >
            <Box sx={{ p: 1, flexGrow: 1 }}>
              <Button onClick={handleClickOpen} variant="outlined">
                {t("button.disagree")}
              </Button>
            </Box>
            <Box sx={{ p: 1 }}>
              <Button variant="contained" onClick={handleAgree}>
                {t("button.agree")}
              </Button>
            </Box>
          </Box>
        </CustomizedRegistrationPaperContent>
        <CustomizedRegistrationPaperContent>
          <Copyright />
        </CustomizedRegistrationPaperContent>
      </CustomizedRegistrationPaper>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">會員登記及使用守則</DialogTitle>
        <DialogContent>
          <DialogContentText>你必須同意才能登記成為會員!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCancel}>
            {t("button.leave")}
          </Button>
          <Button autoFocus variant="contained" onClick={handleClose}>
            {t("button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserRegulation;
